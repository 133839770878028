import { Injectable } from '@angular/core';

import { icon, Icon, IconOptions, marker, Marker, PointTuple } from 'leaflet';
import { ItineraryImgUrl, ItineraryPoint, TimelineImgUrl } from 'app/global/class/itinerary-point';
import { ItineraryPointLayer } from 'app/global/class/itinerary-point-layer';
import { isPointValid } from '../../../utils/geo-utils';

@Injectable()
export class LeafletPointService {

    // The coordinates of the "tip" of the icon (relative to its top left corner)
    static readonly ANCHOR_CIRCLE_VALUE: PointTuple = [10, 10];
    static readonly ANCHOR_TIMELINE_VALUE: PointTuple = [8, 8];
    static readonly ANCHOR_END_POINT_VALUE: PointTuple = [10, 28]; // endpoint image size is 20 width x 28 height

    buildPointLayer(srcPoint: ItineraryPoint, imageUrl: ItineraryImgUrl): Marker {
        if (!isPointValid(srcPoint)) {
            return null;
        }
        return marker(
            [srcPoint.coordinates.latitude, srcPoint.coordinates.longitude],
            this.createPointMarker(imageUrl)
        );
    }

    createPointMarker(imageUrl: ItineraryImgUrl) {
        return {
            icon: this.createIcon(imageUrl),
            draggable: true
        };
    }

    buildTimelinePointLayer(srcPoint: ItineraryPoint): ItineraryPointLayer {
        if (!srcPoint || !srcPoint.coordinates) {
            console.warn('Itinerary point can not be null, could not create ItineraryPoint layer');
            return null;
        }
        // Create icon
        const newIcon = icon({
            iconUrl: TimelineImgUrl.DEFAULT,
            iconAnchor: LeafletPointService.ANCHOR_TIMELINE_VALUE // point of the icon which will correspond to marker's location
        } as IconOptions);
        // Create marker for the point
        const newMarker = marker(
            [srcPoint.coordinates.latitude, srcPoint.coordinates.longitude], {
                icon: newIcon,
                draggable: false
            });
        return ItineraryPointLayer.createFromPoint(srcPoint, newMarker);
    }

    createIcon(imgUrl: ItineraryImgUrl): Icon {
        return icon({
            iconUrl: imgUrl,
            iconAnchor: this.getAnchorValue(imgUrl) // point of the icon which will correspond to marker's location
        } as IconOptions);
    }

    /**
     * Determine point marker anchor value
     *
     * @param point
     * @return {[number,number]}
     */
    getAnchorValue(imgUrl: ItineraryImgUrl): PointTuple {
        if (imgUrl === ItineraryImgUrl.STOP) {
            return LeafletPointService.ANCHOR_END_POINT_VALUE;
        }
        return LeafletPointService.ANCHOR_CIRCLE_VALUE;
    }
}
