import { NgModule } from '@angular/core';
import { GatewayAngularSharedModule } from '../../shared/shared.module';
import { GalleryComponent } from './gallery.component';
import { EnergyEfficiencyRoute } from './gallery.route';
import { GalleryService } from './gallery.service';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from 'app/global/directive/shared-directives.module';

@NgModule({
    imports: [
        GatewayAngularSharedModule,
        SharedDirectivesModule,
        RouterModule.forChild([EnergyEfficiencyRoute])
    ],
    declarations: [GalleryComponent],
    providers: [GalleryService],
    bootstrap: [GalleryComponent],
    exports: [GalleryComponent]
})
export class EnergyEfficiencyModule {
}
