import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MapsModule } from '../maps/maps.module';
import { ModalsModule } from 'app/global/component/modals/modals.module';
import { Ng5SliderModule } from 'ng5-slider';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { galleriesState } from './galleries.route';
import { GatewayAngularSharedModule } from 'app/shared/shared.module';
import { EnergyEfficiencyModule } from 'app/galleries/energyEfficiency/gallery.module';
import { ToolkitModule } from 'app/galleries/toolkit/gallery.module';
import { DocumentationModule } from 'app/galleries/documentation/gallery.module';

@NgModule({
    imports: [
        GatewayAngularSharedModule,
        MapsModule,
        RouterModule.forChild(galleriesState),
        ModalsModule,
        Ng5SliderModule,
        LeafletModule,
        TypeaheadModule.forRoot(),
        ButtonsModule.forRoot(),
        NgxMaterialTimepickerModule,
        EnergyEfficiencyModule,
        ToolkitModule,
        DocumentationModule
    ],
    exports: [],
    providers: [],
    declarations: []
})
export class GalleriesModule {
}
