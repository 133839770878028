import { ChartItem } from './chart-item';
import { isArrayEmpty } from '../../../../utils/global.helper';
import { Layout } from 'react-grid-layout';

/**
 * Construit le layout utilisé par la grille, à partir des valeurs de chaque élément
 *
 * @param charts
 */
export const buildLayout = (charts: ChartItem[]): Layout[] => {
    if (isArrayEmpty(charts)) {
        return [];
    }

    return charts.map(getCellLayout);
};

export const generateChartIndex = (index: number): string => `chart${ index }`;

const getCellLayout = (item: ChartItem, index: number): Layout => {
    if (!item) {
        return null;
    }
    // Index pour l'identification de chaque élément par le composant grid
    const i = generateChartIndex(index);
    const row: number[] = isArrayEmpty(item.rowPositions) ? [1] : item.rowPositions;
    const cols: number[] = isArrayEmpty(item.columnPositions) ? [1] : item.columnPositions;

    // Pour la config, le premier élément commence à 1, alors que pour la grille, le premier index est 0
    const gridOrigin = 0;
    const configOrigin = 1;
    const diffOrigin = configOrigin - gridOrigin;
    // La valeur utile pour la grille est la position du premier élément corrigé de la différence des origines
    const x = cols[0] - diffOrigin;
    const y = row[0] - diffOrigin;
    // Déduction de la hauteur et largeur de l'élément
    const w = cols.reduce(findSize, 0);
    const h = row.reduce(findSize, 0);

    return { x, y, w, h, i };
};

const findSize = (counter: number, currentValue: number, i: number, allValues: number[]): number => {
    // Initialisation, à minima la taille (width ou height) vaut 1
    if (i === 0) {
        return 1;
    }
    // Incrémentation, à condition que l'élément en cours soit bien une nouvelle valeur, pas une répétition de la précédente
    if (currentValue !== allValues[i - 1]) {
        counter++;
    }
    return counter;
};
