import { getInitialState } from '../reducer/initial.state';

export const RESET = 'RESET';

export const reset = () => ({ type: RESET });

// For the app reducer
export const MAIN_ACTIONS = {
    [RESET]: () => ({
        ...getInitialState()
    })
};
