import { RangeValues } from 'app/global/class/range-values';

export class PbfLayersDetails {
    constructor(public readonly loader: string,
                public readonly index: number,
                public readonly rangeValues: RangeValues,
                public readonly gradientRange: Array<number>,
                public readonly title: string,
                public readonly description: string,
                public readonly propertyName?: string,
                public readonly securityId?: number) {
    }
}

export enum  LAYERS_TYPES {
    BIKE = 'bike',
    POLLUTANTS = 'pollutants',
    SECURITY = 'security',
    HERE = 'here'
}

export enum  LAYERS_NAMES {
    BIKE = 'Vélo',
    POLLUTANTS = 'Polluants',
    SECURITY = 'Sécurité',
    HERE = 'Here'
}
export class LayersDetails {
    static pbfLayersDetailsMap = new Map<LAYERS_TYPES, PbfLayersDetails[]>();

    constructor() {
    }

    getPbfLayersDetailsMap(): Map<LAYERS_TYPES, PbfLayersDetails[]> {
        LayersDetails.pbfLayersDetailsMap.set(LAYERS_TYPES.BIKE, [
            new PbfLayersDetails('bikeLayerRoughness', 0, new RangeValues(0, 1, 0, 1, null, 0.02), [0, 10, 20, 30], 'Qualité de la route absolue',
                'Qualité de la route absolue mesurée par la communauté', 'roughness'),
            new PbfLayersDetails('bikeLayerRoughnessRank', 1, new RangeValues(0, 1, 0, 1, null, 0.02), [0, 10, 20, 30], 'Qualité de la route relative',
                'Classement de la qualité des routes mesurées par la communauté', 'roughnessRank')]);

        LayersDetails.pbfLayersDetailsMap.set(LAYERS_TYPES.POLLUTANTS, [
            new PbfLayersDetails('NoxLayer', 0, new RangeValues(0, 90, 0, 90), [0, 30, 60, 90], 'NOx' , 'Affichage des émissions de NOx en mg/(s.km)', 'NOx_mgskm'),
            new PbfLayersDetails('TPCOLayer', 1, new RangeValues(0, 30000, 0, 30000), [0, 10000, 20000, 30000], 'CO2' , 'Affichage des émissions de CO2 en g/(s.km)', 'CO2_gskm'),
        ]);
        LayersDetails.pbfLayersDetailsMap.set(LAYERS_TYPES.SECURITY, [
            new PbfLayersDetails('safetyMapLayer', 0, new RangeValues(-5, 5, -5, 5, null, 0.1), [-5, -2, 2, 5], 'All', 'Affichage des éléments de sécurité importants'),
            new PbfLayersDetails('safetyAccelerationMapLayer', 1, new RangeValues(0, 5, 0, 5, null, 0.1), [0, 1.66, 3.33, 5],
                'Accélération', 'Affichage des accélérations importantes',
                null, 2),
            new PbfLayersDetails('safetyAdherenceMapLayer', 2, new RangeValues(-5, 5, -5, 5, null, 0.1), [-5, -2, 2, 5],
                'Perte d\'adhérence', 'Affichage des pertes d\'adhérences importantes', null, 1),
            new PbfLayersDetails('safetyDecelerationMapLayer', 3, new RangeValues(-5, 0, -5, 0, null, 0.1), [-5, -3.33, -1.66, 0],
                'Décélération', 'Affichage des décélérations importantes', null, 3)
        ]);
        LayersDetails.pbfLayersDetailsMap.set(LAYERS_TYPES.HERE, [
            new PbfLayersDetails('avgSpeedLayer', 0, new RangeValues(0, 130, 0, 130, false), [0, 60, 90, 130], 'Vitesse moyenne', 'Attribut vitesse moyenne', 'averagespeed'),
            new PbfLayersDetails('freeFlowSpeedLayer', 1, new RangeValues(0, 130, 0, 130, false), [0, 60, 90, 130], 'Free Flow Speed', 'Attribut free flow speed',
                'freeflowspeed'),
            new PbfLayersDetails('speedLimitLayer', 2, new RangeValues(0, 130, 0, 130, false), [0, 60, 90, 130], 'Limitation de vitesse', 'Attribut limitation de vitesse',
                'speedLimit'),
            new PbfLayersDetails('functionalClassLayer', 3, new RangeValues(1, 5, 1, 5, false), [0, 1.66, 3.33, 5], 'Fonctional Class', 'Attribut classe fonctionnelle', 'FC')
        ]);
        return LayersDetails.pbfLayersDetailsMap;
    }
}
