import { DemoPresentationContent } from 'app/demos/presentation/class/demo-presentation-content';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { ElectromobilityDemoGroupType } from 'app/demos/presentation/electromobility-presentation/electromobility-demo-group-type';
import { PresentationContentOptions } from 'app/demos/presentation/class/presentation-content-options';
import { DemoName } from '../../demo-name';

// Demonstration groups
export const ElectromobilityGroups: ElectromobilityDemoGroupType[] = [ElectromobilityDemoGroupType.FIRSTGROUP, ElectromobilityDemoGroupType.SECONDGROUP, ElectromobilityDemoGroupType.THIRDGROUP];

// Demos for each presentation group - all demo information must be provided below
export const ElectromobilityDemoGroups: Map<ElectromobilityDemoGroupType, DemoName[]> = new Map<ElectromobilityDemoGroupType, DemoName[]>()
    .set(ElectromobilityDemoGroupType.FIRSTGROUP, [DemoName.CEVOLVER_1])
    .set(ElectromobilityDemoGroupType.SECONDGROUP, [DemoName.XDASHSOFLEET_1])
    .set(ElectromobilityDemoGroupType.THIRDGROUP, [DemoName.DASHBOARDMOUVEMENT, DemoName.DASHBOARDPORPOISE]);

// Content for the header presentation, at the tof of the page
// Presentation images are in content/images/demos/presentation/
export const ElectromobilityPresentationTitle = new DemoPresentationContent(
    'presentation.electromobility.intro.first.title',
    'presentation.electromobility.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const ElectromobilityPresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.electromobility.intro.second.title',
        'presentation.electromobility.intro.second.description',
        'top-img1',
    )
];

// Group level content (presentation of the demonstration group)
export const ElectromobilityDemoGroupContents: Map<ElectromobilityDemoGroupType, DemoPresentationContent> = new Map<ElectromobilityDemoGroupType, DemoPresentationContent>()
    .set(ElectromobilityDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.electromobility.firstGroup.intro.title',
        'presentation.electromobility.firstGroup.intro.description',
        'firstGroup-desc',
    ))
    .set(ElectromobilityDemoGroupType.SECONDGROUP, new DemoPresentationContent(
        'presentation.electromobility.secondGroup.intro.title',
        'presentation.electromobility.secondGroup.intro.description',
        'secondGroup-desc',
    ))
    .set(ElectromobilityDemoGroupType.THIRDGROUP, new DemoPresentationContent(
        'presentation.electromobility.thirdGroup.intro.title',
        'presentation.electromobility.thirdGroup.intro.description',
        'thirdGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const ElectromobilityPresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.CEVOLVER_1, new DemoPresentationContent(
        'presentation.electromobility.firstGroup.demo.demo1.title',
        'presentation.electromobility.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
        null,
        new PresentationContentOptions(true),
    ))
    .set(DemoName.XDASHSOFLEET_1, new DemoPresentationContent(
        'presentation.electromobility.secondGroup.demo.demo1.title',
        'presentation.electromobility.secondGroup.demo.demo1.description',
        'secondGroup-demo1',
        null,
        new PresentationContentOptions(true),
    ))
    .set(DemoName.DASHBOARDMOUVEMENT, new DemoPresentationContent(
        'presentation.electromobility.thirdGroup.demo.demo1.title',
        'presentation.electromobility.thirdGroup.demo.demo1.description',
        'thirdGroup-demo1',
        null,
        new PresentationContentOptions(true),
    ))
    .set(DemoName.DASHBOARDPORPOISE, new DemoPresentationContent(
        'presentation.electromobility.thirdGroup.demo.demo2.title',
        'presentation.electromobility.thirdGroup.demo.demo2.description',
        'thirdGroup-demo2',
        null,
        new PresentationContentOptions(true),
    ));

// Access information for each demonstration
export const ElectromobilityPresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.CEVOLVER_1, new DemoPathAccess('demo/cevolver-1', DEMO_ROLES.CEVOLVER_ROLES))
    .set(DemoName.XDASHSOFLEET_1, new DemoPathAccess('demo/xdash/sofleet-1', DEMO_ROLES.XDASHSOFLEET_ROLES))
    .set(DemoName.DASHBOARDMOUVEMENT, new DemoPathAccess('demo/dashboard-mouvement-1', DEMO_ROLES.DASHBOARDMOUVEMENT_ROLES))
    .set(DemoName.DASHBOARDPORPOISE, new DemoPathAccess('demo/dashboard-porpoise-1', DEMO_ROLES.DASHBOARDPORPOISE_ROLES));
