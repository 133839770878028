<form class="map-control-container" [formGroup]="controlFormGroup">
    <div class="switch switch-sm switch-container">
            <input type="checkbox" class="switch" formControlName="meteoState" id="meteoState">
            <label class="slider round" for="meteoState">
                Météo
            </label>
    </div>
    <div class="legend-container" *ngIf="controlFormGroup.get('meteoState').value">
        <div class="legend-content">
            <div class="legend-item"><img src="/content/images/demos/bike/drop_white.png" height="20" width="15">
                <span>Aucune</span></div>
            <div class="legend-item"><img src="/content/images/demos/bike/drop_0.png" height="20" width="15">
                <span>Bruine</span></div>
            <div class="legend-item"><img src="/content/images/demos/bike/drop_1.png" height="20" width="15"><span>Pluie faible</span></div>
            <div class="legend-item"><img src="/content/images/demos/bike/drop_2.png" height="20" width="15"><span>Pluie modérée</span></div>
            <div class="legend-item"><img src="/content/images/demos/bike/drop_3.png" height="20" width="15"><span>Pluie forte</span></div>
        </div>
    </div>
    <div class="switch switch-sm switch-container">
            <input type="checkbox" class="switch" formControlName="airQualityState" id="airQualityState">
            <label class="slider round" for="airQualityState">
                Qualité de l'air
            </label>
    </div>
</form>
