import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GatewayAngularSharedModule } from 'app/shared/shared.module';
import { HOME_ROUTE } from './home.route';
import { HomeComponent } from './home.component';
import { SharedDirectivesModule } from 'app/global/directive/shared-directives.module';

@NgModule({
  imports: [
      GatewayAngularSharedModule,
      SharedDirectivesModule,
      RouterModule.forChild([HOME_ROUTE])
  ],
  declarations: [HomeComponent],
})
export class GatewayAngularHomeModule {}
