import { DemoUrlHandler } from './demo-url-handler';
import { MapDataPropertyHandler } from 'app/global/component/react/react-map-demo/map-data-property-handler';

export class ReactDemoConfig<T extends DemoUrlHandler, U> {
    constructor(public rootPath: string,
                public rootElement: any,
                public urlHandler: T,
                public propertyHandler: MapDataPropertyHandler,
                public options: U) {
    }
}
