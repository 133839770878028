import { isNil } from 'lodash';

import { MapCoordinates } from './map-coordinates';
import { isArrayEmpty } from '../../utils/global.helper';
import { TruckWaypoint, Waypoint, WeatherWaypoint } from './way-point';

export class ItineraryPoint {

    static areCoordinatesValid(point: ItineraryPoint): boolean {
        return point && point.coordinates &&
            !isNil(point.coordinates.latitude) && !isNil(point.coordinates.latitude);
    }

    static mapToWaypoints(points: ItineraryPoint[]): Waypoint[] {
        if (isArrayEmpty(points)) {
            return [];
        }
        return points.map(point => new Waypoint(
            point.coordinates.latitude,
            point.coordinates.longitude
        ));
    }

    static mapToTruckWaypoints(points: ItineraryPoint[]): TruckWaypoint[] {
        if (isArrayEmpty(points)) {
            return [];
        }
        return points.map(point => new TruckWaypoint(
            point.coordinates.latitude,
            point.coordinates.longitude
        ));
    }

    static mapToWeatherWaypoints(points: ItineraryPoint[]): WeatherWaypoint[] {
        if (isArrayEmpty(points)) {
            return [];
        }
        return points.map(point => new WeatherWaypoint(
            point.coordinates.latitude,
            point.coordinates.longitude
        ));
    }
    constructor(public id: number,
                public address = '',
                public coordinates = new MapCoordinates(null, null)) {
    }
}

export enum ItineraryImgUrl {
    START = 'content/images/icons/start.png',
    INTERMEDIATE = 'content/images/icons/intermediate.png',
    STOP = 'content/images/icons/stop.png',
    TRUCK_DRY_TANK = 'content/images/demos/truck/dry-tank-icon.png',
    CHARGING_POINT = 'content/images/demos/cevolver/cp.png',
    CHARGING_POINT_ECO = 'content/images/demos/cevolver/cpe.png',
    TRUCK_TIMELINE = 'content/images/demos/global/black-circle.png'
}

export enum TimelineImgUrl {
    DEFAULT = 'content/images/icons/circle.png'
}

export type ImgUrl = ItineraryImgUrl | TimelineImgUrl;
