import {difference, map} from 'lodash';
import * as d3 from 'd3';
import {Injectable} from '@angular/core';
import {extractRGBValues, getRandomColor} from '../utils/color-utils';
import {ExtendedPolyline} from './leaflet-maps/extended-polyline';
import {isArrayEmpty} from '../utils/global.helper';
import {MapControlName} from './map-control/map-controle-name';
import {MeteoResultService} from 'app/demos/meteo/meteo-result.service';
import {GlobalResult} from 'app/global/class/global-result';

@Injectable()
export class RouteColorsService {
    static readonly DEFAULT_ROUTE_OPACITY = 0.9;
    static readonly SELECTED_ROUTE_OPACITY = 1;
    static readonly RGB_BASE_VALUES = [
        '41, 171, 226',
        '232, 99, 19',
        '2, 244, 127',
        '255, 203, 36',
        '239, 41, 247',
        '0, 87, 23',
        '100, 0, 100',
        '255, 191, 191',
        '147, 118, 255',
        '144, 0, 13'
    ];

    constructor(private readonly meteoResultService: MeteoResultService) {}

    /**
     * Return the next unused color from the default colors list
     * if all colors are already used, generate a new random color
     *
     * @return {*} a string defining a new color in format 'R, G, B'
     */
    getNextAvailableColor(results: GlobalResult[]): string {
        // Retrieve any existing layers
        const usedColors = this.getRGBValuesFromResults(results);
        const availableColors = difference(RouteColorsService.RGB_BASE_VALUES, usedColors);
        return isArrayEmpty(availableColors) ? getRandomColor() : availableColors[0] ;
    }

    /**
     * Return the next unused color from the default colors list
     * if all colors are already used, generate a new random color
     *
     * @return {*} a string defining a new color in format 'R, G, B'
     */
    getNextColor(usedColors: string[]): string {
        // Retrieve any existing layers
        const availableColors = difference(RouteColorsService.RGB_BASE_VALUES, usedColors);
        return isArrayEmpty(availableColors) ? getRandomColor() : availableColors[0] ;
    }

    getRGBAColorWithOpacity(baseValue, isSelected = false): string {
        const opacity = isSelected ? RouteColorsService.SELECTED_ROUTE_OPACITY : RouteColorsService.DEFAULT_ROUTE_OPACITY;
        return `rgba(${baseValue}, ${opacity})`;
    }

    getRGBValuesFromResults(results: GlobalResult[]): string[] {
        const layers = map(results, (result: GlobalResult) => result.routeLayer);
        return map(layers, (layer: ExtendedPolyline) => extractRGBValues(layer.options.color)) || [];
    }

    getColorFromLinearScale(value: number, valueRange = [0, 50, 70, 100], colorRange = ['green', 'yellow', 'orange', 'red']): string {
        const colorScale = d3.scale.linear().domain(valueRange).range(colorRange);
        return colorScale(value);
    }

    getColorFromValue(value: number, controlName: MapControlName): string {
        if (controlName === MapControlName.METEO) {
            return this.meteoResultService.getRGBFromIntensity(value);
        }
        if (controlName === MapControlName.AIR_QUALITY) {
            return this.getColorFromLinearScale(value);
        }
    }
}
