<div class="row gallery-container justify-content-around">
    <div class="gallery-inner-container" align="center">
        <div class="container-fluid" align="center">
            <div class="row m-3 justify-content-around">
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.ECOROUTING_1)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/eco-routing-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/optemus/optemus_round.png">
                        <h4 class="card-title" jhiTranslate="energyEfficiency.optemus"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.CEVOLVER_1)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/cevolver-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/cevolver/cevolver_round.png">
                        <h4 class="card-title" jhiTranslate="energyEfficiency.cevolver"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.XDASHSOFLEET_1)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/xdash/sofleet-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/xdash-sofleet/xdash-sofleet_round.png">
                        <h4 class="card-title" jhiTranslate="energyEfficiency.xdashSofleet"></h4>
                    </a>
                </div>
            </div>
            <div class="btn btn-dark" jhiTranslate="entity.action.back" routerLink="/"></div>
            <div class="row m-3 justify-content-around"></div>
        </div>
    </div>
</div>
