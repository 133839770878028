import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { GlobalResult } from 'app/global/class/global-result';
import { RouteColorsService } from '../maps/route-colors.service';
import { isArrayEmpty } from '../utils/global.helper';

@Injectable()
export class ResultsSharedService {

    cachedResults: GlobalResult[] = [];

    globalResults = new BehaviorSubject<GlobalResult[]>([]);
    selectedResult = new BehaviorSubject<GlobalResult>(undefined);

    constructor(private readonly routeColorsService: RouteColorsService) {
    }

    initResult(): void {
        this.cachedResults = [];
        this.globalResults.next([]);
        this.selectedResult.next(undefined);
    }

    getGlobalResults(): BehaviorSubject<GlobalResult[]> {
        return this.globalResults;
    }

    setGlobalResults(results: GlobalResult[]): void {
        this.globalResults.next(results);
    }

    getGlobalResultsValue(): GlobalResult[] {
        return this.globalResults.getValue();
    }

    refreshGlobalResults(): void {
        const currentValues = this.getGlobalResultsValue();
        this.setGlobalResults([...currentValues]);
    }

    getGlobalResultsWithCache(): GlobalResult[] {
        return [...this.globalResults.getValue(), ...this.cachedResults];
    }

    addResultToCache(results: GlobalResult[]): void  {
        this.cachedResults = [...this.cachedResults, ...results];
    }

    processCache(): void  {
        if (!isArrayEmpty(this.cachedResults)) {
            const allResults = this.getGlobalResultsWithCache();
            this.cachedResults = [];
            this.setGlobalResults(allResults);
        }
    }

    // Selected result
    getSelectedResult(): BehaviorSubject<GlobalResult> {
        return this.selectedResult;
    }

    getSelectedResultValue(): GlobalResult {
        return this.selectedResult.getValue();
    }

    nextSelectedResult(newSelectedResult: GlobalResult): void {
        this.selectedResult.next(newSelectedResult);
    }

    // Color
    getNextAvailableColor(): string {
        return this.routeColorsService.getNextAvailableColor(this.getGlobalResultsWithCache());
    }

}
