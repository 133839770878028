import {DemoPresentationContent} from 'app/demos/presentation/class/demo-presentation-content';
import {DemoPathAccess} from 'app/demos/presentation/class/demo-path-access';
import {DEMO_ROLES} from 'app/demos/role.constants';
import {AirQualityDemoGroupType} from 'app/demos/presentation/airQuality-presentation/airQuality-demo-group-type';
import {DemoName} from '../../demo-name';

// Demonstration groups
export const AirQualityGroups: AirQualityDemoGroupType[] = [AirQualityDemoGroupType.FIRSTGROUP, AirQualityDemoGroupType.THIRDGROUP, AirQualityDemoGroupType.SECONDGROUP];

// Groupe de démos de la première section
const firstGroupDemos = [
    DemoName.GECOAIRCITYSTATISTICS,
    DemoName.GECOAIRUSERTRIPS,
    DemoName.GECOAIRUSERTRIPS_2,
    DemoName.GECOAIRCOZYCLOUD,
    DemoName.PDM_1,
    DemoName.XDASHPDM_2,
    DemoName.GECOAIRMONITORING
];

// Groupe de démos de la seconde section
const secondGroupDemos = [
    DemoName.RTAMS,
    DemoName.SIMULATIONZFE_1,
    DemoName.MOBICLOUDDATASPARC,
    DemoName.GECOAIRCARTOGRAPHY,
    DemoName.REALTIMEEMISSIONS_1,
    //DemoName.XDASHSPARC_1,
    //DemoName.CARTOREACT,

];

// Demos for each presentation group - all demo information must be provided below
export const AirQualityDemoGroups: Map<AirQualityDemoGroupType, DemoName[]> = new Map<AirQualityDemoGroupType, DemoName[]>()
    .set(AirQualityDemoGroupType.FIRSTGROUP, firstGroupDemos)
    .set(AirQualityDemoGroupType.SECONDGROUP, secondGroupDemos)
    .set(AirQualityDemoGroupType.THIRDGROUP, [DemoName.GECOAIRSDKANDROID_1, DemoName.GECOAIRSDKIOS_1]);

// Content for the header presentation, at the tof of the page
// Presentation images are in content/images/demos/presentation/
export const AirQualityPresentationTitle = new DemoPresentationContent(
    'presentation.airQuality.intro.first.title',
    'presentation.airQuality.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const AirQualityPresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.airQuality.intro.second.title',
        'presentation.airQuality.intro.second.description',
        'top-img1',
        new DemoPathAccess('https://www.gecoair.fr', [])
    )
];

// Group level content (presentation of the demonstration group)
export const AirQualityDemoGroupContents: Map<AirQualityDemoGroupType, DemoPresentationContent> = new Map<AirQualityDemoGroupType, DemoPresentationContent>()
    .set(AirQualityDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.intro.title',
        'presentation.airQuality.firstGroup.intro.description',
        'firstGroup-desc',
    ))
    .set(AirQualityDemoGroupType.SECONDGROUP, new DemoPresentationContent(
        'presentation.airQuality.secondGroup.intro.title',
        'presentation.airQuality.secondGroup.intro.description',
        'secondGroup-desc',
    ))
    .set(AirQualityDemoGroupType.THIRDGROUP, new DemoPresentationContent(
        'presentation.airQuality.thirdGroup.intro.title',
        'presentation.airQuality.thirdGroup.intro.description',
        'thirdGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const AirQualityPresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.GECOAIRCITYSTATISTICS, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.demo.demo1.title',
        'presentation.airQuality.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ))
    .set(DemoName.GECOAIRUSERTRIPS, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.demo.demo2.title',
        'presentation.airQuality.firstGroup.demo.demo2.description',
        'firstGroup-demo2',
    ))
    .set(DemoName.PDM_1, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.demo.demo3.title',
        'presentation.airQuality.firstGroup.demo.demo3.description',
        'firstGroup-demo3',
    ))
    .set(DemoName.XDASHPDM_2, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.demo.demo4.title',
        'presentation.airQuality.firstGroup.demo.demo4.description',
        'firstGroup-demo4',
        ))
    .set(DemoName.GECOAIRCOZYCLOUD, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.demo.demo5.title',
        'presentation.airQuality.firstGroup.demo.demo5.description',
        'firstGroup-demo5',
        ))
    .set(DemoName.GECOAIRMONITORING, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.demo.demo6.title',
        'presentation.airQuality.firstGroup.demo.demo6.description',
        'firstGroup-demo6',
    ))
    .set(DemoName.GECOAIRUSERTRIPS_2, new DemoPresentationContent(
        'presentation.airQuality.firstGroup.demo.demo7.title',
        'presentation.airQuality.firstGroup.demo.demo7.description',
        'firstGroup-demo7',
    ))
    .set(DemoName.REALTIMEEMISSIONS_1, new DemoPresentationContent(
        'presentation.airQuality.secondGroup.demo.demo1.title',
        'presentation.airQuality.secondGroup.demo.demo1.description',
        'secondGroup-demo1',
    ))
    .set(DemoName.GECOAIRCARTOGRAPHY, new DemoPresentationContent(
        'presentation.airQuality.secondGroup.demo.demo2.title',
        'presentation.airQuality.secondGroup.demo.demo2.description',
        'secondGroup-demo2',
    ))
    //.set(DemoName.XDASHSPARC_1, new DemoPresentationContent(
    //    'presentation.airQuality.secondGroup.demo.demo3.title',
    //    'presentation.airQuality.secondGroup.demo.demo3.description',
    //    'secondGroup-demo3',
    //))
    .set(DemoName.MOBICLOUDDATASPARC, new DemoPresentationContent(
       'presentation.airQuality.secondGroup.demo.demo4.title',
       'presentation.airQuality.secondGroup.demo.demo4.description',
       'secondGroup-demo4',
    ))
    //.set(DemoName.CARTOREACT, new DemoPresentationContent(
    //    'presentation.airQuality.secondGroup.demo.demo5.title',
    //    'presentation.airQuality.secondGroup.demo.demo5.description',
    //    'secondGroup-demo5',
    //))
   .set(DemoName.RTAMS, new DemoPresentationContent(
        'presentation.airQuality.secondGroup.demo.demo6.title',
        'presentation.airQuality.secondGroup.demo.demo6.description',
        'secondGroup-demo6',
    ))
    .set(DemoName.SIMULATIONZFE_1, new DemoPresentationContent(
        'presentation.airQuality.secondGroup.demo.demo7.title',
        'presentation.airQuality.secondGroup.demo.demo7.description',
        'secondGroup-demo7',
    ))
    .set(DemoName.GECOAIRSDKANDROID_1, new DemoPresentationContent(
        'presentation.airQuality.thirdGroup.demo.demo1.title',
        'presentation.airQuality.thirdGroup.demo.demo1.description',
        'thirdGroup-demo1',
    ))
    .set(DemoName.GECOAIRSDKIOS_1, new DemoPresentationContent(
        'presentation.airQuality.thirdGroup.demo.demo2.title',
        'presentation.airQuality.thirdGroup.demo.demo2.description',
        'thirdGroup-demo2',
    ));

// Access information for each demonstration
export const AirQualityPresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.GECOAIRCITYSTATISTICS, new DemoPathAccess('demo/gecoaircitystatistics-1', DEMO_ROLES.GECOAIRCITYSTATISTICS_ROLES))
    .set(DemoName.GECOAIRUSERTRIPS, new DemoPathAccess('demo/xdash/gecoairusertrips-1', DEMO_ROLES.GECOAIRUSERTRIPS_ROLES))
    .set(DemoName.GECOAIRUSERTRIPS_2, new DemoPathAccess('demo/xdash/gecoairusertrips-2', DEMO_ROLES.GECOAIRUSERTRIPS_ROLES))
    .set(DemoName.GECOAIRCOZYCLOUD, new DemoPathAccess('demo/xdash/gecoaircozycloud-1', DEMO_ROLES.GECOAIRCOZYCLOUD_ROLES))
    .set(DemoName.PDM_1, new DemoPathAccess('demo/pdm-1/statistique', DEMO_ROLES.PDM_ROLES))
    .set(DemoName.XDASHPDM_2, new DemoPathAccess('demo/xdash/pdm-2', DEMO_ROLES.PDM_ROLES))
    .set(DemoName.REALTIMEEMISSIONS_1, new DemoPathAccess('demo/mapdata/realtimeemissions-1', DEMO_ROLES.GECOAIRREALTIMEEMISSIONS_ROLES))
    .set(DemoName.GECOAIRCARTOGRAPHY, new DemoPathAccess('demo/xdash/gecoaircartography-1', DEMO_ROLES.GECOAIRCARTOGRAPHY_ROLES))
    .set(DemoName.GECOAIRSDKANDROID_1, new DemoPathAccess('demo/gecoairsdk-1/android', DEMO_ROLES.GECOAIRSDK_ROLES))
    .set(DemoName.GECOAIRSDKIOS_1, new DemoPathAccess('demo/gecoairsdk-1/ios', DEMO_ROLES.GECOAIRSDK_ROLES))
    .set(DemoName.GECOAIRMONITORING, new DemoPathAccess('demo/gecoairmonitoring-1', DEMO_ROLES.GECOAIR_MONITORING_ROLES))
    //.set(DemoName.XDASHSPARC_1, new DemoPathAccess('demo/xdash/sparc-1', DEMO_ROLES.XDASHSPARC_ROLES))
    .set(DemoName.MOBICLOUDDATASPARC, new DemoPathAccess('demo/mobiclouddatasparc-1', DEMO_ROLES.MOBICLOUDDATASPARC_ROLES))
    //.set(DemoName.CARTOREACT, new DemoPathAccess('demo/carto-react-1', DEMO_ROLES.CARTOREACT_ROLES))
    .set(DemoName.RTAMS, new DemoPathAccess(location.origin+'/rtams/', DEMO_ROLES.RTAMS_ROLES))
    .set(DemoName.SIMULATIONZFE_1, new DemoPathAccess('demo/xdash/simulationzfe-1', DEMO_ROLES.XDASHSIMULATIONZFE_ROLES));
