import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LONG_RUN_GET_VERSION_WEB_SERVICE } from 'app/demos/long-run-demo/long-run-demo.constants';
import { DemoName } from 'app/demos/demo-name';
import { TCO2_1_SERVICE_VERSION_URL } from 'app/demos/tco2-demo/tco2-demo.constants';
import { TCO2_2_SERVICE_VERSION_URL } from 'app/demos/tco2data-demo/tco2data-demo.constants';
import { SIMULATIONTOOLS_1_SERVICE_VERSION_URL } from 'app/demos/simulationtools-demo/simulationtools-demo.constants';

@Injectable()
export class VersionService {

    static readonly DEFAULT_URL = 'api/version';
    static readonly BIKE_URL = 'bike/service/getVersion';
    static readonly FUELPREDICTION_URL = 'fuelprediction/service/getVersion';
    static readonly TRUCK_URL = 'truckrouting/service/getVersion';
    static readonly LONGRUN_1_URL = LONG_RUN_GET_VERSION_WEB_SERVICE;
    static readonly EMPOWER_1_URL = 'empower/service/getVersion';
    static readonly MOBILITY_BIKEDATA_URL = 'bikedata/service/getVersion';
    static readonly WEATHER_URL = 'weather/service/getVersion';
    static readonly TCO_URL = 'tco/service/getVersion';
    static readonly MTES_URL = 'mtes/service/getVersion';
    static readonly REALE_URL = 'reale/service/getVersion';
    static readonly REALTIMEEMISSIONS_1 = 'reveal/service/getVersion';
    static readonly SOFLEET_URL = 'sofleet/service/getVersion';
    static readonly DJANGOTEMPLATE_URL = 'djangotemplate/service/getVersion';
    static readonly MOBICLOUDDATA_URL = 'mobicloud-data/service/getVersion';
    static readonly SPARC_URL = 'sparc/service/getVersion';
    static readonly ACVSCREENING_URL = 'acv-screening/service/getVersion';
    static readonly SIMULATIONZFE_URL = 'simulation-zfe/service/getVersion';
    static readonly CP4SC_URL = 'urbandataplatform/service/getVersion';
    static readonly SERVICE_NOT_AVAILABLE = '';

    private versionUrls = new Map<DemoName, string>();

    constructor(private readonly httpClient: HttpClient) {
        this.initUrls();
    }

    initUrls(): void {
        this.versionUrls.set(DemoName.MAIN, VersionService.DEFAULT_URL);
        this.versionUrls.set(DemoName.BIKE, VersionService.BIKE_URL);
        this.versionUrls.set(DemoName.FUELPREDICTION, VersionService.FUELPREDICTION_URL);
        this.versionUrls.set(DemoName.COMMUNITYMAP, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.TRUCK_ROUTING, VersionService.TRUCK_URL);
        this.versionUrls.set(DemoName.PDM_1, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.XDASHPDM_2, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.MOBILITY_1, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.BIKEDATA_1, VersionService.MOBILITY_BIKEDATA_URL);
        this.versionUrls.set(DemoName.WEATHER_1, VersionService.WEATHER_URL);
        this.versionUrls.set(DemoName.ECOROUTING_1, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.XDASHGEOCODING_1, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.XDASHTCO_1, VersionService.TCO_URL);
        this.versionUrls.set(DemoName.XDASHSOFLEET_1, VersionService.SOFLEET_URL);
        this.versionUrls.set(DemoName.XDASHSPARC_1, VersionService.SPARC_URL);
        this.versionUrls.set(DemoName.XDASHGOOGLETIMELINES_1, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.DASHBOARDE4T, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.DASHBOARDDJANGO, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.MTES_1, VersionService.MTES_URL);
        this.versionUrls.set(DemoName.REALE_1, VersionService.REALE_URL);
        this.versionUrls.set(DemoName.REALTIMEEMISSIONS_1, VersionService.REALTIMEEMISSIONS_1);
        this.versionUrls.set(DemoName.LONGRUN_1, VersionService.LONGRUN_1_URL);
        this.versionUrls.set(DemoName.EMPOWER_1, VersionService.EMPOWER_1_URL);
        this.versionUrls.set(DemoName.EMISSIONFACTORS, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.TCO2_1, TCO2_1_SERVICE_VERSION_URL);
        this.versionUrls.set(DemoName.TCO2_2, TCO2_2_SERVICE_VERSION_URL);
        this.versionUrls.set(DemoName.SIMULATIONTOOLS_1, SIMULATIONTOOLS_1_SERVICE_VERSION_URL);
        this.versionUrls.set(DemoName.CONCAWE_1, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.CONCAWE_2, VersionService.SERVICE_NOT_AVAILABLE);
        this.versionUrls.set(DemoName.DJANGOTEMPLATE, VersionService.DJANGOTEMPLATE_URL);
        this.versionUrls.set(DemoName.MOBICLOUDDATA, VersionService.MOBICLOUDDATA_URL);
        this.versionUrls.set(DemoName.MOBICLOUDDATASPARC, VersionService.SPARC_URL);
        this.versionUrls.set(DemoName.XDASHACVSCREENING, VersionService.ACVSCREENING_URL);
        this.versionUrls.set(DemoName.SIMULATIONZFE_1, VersionService.SIMULATIONZFE_URL);
        this.versionUrls.set(DemoName.CP4SC_1, VersionService.CP4SC_URL);
    }

    getDemoVersion(demoTypeName?: DemoName): Observable<string> {
        const url = demoTypeName ? this.versionUrls.get(demoTypeName) : VersionService.DEFAULT_URL;
        // If version service is not available yet for the required demo,
        // return an observable containing an empty string
        if (!url || url === VersionService.SERVICE_NOT_AVAILABLE) {
            return of('');
        }
        const queryOptions = {
            responseType: 'text' as 'json',
            withCredentials: true
        };
        return this.httpClient.get<string>(url, queryOptions);
    }
}
