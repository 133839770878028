export const DEMO_ROLES = {
    USER_ROLES: ['SERVICE_ALL', 'ROLE_USER'],
    BETA_ROLES: ['BETA_USER'],
    PDM_ROLES: ['SERVICE_ALL', 'PDM'],
    COMMUNITYMAP_ROLES: ['SERVICE_ALL', 'COMMUNITYMAP'],
    MOBILITYOBSERVATORY_ROLES: ['SERVICE_ALL', 'MOBILITYOBSERVATORY'],
    MOBILITYDOUCE_ROLES: ['SERVICE_ALL', 'BIKEDATA'],
    BIKE_ROLES: ['SERVICE_ALL', 'BIKE'],
    TRUCKROUTING_ROLES: ['SERVICE_ALL', 'TRUCKROUTING'],
    FUELPREDICTION_ROLES: ['SERVICE_ALL', 'FUELPREDICTION'],
    WEATHER_ROLES: ['SERVICE_ALL', 'WEATHER'],
    OPTEMUS_ROLES: ['SERVICE_ALL', 'OPTEMUS'],
    CEVOLVER_ROLES: ['SERVICE_ALL', 'CEVOLVER'],
    XDASHGEOCODING_ROLES: ['SERVICE_ALL', 'XDASHGEOCODING'],
    XDASHTCO_ROLES: ['SERVICE_ALL', 'TCO'],
    XDASHPDM_2: ['SERVICE_ALL', 'PDM'],
    DASHBOARDE4T_ROLES: ['SERVICE_ALL', 'E4T'],
    DASHBOARDE4TADEME_ROLES: ['E4TADEME'],
    DASHBOARDDJANGO_ROLES: ['SERVICE_ALL', 'DJANGOHTML'],
    GECOAIRCITYSTATISTICS_ROLES: ['SERVICE_ALL', 'GECOAIRCITYSTATISTICS'],
    GECOAIR_MONITORING_ROLES: ['SERVICE_ALL', 'GECOAIR_MONITORING'],
    GECOAIRCARTOGRAPHY_ROLES: ['SERVICE_ALL', 'GECOAIRCARTOGRAPHY'],
    GECOAIRUSERTRIPS_ROLES: ['SERVICE_ALL', 'GECOAIRUSERTRIPS'],
    GECOAIRCOZYCLOUD_ROLES: ['SERVICE_ALL', 'GECOAIRCOZYCLOUD'],
    GECOAIRREALTIMEEMISSIONS_ROLES: ['SERVICE_ALL', 'GECOAIRREALTIMEEMISSIONS'],
    GECOAIRSDK_ROLES: ['SERVICE_ALL', 'GECOAIRSDK'],
    MTES_ROLES: ['SERVICE_ALL', 'MTES'],
    REALE_ROLES: ['SERVICE_ALL', 'REALE'],
    REALE_IFPEN_ROLES: ['SERVICE_ALL'],
    GRAFANA_ROLES: ['SERVICE_ALL'],
    KIBANA_ROLES: ['SERVICE_ALL'],
    LONGRUNECOROUTING_ROLES: ['SERVICE_ALL', 'LONGRUN'],
    EMPOWERECOROUTING_ROLES: ['SERVICE_ALL', 'EMPOWER'],
    XDASH_MOBICLOUD_ROLES: ['SERVICE_ALL', 'XDASHMOBICLOUD'],
    EMISSIONFACTORS_ROLES: ['SERVICE_ALL', 'EMISSIONFACTORS'],
    TCO2_ROLES: ['SERVICE_ALL', 'TCO2'],
    TCO2DATA_ROLES: ['SERVICE_ALL', 'TCO2DATA'],
    SIMULATIONTOOLS_ROLES: ['SERVICE_ALL', 'SIMULATIONTOOLS'],
    DASHBOARDH24EU_ROLES: ['SERVICE_ALL', 'H24EU'],
    DASHBOARDE85SNPAA_ROLES: ['SERVICE_ALL', 'E85SNPAA'],
    DASHBOARDMOUVEMENT_ROLES: ['SERVICE_ALL', 'MOUVEMENT'],
    DASHBOARDPORPOISE_ROLES: ['SERVICE_ALL', 'PORPOISE'],
    DASHBOARDCMACGMACV_ROLES: ['SERVICE_ALL', 'CMACGM_ACV'],
    DASHBOARDCMACGMECO_ROLES: ['SERVICE_ALL', 'CMACGM_ECO'],
    FUELECOCOACH_ROLES: ['SERVICE_ALL', 'FUELECOCOACH'],
    AFTERSIM: ['SERVICE_ALL', 'AFTERSIM'],
    XDASHREALE_1: ['SERVICE_ALL', 'ROLE_USER'],
    CONCAWE_ROLES: ['SERVICE_ALL', 'CONCAWE'],
    TDBCARBONLOCAL_ROLES: ['SERVICE_ALL', 'TDBCARBONLOCAL'],
    XDASHSOFLEET_ROLES: ['SERVICE_ALL', 'XDASHSOFLEET'],
    DJANGOTEMPLATE_ROLES: ['SERVICE_ALL', 'DJANGOTEMPLATE'],
    MOBICLOUDDATA_ROLES: ['SERVICE_ALL', 'MOBICLOUD_DATA'],
    XDASHSPARC_ROLES: ['SERVICE_ALL', 'XDASHSPARC'],
    MOBICLOUDDATASPARC_ROLES: ['SERVICE_ALL', 'MOBICLOUDDATASPARC'],
    CARTOREACT_ROLES: ['SERVICE_ALL', 'CARTOREACT'],
    XDASHGOOGLETIMELINES_ROLES: ['SERVICE_ALL', 'XDASHGOOGLETIMELINES'],
    RTAMS_ROLES: ['SERVICE_ALL', 'RTAMS'],
    XDASHACVSCREENING_ROLES: ['SERVICE_ALL', 'ACVSCREENING'],
    XDASHSIMULATIONZFE_ROLES: ['SERVICE_ALL', 'SIMULATIONZFE'],
    CP4SC_ROLES: ['SERVICE_ALL', 'CP4SC'],
    VEHICLEFLEET_ROLES: ['SERVICE_ALL','VEHICLEFLEET'],
};

/**
 * Rôles pour les pages de groupes de démos (Galleries, présentation...)
 *
 */
export const DEMO_GROUP_ROLES = {
    PRESENTATION_TRUCK: DEMO_ROLES.USER_ROLES,
    PRESENTATION_BIKE: DEMO_ROLES.USER_ROLES,
    PRESENTATION_LIGHTVEHICLE: DEMO_ROLES.USER_ROLES,
    PRESENTATION_AIRQUALITY: DEMO_ROLES.USER_ROLES,
    PRESENTATION_DIGITALIZATION: DEMO_ROLES.USER_ROLES,
    PRESENTATION_ELECTROMOBILITY: DEMO_ROLES.USER_ROLES,
    PRESENTATION_EXAMPLE: DEMO_ROLES.BETA_ROLES,
    PRESENTATION_POLLUTINGEMISSIONS: DEMO_ROLES.USER_ROLES,
    PRESENTATION_PROSPECTIVESTUDIES: DEMO_ROLES.USER_ROLES,
    PRESENTATION_ENVIRONMENTALFOOTPRINT: DEMO_ROLES.USER_ROLES,
};
