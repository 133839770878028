import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { geoJSON, LatLng, Layer, Marker } from 'leaflet';
import { LeafletMapConfigService } from './leaflet-map-config.service';
import { ItineraryPointLayer } from 'app/global/class/itinerary-point-layer';
import { isArrayEmpty, unsubscribe } from '../../../utils/global.helper';

import { GlobalBikeResult } from '../../../demos/bike-demo/result/global-bike-result';
import { ExtendedLayer } from '../extended-layer';
import { MapControlSharedService } from '../../map-control/map-control.shared.service';
import { MapControlName } from '../../map-control/map-controle-name';
import { BikeTimelineSharedService } from '../../../demos/bike-demo/temporary-signal-chart/bike-timeline-shared.service';
import { ResultsSharedService } from '../../../demos/results-shared.service';
import { LeftSidebarSharedService } from 'app/left-sidebar/left-sidebar.shared.service';

@Component({
    selector: 'dc-leaflet-map',
    templateUrl: './leaflet-map.component.html',
    styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly MAP_URL = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png';

    mapInstance: L.Map;
    options = null; // Leaflet map options
    pointLayers: Marker[] = [];
    meteoLayers: Layer[] = [];
    airQualityLayers: Layer[] = [];
    itineraryPoints: ItineraryPointLayer[] = [];
    layers: Layer[] = [];
    timelinePointLayer: Marker = null;
    fitBounds: any; // Leaflet object to resize and center map around layers
    zoom;
    activeMapControl: MapControlName;
    center: LatLng = null;

    firstPoint = true;
    subs: Subscription[] = [];

    @Input() itineraryOnly: boolean; // Display itinerary points only
    @Input() disableControls: boolean; // Disable custom layers controls (timeline, meteo && air quality)

    constructor(private readonly leafletMapService: LeafletMapConfigService,
                private readonly mapControlSharedService: MapControlSharedService,
                private readonly resultsSharedService: ResultsSharedService,
                private readonly timelineSharedService: BikeTimelineSharedService,
                private readonly leftSidebarSharedService: LeftSidebarSharedService) {
    }

    ngOnInit(): void {
        this.initMap();
        this.subs.push(this.leftSidebarSharedService.onBarAction().pipe(
            delay(100)
        ).subscribe(() => window.dispatchEvent(new Event('resize'))));
    }

    ngAfterViewInit(): void {
        this.subscribeToLayerChanges();
        window.dispatchEvent(new Event('resize'));
    }

    ngOnDestroy(): void {
        unsubscribe(this.subs);
        this.mapControlSharedService.init();
        this.resultsSharedService.initResult();
    }

    onMapReady(map: L.Map) {
        this.mapInstance = map;
    }

    private subscribeToLayerChanges(): void {
        this.subscribeToPointsChange();
        this.subscribeToOtherChanges();
    }

    private subscribeToPointsChange(): void {
        this.subs.push(this.leafletMapService.getPointLayers().subscribe((pointLayers: ItineraryPointLayer[]) => {
            this.itineraryPoints = pointLayers;
            this.pointLayers = this.leafletMapService.mapAndFilterLayers(pointLayers);
            // Center and adjust zoom - seems to be a bit smoother with timeout 200
            setTimeout(() => this.updateFitBounds(), 200);
        }));
    }

    private subscribeToOtherChanges(): void {
        if (this.itineraryOnly) {
            return;
        }
        this.subs.push(this.resultsSharedService.getGlobalResults()
            .subscribe((results: GlobalBikeResult[]) => this.addLayers(results)));

        if (this.disableControls) {
            return;
        }
        this.subs.push(this.mapControlSharedService.getActivated()
            .subscribe((activated: MapControlName) => this.updateControlLayers(activated)));
        this.subs.push(this.timelineSharedService.getTimelineLayer()
            .subscribe((tLMarker: ItineraryPointLayer) => this.updateTimelineMarker(tLMarker)));
    }

    updateControlLayers(activated: MapControlName): void {
        if (this.activeMapControl !== activated) {
            this.activeMapControl = activated;
            this.refreshLayers();
        }
    }

    updateTimelineMarker(tLMarker: ItineraryPointLayer): void {
        if (tLMarker && tLMarker.layer && this.timelinePointLayer !== tLMarker.layer) {
            this.timelinePointLayer = tLMarker.layer;
        } else {
            this.timelinePointLayer = null;
        }
    }

    refreshLayers(): void {
        this.addLayers(this.resultsSharedService.getGlobalResultsValue());
    }

    initMap(): void {
        this.options = this.leafletMapService.getMapConfig(this.leafletMapService.getLayerConfig(this.MAP_URL));
    }

    private addLayers(results: GlobalBikeResult[]): void {
        this.layers = [];
        this.meteoLayers = [];
        this.airQualityLayers = [];
        if (!isArrayEmpty(results)) {
            results.forEach(result => this.addSingleLayer(result));
        }
    }

    private addSingleLayer(result: GlobalBikeResult): void {
        const layer: ExtendedLayer = result.routeLayer;
        const meteoLayer: any = result.meteoLayers;
        const airQualityLayer: any = result.airQualityLayers;
        // We need the selected layer to be displayed above the others, but leaflet doesn't provide z-index style handling
        if (!layer.data.$selected) {
            // First add the non selected layers
            this.layers.push(layer);
            this.addExtraLayers(meteoLayer, airQualityLayer);
        } else {
            // Delay the add of the selected layer, so it will always be visible
            setTimeout(() => {
                this.layers.push(layer);
                this.addExtraLayers(meteoLayer, airQualityLayer);
            });
        }
    }

    /**
     * Add meteo / airQuality layers if disable controls attribute is not true
     *
     * @param meteoLayer the leaflet object layers for the meteo
     * @param airQualityLayer the leaflet object layers for the air quality
     */
    private addExtraLayers(meteoLayer, airQualityLayer): void {
        if (this.disableControls) {
            return;
        }
        if (meteoLayer && this.isMapControlActivated(MapControlName.METEO)) {
            this.meteoLayers.push(meteoLayer);
        }
        if (airQualityLayer && this.isMapControlActivated(MapControlName.AIR_QUALITY)) {
            this.airQualityLayers.push(airQualityLayer);
        }
    }

    onMapClick(event): void {
        this.leafletMapService.updatePoint(event).subscribe();
    }

    updateFitBounds(): void {
        const fitBounds = this.leafletMapService.getFitBounds(this.itineraryPoints);
        if (!fitBounds || !fitBounds.length) {
            return null;
        }
        // If only one point, just center with default zoom
        if (fitBounds.length === 1) {
            this.zoom = this.firstPoint ? LeafletMapConfigService.DEFAULT_FR_ZOOM : null;
            this.center = new LatLng(fitBounds[0][0], fitBounds[0][1]);
        } else {
            this.firstPoint = false;
            // Else set new fitBounds
            this.fitBounds = fitBounds;
        }
    }

    isMapControlActivated(mapControlName: MapControlName): boolean {
        return !this.itineraryOnly && !this.disableControls && this.activeMapControl && this.activeMapControl === mapControlName;
    }
}
