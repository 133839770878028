export class GlobalLayersType {
    constructor(public safetyMapLayer: any,
                public safetyAccelerationMapLayer: any,
                public safetyAdherenceMapLayer: any,
                public safetyDecelerationMapLayer: any,
                public NoxLayer: any,
                public QFuelLayer: any,
                public TPCOLayer: any,
                public TPSootLayer: any,
                public confidenceLayer: any,
                public bikeLayerRoughness: any,
                public bikeLayerRoughnessRank: any,
                public avgSpeedLayer: any,
                public freeFlowSpeedLayer: any,
                public speedLimitLayer: any,
                public functionalClassLayer: any,
    ) {

    }
}
