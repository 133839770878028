import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PdfviewerService} from 'app/docs/pdfviewer/pdfviewer.service';
import {GalleryService} from 'app/galleries/documentation/gallery.service';

@Component({
  selector: 'dc-gallery-pdf-viewer',
  templateUrl: './gallery-pdf-viewer.component.html',
  styleUrls: ['./gallery-pdf-viewer.component.scss']
})
export class GalleryPdfViewerComponent implements OnInit {
    pdfSrc = 'default';
    filename: string = '';

    constructor(private readonly pdfViewerService: PdfviewerService,
                private galleryService: GalleryService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
       this.filename = this.route.snapshot.params.data;
        this.fetchPdfFile();
    }

    fetchPdfFile() {
        this.galleryService.fetchPdfFile(this.filename).subscribe(res => {
            if (res) {
                this.pdfSrc = URL.createObjectURL(res);
            }
        });
    }
}
