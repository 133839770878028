import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SimpleErrorModalComponent } from './simple-error-modal.component';
import { LoadingModalComponent } from './loading-modal.component';
import { WarningModalComponent } from './warning-modal.component';
import { BaseModalComponent } from 'app/global/component/modals/base-modal.component';

@Injectable()
export class CustomModalsService {
    constructor(private readonly bsModalService: BsModalService) {
    }

    simpleError(translationKey: string, onClose?: Function): BsModalRef {
        const options = new ModalOptions();
        options.class = 'simple-error-modal';
        const modalRef: BsModalRef = this.bsModalService.show(SimpleErrorModalComponent, options);
        if (modalRef && translationKey) {
            modalRef.content.translationKey = translationKey;
        }
        if (modalRef && onClose) {
            modalRef.content.onClose = onClose;
        }
        return modalRef;
    }

    simpleLoading(configOptions: ModalOptions): BsModalRef {
        return this.bsModalService.show(LoadingModalComponent, configOptions);
    }

    simpleWarning(warningText: string): void {
        const options: ModalOptions = new ModalOptions();
        options.class = 'warning-modal';
        const modalRef: BsModalRef = this.bsModalService.show(WarningModalComponent, options);
        modalRef.content.warningText = warningText;
    }

    simpleModal(component): BsModalRef {
        const options = new ModalOptions();
        options.class = 'modal-lg';
        return this.bsModalService.show(component, options);
    }

    basicModal(translationKey: string, options = new ModalOptions()): BsModalRef {
        options.class = options.class ? options.class + ' modal-dialog-centered' : 'modal-dialog-centered';
        const modalRef: BsModalRef = this.bsModalService.show(BaseModalComponent, options);
        modalRef.content.translationKey = translationKey;
        return modalRef;
    }
}
