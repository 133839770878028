import { Subscription } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JhiLanguageService } from 'ng-jhipster';
import { SessionStorageService } from 'ngx-webstorage';

import { VERSION } from 'app/app.constants';
import { LANGUAGES } from 'app/core/language/language.constants';
import { AccountService } from 'app/core/auth/account.service';
import { LoginModalService } from 'app/core/login/login-modal.service';
import { LoginService } from 'app/core/login/login.service';
import { ProfileService } from 'app/layouts/profiles/profile.service';
import { CurrentDemoSharedService } from 'app/global/service/current-demo-shared.service';
import { unsubscribe } from '../../utils/global.helper';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { extractDemoTitleKey, getDemoTitleKey } from 'app/utils/demo-utils';

@Component({
    selector: 'dc-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['navbar.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    inProduction?: boolean;
    isNavbarCollapsed = true;
    languages = LANGUAGES;
    swaggerEnabled?: boolean;
    version: string;
    modalRef: NgbModalRef;
    demoTitleKey: string;
    roles = DEMO_ROLES;
    subs: Subscription[] = [];

    constructor(
        private loginService: LoginService,
        private languageService: JhiLanguageService,
        private sessionStorage: SessionStorageService,
        private accountService: AccountService,
        private loginModalService: LoginModalService,
        private profileService: ProfileService,
        private router: Router,
        private readonly currentDemoSharedService: CurrentDemoSharedService
    ) {
        this.version = VERSION ? (VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION) : '';
    }

    ngOnInit(): void {
        this.profileService.getProfileInfo().subscribe(profileInfo => {
            this.inProduction = profileInfo.inProduction;
            this.swaggerEnabled = profileInfo.swaggerEnabled;
        });

        this.subs.push(
            this.currentDemoSharedService.getCurrentDemoSubject().subscribe(demoType => {
                if (demoType) {
                    this.version = demoType.version;
                    this.demoTitleKey = extractDemoTitleKey(demoType.name);
                }
            })
        );
    }

    ngOnDestroy(): void {
        unsubscribe(this.subs);
    }

    changeLanguage(languageKey: string): void {
        this.sessionStorage.store('locale', languageKey);
        this.languageService.changeLanguage(languageKey);
    }

    collapseNavbar(): void {
        this.isNavbarCollapsed = true;
    }

    isAuthenticated(): boolean {
        return this.accountService.isAuthenticated();
    }

    login() {
        this.modalRef = this.loginModalService.open();
    }

    logout(): void {
        this.collapseNavbar();
        this.loginService.logout();
        this.router.navigate(['']);
    }

    toggleNavbar(): void {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    getImageUrl(): string {
        return this.isAuthenticated() ? this.accountService.getImageUrl() : '';
    }

    getDemoTitleKey(): string {
        return getDemoTitleKey(this.demoTitleKey);
    }
}
