import { DemoUrlHandler } from 'app/global/class/demo-url-handler';
import { WsDefinition } from 'app/global/class/ws/ws-definition';

export class ChartDemoUrlHandler extends DemoUrlHandler {
    constructor(webServiceId: string,
                baseUrl: string,
                private configuration: WsDefinition,
                private calculate: WsDefinition) {
        super(webServiceId, baseUrl);
    }

    getConfiguration(): WsDefinition {
        return this.configuration;
    }

    getCalculate(): WsDefinition {
        return this.calculate;
    }
}
