import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        const doc = new DOMParser().parseFromString(value, 'text/html');
        const value123 = doc.documentElement.textContent;
        return this.sanitized.bypassSecurityTrustHtml(value123);
    }

}
