import {WAYPOINT_TYPES} from '../../demos/truck-demo/truck-demo-input';

export class Waypoint {
    constructor(public latitude = 0, public longitude = 0, public type = WAYPOINT_TYPES.PASS_THROUGH) {
    }
}

export class TruckWaypoint extends Waypoint {
    constructor(public latitude = 0,
                public longitude = 0,
                public type = WAYPOINT_TYPES.PASS_THROUGH,
                public dieselCost = null,
                public gasCost = null) {
        super(latitude, longitude, type);
    }
}

export class WeatherWaypoint extends Waypoint {
    constructor(public latitude = 0,
                public longitude = 0,
                public type = WAYPOINT_TYPES.PASS_THROUGH,
                public title = '') {
        super(latitude, longitude, type);
    }
}
