import { ChartDemoUrlHandler } from 'app/global/component/react/react-chart-board-demo/chart-demo-url.handler';
import { ChartBoardOptions } from 'app/global/component/react/react-chart-board/chart-board-options';
import { ReactDemoConfig } from 'app/global/class/react-demo-config';
import { ChartBoardRootComponent } from 'app/global/component/react/react-chart-board-demo/chart-board-root.react.component';

export class ChartBoardDemoConfig extends ReactDemoConfig<ChartDemoUrlHandler, ChartBoardOptions> {
    constructor(rootPath: string,
                urlHandler: ChartDemoUrlHandler,
                options: ChartBoardOptions = new ChartBoardOptions()) {
        super(rootPath, ChartBoardRootComponent, urlHandler, null, options);
    }
}
