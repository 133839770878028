import { DEMO_ROLES } from 'app/demos/role.constants';
import { Route } from '@angular/router';
import { HtmlViewerComponent } from 'app/docs/html-viewer/html-viewer.component';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';

export const htmlViewerRoute: Route = {
    path: 'html',
    component: HtmlViewerComponent,
    data: {
        authorities: DEMO_ROLES.USER_ROLES,
        pageTitle: 'pdfviewer.title',
        filename: 'filename'
    },
    canActivate: [UserRouteAccessService]
};
