import { EMPTY, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';

import * as React from 'react';
import { rce } from 'app/utils/react-utils';

import { HttpClient } from '@angular/common/http';
import { Event, NavigationEnd } from '@angular/router';
import { connect } from 'react-redux';
import { unsubscribe } from '../../../../utils/global.helper';
import { Provider as StyletronProvider } from 'styletron-react';
import { StyletronEngine } from 'app/shared/constants/react.constants';
import { BaseProvider, LightTheme } from 'baseui';
import { setShowErrorModal, setShowLoader, toggleSidebar } from './store/action/ui-action';
import { reset } from './store/action/action';
import { ChartDemoService } from './chart-demo.service';
import { ChartDemoUrlHandler } from './chart-demo-url.handler';
import { BoardConfig } from '../react-chart-board/board-config';
import { MenuConfiguration } from '../react-sidebar/class/menu-configuration';
import { ChartBoardDemoReactComponent } from './chart-board-demo.react.component';
import { STYLE_CONSTANTS } from '../common/react.constants';
import { setHttpClient, setRoles } from './store/action/config-action';
import { setChartResults } from './store/action/data-action';
import { ChartBoardDemoConfig } from './chart-board-demo-config';
import { loadTheme } from './chart-board-demo.helper';
import { mapResultToChartItems } from './chart-board-demo.utils';
import { ChartboardDemoResult } from './class/output/chartboard-demo-result';
import { ChartBoardOptions } from '../react-chart-board/chart-board-options';
import { ChartBoardInput } from './class/input/chart-board-input';

interface IBoardChartRootProps {
    routerEvents: Observable<Event>;
    httpClient: HttpClient;
    rootPath: string;
    roles: string[];
    urlHandler: ChartDemoUrlHandler;
    options: ChartBoardOptions;
    dispatch?: any;
    chartBoard?: any;
}

interface IBoardChartRootState {
    routerSub: Subscription;
    inactive: boolean;
    boardConfig: BoardConfig;
    menuConfig: MenuConfiguration;
}

export class ChartBoardRoot extends React.Component<IBoardChartRootProps, IBoardChartRootState> {

    service: ChartDemoService<ChartBoardInput, ChartboardDemoResult>;

    constructor(props) {
        super(props);
        this.state = {
            routerSub: null,
            inactive: false,
            boardConfig: null,
            menuConfig: null
        };
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onWSError = this.onWSError.bind(this);
        this.onCalculate = this.onCalculate.bind(this);
    }

    componentDidMount(): void {
        // Reset any previous props, if user navigated in and out from the demo
        const { httpClient, roles, urlHandler } = this.props;
        this.service = new ChartDemoService<ChartBoardInput, ChartboardDemoResult>(urlHandler);
        this.props.dispatch(setHttpClient(httpClient));
        this.props.dispatch(setRoles(roles));

        this.listenToAngularRouter();
        this.initConfig();
    }

    componentDidUpdate(prev: IBoardChartRootProps): void {
        if (!prev.httpClient && this.props.httpClient) {
            this.initConfig();
        }
    }

    private initConfig(): void {
        this.service.getBoardConfig(this.props.httpClient, this.onWSError).pipe(
            tap(loadTheme),
            tap((boardConfig: ChartBoardDemoConfig) => this.setState({ boardConfig: boardConfig.grid, menuConfig: boardConfig.menuConfiguration  })),
        ).subscribe();
    }

    componentWillUnmount(): void {
        unsubscribe(this.state.routerSub);
    }

    render() {
        const { sidebarOpen, showErrorModal, showLoader, chartResults, httpClient } = this.props.chartBoard;
        const { boardConfig, menuConfig } = this.state;

        // zIndex Must be the same as the root elements's
        // more info at https://baseweb.design/components/layer/#layers
        const zIndex = STYLE_CONSTANTS.Z_INDEX;

        if (this.state.inactive) {
            // If we left the demo, return null - will clean up child components
            return null;
        }

        return rce(StyletronProvider, { value: StyletronEngine } as any,
            rce(BaseProvider, {
                theme: LightTheme,
                zIndex,
                children: [rce(ChartBoardDemoReactComponent, {
                    key: 'board-demo-component',
                    sidebarOpen,
                    toggleSidebar: this.toggleSidebar,
                    onCalculate: this.onCalculate,
                    closeModal: this.closeModal,
                    showErrorModal,
                    showLoader,
                    boardConfig,
                    menuConfig,
                    chartResults,
                    httpClient
                })]
            }));
    }

    private listenToAngularRouter(): void {
        if (!this.state.routerSub || this.state.routerSub.closed) {
            const events: Observable<any> = this.props.routerEvents;
            const routerSub: Subscription = events.pipe(
                filter(e => e instanceof NavigationEnd),
                tap((e: NavigationEnd) => {
                    const inactive = !e || !e.urlAfterRedirects.includes(this.props.rootPath);
                    if (inactive && !this.state.inactive) {
                        this.props.dispatch((reset()));
                        unsubscribe(this.state.routerSub);
                    }
                    this.setState({ inactive });
                })
            ).subscribe();
            this.setState({ routerSub });
        }
    }

    private onWSError(err): Observable<void> {
        console.error(err);
        this.props.dispatch(setShowErrorModal(true));
        return EMPTY;
    }

    private onCalculate(model: { [key: string]: any }): void {
        if (!model) {
            throw Error('Appel au calcul impossible, le modèle n\'est pas défini');
        }
        const { httpClient } = this.props.chartBoard;
        const options = this.props.options || new ChartBoardOptions();
        const input: ChartBoardInput = { ...model, ...options };
        this.props.dispatch(setShowLoader(true));
        this.service.compareResults(input, httpClient, this.onWSError).pipe(
            map(mapResultToChartItems),
            tap((result: any) => this.props.dispatch(setChartResults(result))),
            finalize(() => this.props.dispatch(setShowLoader(false)))
        ).subscribe();
    }

    private toggleSidebar(): void {
        this.props.dispatch(toggleSidebar());
    }

    private closeModal(): void {
        this.props.dispatch(setShowErrorModal(false));
    }
}

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({ dispatch });

export const ChartBoardRootComponent = connect(
    mapStateToProps,
    dispatchToProps
)(ChartBoardRoot);
