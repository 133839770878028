import { DemoPresentationContent } from 'app/demos/presentation/class/demo-presentation-content';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';
import { ExampleDemoGroupType } from 'app/demos/presentation/example-presentation/example-demo-group-type';
import { DemoName } from '../../demo-name';

// Demonstration groups
export const ExampleGroups: ExampleDemoGroupType[] = [ExampleDemoGroupType.FIRSTGROUP, ExampleDemoGroupType.SECONDGROUP];

// Demos for each presentation group - all demo information must be provided below
export const ExampleDemoGroups: Map<ExampleDemoGroupType, DemoName[]> = new Map<ExampleDemoGroupType, DemoName[]>()
    .set(ExampleDemoGroupType.FIRSTGROUP, [DemoName.FUELPREDICTION])
    .set(ExampleDemoGroupType.SECONDGROUP, [DemoName.WEATHER_1, DemoName.PDM_1]);

// Content for the header presentation, at the tof of the page
// Presentation images are in content/images/demos/presentation/
export const ExamplePresentationTitle = new DemoPresentationContent(
    'presentation.example.intro.first.title',
    'presentation.example.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const ExamplePresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.example.intro.second.title',
        'presentation.example.intro.second.description',
        'top-img1',
        new DemoPathAccess('documentation/sample_documentation.html', [], true, true)
    )
];

// Group level content (presentation of the demonstration group)
export const ExampleDemoGroupContents: Map<ExampleDemoGroupType, DemoPresentationContent> = new Map<ExampleDemoGroupType, DemoPresentationContent>()
    .set(ExampleDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.example.firstGroup.intro.title',
        'presentation.example.firstGroup.intro.description',
        'firstGroup-desc',
    ))
    .set(ExampleDemoGroupType.SECONDGROUP, new DemoPresentationContent(
        'presentation.example.secondGroup.intro.title',
        'presentation.example.secondGroup.intro.description',
        'secondGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const ExamplePresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.FUELPREDICTION, new DemoPresentationContent(
        'presentation.example.firstGroup.demo.demo1.title',
        'presentation.example.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ))
    .set(DemoName.WEATHER_1, new DemoPresentationContent(
        'presentation.example.secondGroup.demo.demo1.title',
        'presentation.example.secondGroup.demo.demo1.description',
        'secondGroup-demo1',
    ))
    .set(DemoName.PDM_1, new DemoPresentationContent(
        'presentation.example.secondGroup.demo.demo2.title',
        'presentation.example.secondGroup.demo.demo2.description',
        'secondGroup-demo2',
        new DemoPathAccess('documentation/sample_documentation.html', [], true, true)
    ));

// Access information for each demonstration
export const ExamplePresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    // .set(DemoName.FUELPREDICTION, new DemoPathAccess('demo/fuelprediction-1', ROLES.FUELPREDICTION_ROLES))
    .set(DemoName.FUELPREDICTION, new DemoPathAccess('demo/fuelprediction-1', ['DUMMY']))
    .set(DemoName.WEATHER_1, new DemoPathAccess('demo/weather-1', ['DUMMY']))
    .set(DemoName.PDM_1, new DemoPathAccess('demo/pdm-1', ['DUMMY']));
