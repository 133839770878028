import { NgModule } from '@angular/core';
import { GatewayAngularSharedModule } from '../../shared/shared.module';
import { PdfviewerComponent } from './pdfviewer.component';
import { RouterModule } from '@angular/router';
import { pdfviewerRoute } from 'app/docs/pdfviewer/pdfviewer.route';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
    imports: [
        GatewayAngularSharedModule,
        NgxExtendedPdfViewerModule,
        PdfViewerModule,
        RouterModule.forChild([pdfviewerRoute]),
    ],
    declarations: [PdfviewerComponent],
    providers: [],
    bootstrap: [PdfviewerComponent],
    exports: [PdfviewerComponent]
})
export class PdfviewerModule {
}
