import { styled } from 'baseui';

export const InputContainer = styled('div', (({ $theme }) => ({
    padding: '10px 0'
})));

export const TcoBoardItemContainer = styled('div', (({ $theme }) => ({
    minHeight: '380px',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: 'rgb(246, 246, 246)'
})));
