import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'dc-loading-modal',
    styleUrls: ['./modals.scss'],
    template: `
        <!-- Modal header -->
        <div class="modal-header info-modal-header">
            <h5 class="modal-title pull-left" jhiTranslate="modal.loader.calculate.title"></h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
            <span class="flex-align-center" jhiTranslate="modal.loader.calculate.body"></span>
            <mat-spinner></mat-spinner>
        </div>
    `
})
export class LoadingModalComponent {

    constructor(public readonly bsModalRef: BsModalRef) {
    }
}
