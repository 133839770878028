import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { GeocodingSuggestion } from 'app/global/class/geocoding/geocoding-suggestion';
import { GeocodingBaseService } from 'app/global/component/react/react-search-address/geocoding-base.service';

@Injectable()
export class AddressService {
    constructor(private readonly httpClient: HttpClient) {
    }
    getSuggestionList(search: string): Observable<GeocodingSuggestion[]> {
        return GeocodingBaseService.autocomplete(search, this.httpClient);
    }
}
