import { DEMO_ROLES } from 'app/demos/role.constants';
import { Route } from '@angular/router';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import {GalleryPdfViewerComponent} from './gallery-pdf-viewer.component';

export const GalleryPdfViewerRoute: Route = {
    path: 'pdf-view',
    component: GalleryPdfViewerComponent,
    data: {
        pageTitle: 'pdfviewer.title',
        filename: 'filename'
    },
};
