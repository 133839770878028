import { DemoAccess } from './demo-access';
import { DemoName } from './demo-name';
import { DemoInfo } from 'app/demos/demo-info';
import { DEMO_GROUP_ROLES, DEMO_ROLES } from 'app/demos/role.constants';
import { DemoGroupInfo } from 'app/demos/demo-group-info';
import { TruckDemoGroups } from 'app/demos/presentation/truck-presentation/truck-presentation.constant';
import { BikeDemoGroups } from 'app/demos/presentation/bike-presentation/bike-presentation.constant';
import { LightVehicleDemoGroups } from 'app/demos/presentation/lightVehicle-presentation/lightVehicle-presentation.constant';
import { AirQualityDemoGroups } from 'app/demos/presentation/airQuality-presentation/airQuality-presentation.constant';
import { DigitalizationDemoGroups } from 'app/demos/presentation/digitalization-presentation/digitalization-presentation.constant';
import { ElectromobilityDemoGroups } from 'app/demos/presentation/electromobility-presentation/electromobility-presentation.constant';
import { ExampleDemoGroups } from 'app/demos/presentation/example-presentation/example-presentation.constant';
import { EnvironmentalFootprintDemoGroups } from 'app/demos/presentation/environmentalFootprint-presentation/environmentalFootprint-presentation.constant';
import { PollutingEmissionsDemoGroups } from 'app/demos/presentation/pollutingEmissions-presentation/pollutingEmissions-presentation.constant';
import { ProspectiveStudiesDemoGroups } from 'app/demos/presentation/prospectiveStudies-presentation/prospectiveStudies-presentation.constant';
import { GalleryInfos } from 'app/galleries/galleries.constants';
import { getDemoNamesFromGroup } from 'app/demos/demos.helper';

/**
 * Dictionnaire des démos, définissant leurs droits d'accès, ainsi que d'autres d'infos comme le numéro de version
 *
 * La clé est le nom de la démo, la valeur est un DemoInfo:
 * {
 *      name: DemoName,  // Nom identifiant la démo
 *      roles: string[], // Rôles nécessaire à l'accès ([] par défaut)
 *      version: string  // Version de la démo (optionnel)
 * }
 * ou un DemoGroupInfo, qui étend DemoInfo:
 * {
 *      name: DemoName,
 *      roles: string[],
 *      version: string,
 *      children: DemoName[], // Liste des démos contenues dans ce groupe
 *      ignoreChildren: boolean (défaut: false) // Si vrai, le groupe sera actif même si tous ses enfants ne le sont pas
 * }
 *
 * Les droits d'accès sont consultés notamment:
 *      - par la directive *dcDemoAccess, si ajoutée à un élément (ex vignette) dans le template:
 *      la directive attend le DemoInfo associé à la démo ({ name: DemoName, roles: string[] }) en input (renvoyé ici par la méthode getDemoAccess)
 *
 *      <div class="col-6 col-lg-4 d-flex p-2" *dcDemoAccess="getDemoAccess(demoNames.MOBILITY_1)">
 *          <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/mobilityobservatory-1">
 *              <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/mobilityobservatory.png"
 *                  alt="Digitalisation">
 *              <h4 class="card-title" jhiTranslate=demo.mobilityobservatory.title></h4>
 *          </a>
 *      </div>
 *
 *      Cette vignette ne sera affichée que si l'utilisateur à les droits définis ci-bas, et si la démo n'est pas désactivée.
 *
 *      - au niveau des routes, via le décorateur @RouteAccess({ demoName: DemoName }),
 *      qui bloquera la navigation si les droits sont insuffisants ou la démo est désactivée
 *
 *
 *          @RouteAccess({ demoName: DemoName.BIKE })
 *          export class BikeDemoRoute implements Route {
 *              static path = '';
 *              static component = BikeDemoComponent;
 *              static data = { pageTitle: 'demoBike.title' };
 *          }
 *
 *          obs: pour utiliser le décorateur, la route doit être définie sous la forme d'une classe et non d'une constante
 *
 *      - Dans une présentation, la liste des démo sera filtrée en fonction des droits et du statut activé / désactivé de la démo
 *
 *
 *      Pour ajouter une démo à la liste, il faut définir au préalable son nom dans l'enum DemoName, ses droits, ses enfants si c'est un groupe
 *      Son accès pourra ensuite être géré via les mécanismes décrits précédemment
 */
export const DemoInfos: Map<DemoName, DemoInfo> = new Map<DemoName, DemoAccess>()
    // Home
    .set(DemoName.MAIN, new DemoGroupInfo(DemoName.MAIN))
    // Groupes de démos
    .set(DemoName.PRESENTATION_AIRQUALITY, new DemoGroupInfo(DemoName.PRESENTATION_AIRQUALITY, DEMO_GROUP_ROLES.PRESENTATION_AIRQUALITY, getDemoNamesFromGroup(AirQualityDemoGroups)))
    .set(DemoName.PRESENTATION_BIKE, new DemoGroupInfo(DemoName.PRESENTATION_BIKE, DEMO_GROUP_ROLES.PRESENTATION_BIKE, getDemoNamesFromGroup(BikeDemoGroups)))
    .set(DemoName.PRESENTATION_DIGITALIZATION, new DemoGroupInfo(DemoName.PRESENTATION_DIGITALIZATION, DEMO_GROUP_ROLES.PRESENTATION_DIGITALIZATION, getDemoNamesFromGroup(DigitalizationDemoGroups)))
    .set(DemoName.PRESENTATION_ELECTROMOBILITY, new DemoGroupInfo(DemoName.PRESENTATION_ELECTROMOBILITY, DEMO_GROUP_ROLES.PRESENTATION_ELECTROMOBILITY, getDemoNamesFromGroup(ElectromobilityDemoGroups)))
    .set(DemoName.PRESENTATION_ENVIRONMENTALFOOTPRINT, new DemoGroupInfo(DemoName.PRESENTATION_ENVIRONMENTALFOOTPRINT, DEMO_GROUP_ROLES.PRESENTATION_ENVIRONMENTALFOOTPRINT, getDemoNamesFromGroup(EnvironmentalFootprintDemoGroups)))
    .set(DemoName.PRESENTATION_EXAMPLE, new DemoGroupInfo(DemoName.PRESENTATION_EXAMPLE, DEMO_GROUP_ROLES.PRESENTATION_EXAMPLE, getDemoNamesFromGroup(ExampleDemoGroups)))
    .set(DemoName.PRESENTATION_LIGHTVEHICLE, new DemoGroupInfo(DemoName.PRESENTATION_LIGHTVEHICLE, DEMO_GROUP_ROLES.PRESENTATION_LIGHTVEHICLE, getDemoNamesFromGroup(LightVehicleDemoGroups)))
    .set(DemoName.PRESENTATION_POLLUTINGEMISSIONS, new DemoGroupInfo(DemoName.PRESENTATION_POLLUTINGEMISSIONS, DEMO_GROUP_ROLES.PRESENTATION_POLLUTINGEMISSIONS, getDemoNamesFromGroup(PollutingEmissionsDemoGroups)))
    .set(DemoName.PRESENTATION_PROSPECTIVESTUDIES, new DemoGroupInfo(DemoName.PRESENTATION_PROSPECTIVESTUDIES, DEMO_GROUP_ROLES.PRESENTATION_PROSPECTIVESTUDIES, getDemoNamesFromGroup(ProspectiveStudiesDemoGroups)))
    .set(DemoName.PRESENTATION_TRUCK, new DemoGroupInfo(DemoName.PRESENTATION_TRUCK, DEMO_GROUP_ROLES.PRESENTATION_TRUCK, getDemoNamesFromGroup(TruckDemoGroups)))
    // Galleries
    .set(DemoName.GALLERY_DOCUMENTATION, GalleryInfos.get(DemoName.GALLERY_DOCUMENTATION))
    .set(DemoName.GALLERY_ENERGY_EFFICIENCY, GalleryInfos.get(DemoName.GALLERY_ENERGY_EFFICIENCY))
    .set(DemoName.GALLERY_TOOLKIT, GalleryInfos.get(DemoName.GALLERY_TOOLKIT))
    // Démos
    .set(DemoName.AFTERSIM, new DemoInfo(DemoName.AFTERSIM, DEMO_ROLES.AFTERSIM))
    .set(DemoName.BIKE, new DemoInfo(DemoName.BIKE, DEMO_ROLES.BIKE_ROLES))
    .set(DemoName.BIKEDATA_1, new DemoInfo(DemoName.BIKEDATA_1, DEMO_ROLES.MOBILITYDOUCE_ROLES))
    .set(DemoName.RTAMS, new DemoInfo(DemoName.RTAMS, DEMO_ROLES.RTAMS_ROLES))
    .set(DemoName.CARTOREACT, new DemoInfo(DemoName.CARTOREACT, DEMO_ROLES.CARTOREACT_ROLES))
    .set(DemoName.CEVOLVER_1, new DemoInfo(DemoName.CEVOLVER_1, DEMO_ROLES.CEVOLVER_ROLES))
    .set(DemoName.COMMUNITYMAP, new DemoInfo(DemoName.COMMUNITYMAP, DEMO_ROLES.COMMUNITYMAP_ROLES))
    .set(DemoName.CONCAWE_1, new DemoInfo(DemoName.CONCAWE_1, DEMO_ROLES.CONCAWE_ROLES))
    .set(DemoName.CONCAWE_1, new DemoInfo(DemoName.CONCAWE_2, DEMO_ROLES.CONCAWE_ROLES))
    .set(DemoName.DASHBOARDCMACGMACV, new DemoInfo(DemoName.DASHBOARDCMACGMACV, DEMO_ROLES.DASHBOARDCMACGMACV_ROLES))
    .set(DemoName.DASHBOARDCMACGMECO, new DemoInfo(DemoName.DASHBOARDCMACGMECO, DEMO_ROLES.DASHBOARDCMACGMECO_ROLES))
    .set(DemoName.DASHBOARDDJANGO, new DemoInfo(DemoName.DASHBOARDDJANGO, DEMO_ROLES.XDASHTCO_ROLES))
    .set(DemoName.DASHBOARDE4T, new DemoInfo(DemoName.DASHBOARDE4T, DEMO_ROLES.DASHBOARDE4T_ROLES))
    .set(DemoName.DASHBOARDE4TADEME, new DemoInfo(DemoName.DASHBOARDE4TADEME, DEMO_ROLES.DASHBOARDE4TADEME_ROLES))
    .set(DemoName.DASHBOARDE85SNPAA, new DemoInfo(DemoName.DASHBOARDE85SNPAA, DEMO_ROLES.DASHBOARDE85SNPAA_ROLES))
    .set(DemoName.DASHBOARDH24EU, new DemoInfo(DemoName.DASHBOARDH24EU, DEMO_ROLES.DASHBOARDH24EU_ROLES))
    .set(DemoName.DASHBOARDMOUVEMENT, new DemoInfo(DemoName.DASHBOARDMOUVEMENT, DEMO_ROLES.DASHBOARDMOUVEMENT_ROLES))
    .set(DemoName.DASHBOARDPORPOISE, new DemoInfo(DemoName.DASHBOARDPORPOISE, DEMO_ROLES.DASHBOARDPORPOISE_ROLES))
    .set(DemoName.DJANGOTEMPLATE, new DemoInfo(DemoName.DJANGOTEMPLATE, DEMO_ROLES.DJANGOTEMPLATE_ROLES))
    .set(DemoName.ECOROUTING_1, new DemoInfo(DemoName.ECOROUTING_1, DEMO_ROLES.OPTEMUS_ROLES))
    .set(DemoName.EMPOWER_1, new DemoInfo(DemoName.EMPOWER_1, DEMO_ROLES.EMPOWERECOROUTING_ROLES))
    .set(DemoName.EMISSIONFACTORS, new DemoInfo(DemoName.EMISSIONFACTORS, DEMO_ROLES.EMISSIONFACTORS_ROLES))
    .set(DemoName.FUELPREDICTION, new DemoInfo(DemoName.FUELPREDICTION, DEMO_ROLES.FUELPREDICTION_ROLES))
    .set(DemoName.GECOAIRCARTOGRAPHY, new DemoInfo(DemoName.GECOAIRCARTOGRAPHY, DEMO_ROLES.GECOAIRCARTOGRAPHY_ROLES))
    .set(DemoName.GECOAIRCITYSTATISTICS, new DemoInfo(DemoName.GECOAIRCITYSTATISTICS, DEMO_ROLES.GECOAIRCITYSTATISTICS_ROLES))
    .set(DemoName.GECOAIRCOZYCLOUD, new DemoInfo(DemoName.GECOAIRCOZYCLOUD, DEMO_ROLES.GECOAIRCOZYCLOUD_ROLES))
    .set(DemoName.GECOAIRMONITORING, new DemoInfo(DemoName.GECOAIRMONITORING, DEMO_ROLES.GECOAIR_MONITORING_ROLES))
    .set(DemoName.GECOAIRSDKANDROID_1, new DemoInfo(DemoName.GECOAIRSDKANDROID_1, DEMO_ROLES.GECOAIRSDK_ROLES))
    .set(DemoName.GECOAIRSDKIOS_1, new DemoInfo(DemoName.GECOAIRSDKIOS_1, DEMO_ROLES.GECOAIRSDK_ROLES))
    .set(DemoName.GECOAIRUSERTRIPS, new DemoInfo(DemoName.GECOAIRUSERTRIPS, DEMO_ROLES.GECOAIRUSERTRIPS_ROLES))
    .set(DemoName.GECOAIRUSERTRIPS_2, new DemoInfo(DemoName.GECOAIRUSERTRIPS_2, DEMO_ROLES.GECOAIRUSERTRIPS_ROLES))
    .set(DemoName.JCMF, new DemoInfo(DemoName.JCMF, DEMO_ROLES.DASHBOARDDJANGO_ROLES))
    .set(DemoName.JCMVLINK, new DemoInfo(DemoName.JCMVLINK))
    .set(DemoName.LONGRUN_1, new DemoInfo(DemoName.LONGRUN_1, DEMO_ROLES.LONGRUNECOROUTING_ROLES))
    .set(DemoName.MOBICLOUDDATA, new DemoInfo(DemoName.MOBICLOUDDATA, DEMO_ROLES.MOBICLOUDDATA_ROLES))
    .set(DemoName.MOBICLOUDDATASPARC, new DemoInfo(DemoName.MOBICLOUDDATASPARC, DEMO_ROLES.MOBICLOUDDATASPARC_ROLES))
    .set(DemoName.MOBILITY_1, new DemoInfo(DemoName.MOBILITY_1, DEMO_ROLES.MOBILITYOBSERVATORY_ROLES))
    .set(DemoName.MTESHOMEPAGE_1, new DemoInfo(DemoName.MTESHOMEPAGE_1, DEMO_ROLES.MTES_ROLES))
    .set(DemoName.MTES_1, new DemoInfo(DemoName.MTES_1, DEMO_ROLES.MTES_ROLES))
    .set(DemoName.PDM_1, new DemoInfo(DemoName.PDM_1, DEMO_ROLES.PDM_ROLES))
    .set(DemoName.REALEHOMEPAGE_1, new DemoInfo(DemoName.REALEHOMEPAGE_1, DEMO_ROLES.USER_ROLES))
    .set(DemoName.REALE_1, new DemoInfo(DemoName.REALE_1, DEMO_ROLES.REALE_ROLES))
    .set(DemoName.REALTIMEEMISSIONS_1, new DemoInfo(DemoName.REALTIMEEMISSIONS_1, DEMO_ROLES.GECOAIRREALTIMEEMISSIONS_ROLES))
    .set(DemoName.SIMULATIONTOOLS_1, new DemoInfo(DemoName.SIMULATIONTOOLS_1, DEMO_ROLES.SIMULATIONTOOLS_ROLES))
    .set(DemoName.SIMULATIONTOOLS_2, new DemoInfo(DemoName.SIMULATIONTOOLS_2, DEMO_ROLES.SIMULATIONTOOLS_ROLES))
    .set(DemoName.SIMULATIONZFE_1, new DemoInfo(DemoName.SIMULATIONZFE_1, DEMO_ROLES.XDASHSIMULATIONZFE_ROLES))
    .set(DemoName.TCO2_1, new DemoInfo(DemoName.TCO2_1, DEMO_ROLES.TCO2_ROLES))
    .set(DemoName.TCO2_2, new DemoInfo(DemoName.TCO2_2, DEMO_ROLES.TCO2DATA_ROLES))
    .set(DemoName.TDBCARBONLOCAL, new DemoInfo(DemoName.TDBCARBONLOCAL, DEMO_ROLES.TDBCARBONLOCAL_ROLES))
    .set(DemoName.TRUCK_ROUTING, new DemoInfo(DemoName.TRUCK_ROUTING, DEMO_ROLES.TRUCKROUTING_ROLES))
    .set(DemoName.WEATHER_1, new DemoInfo(DemoName.WEATHER_1, DEMO_ROLES.WEATHER_ROLES))
    .set(DemoName.XDASHACVSCREENING, new DemoInfo(DemoName.XDASHACVSCREENING, DEMO_ROLES.XDASHACVSCREENING_ROLES))
    .set(DemoName.CP4SC_1, new DemoInfo(DemoName.CP4SC_1, DEMO_ROLES.CP4SC_ROLES))
    .set(DemoName.XDASHGEOCODING_1, new DemoInfo(DemoName.XDASHGEOCODING_1, DEMO_ROLES.XDASHGEOCODING_ROLES))
    .set(DemoName.XDASHGOOGLETIMELINES_1, new DemoInfo(DemoName.XDASHGOOGLETIMELINES_1, DEMO_ROLES.XDASHGOOGLETIMELINES_ROLES))
    .set(DemoName.XDASHPDM_2, new DemoInfo(DemoName.XDASHPDM_2, DEMO_ROLES.PDM_ROLES))
    .set(DemoName.XDASHREALE_1, new DemoInfo(DemoName.XDASHREALE_1, DEMO_ROLES.XDASHREALE_1))
    .set(DemoName.XDASHSOFLEET_1, new DemoInfo(DemoName.XDASHSOFLEET_1, DEMO_ROLES.XDASHSOFLEET_ROLES))
    .set(DemoName.XDASHSPARC_1, new DemoInfo(DemoName.XDASHSPARC_1, DEMO_ROLES.XDASHSPARC_ROLES))
    .set(DemoName.XDASHTCO_1, new DemoInfo(DemoName.XDASHTCO_1, DEMO_ROLES.XDASHTCO_ROLES))
    .set(DemoName.VERDIR_MA_FLOTTE, new DemoInfo(DemoName.VERDIR_MA_FLOTTE))
    .set(DemoName.SPEED_PROFILE, new DemoInfo(DemoName.SPEED_PROFILE))
    .set(DemoName.BIKE_PREDICTION, new DemoInfo(DemoName.BIKE_PREDICTION))
    .set(DemoName.VEHICLEFLEET, new DemoInfo(DemoName.VEHICLEFLEET, DEMO_ROLES.VEHICLEFLEET_ROLES));
