import { chain, isNil } from 'lodash';
import { Injectable } from '@angular/core';
import { ControlOptions, ControlPosition, geoJSON, tileLayer } from 'leaflet';
import { GlobalLayersType } from '../leaflet-map-component/vector-layers-type';
import { PbfHelperService } from '../../../demos/community-map-demo/side-menu.component/pbf-layers/pbf-helper';
import { L, vectorGrid } from '../../../vendor';
import { LeafletMapSharedService } from '../leaflet-map.shared.service';
import { LAYERS_TYPES, LayersDetails } from '../../../demos/community-map-demo/side-menu.component/pbf-layers/pbf-layers-details';
import { LeafletMapConfigService } from '../leaflet-map-component/leaflet-map-config.service';
import { PbfCounters } from '../../../demos/community-map-demo/side-menu.component/pbf-layers/pbf-counters';
import { PbfSharedService } from '../../../demos/community-map-demo/side-menu.component/pbf-layers/pbf-shared.service';
import { GeoJsonObject } from 'geojson';

@Injectable()
export class LeafletCommunityMapService  {

    static readonly MAPS_BASE_PATH = 'maps/'; // racine des fichiers pbf

    static readonly BASE_MAP_URL = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png';
    static readonly CARTODB_LIGHT_URL = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png';
    static readonly STAMEN_TONER_URL = 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png';
    static readonly CARTODB_DARK_URL = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png';

    private vectorLayers;
    private currentLayer;
    private altStyle = false;
    private PBF_LAYERS_DETAILS = new LayersDetails();
    geoJson;
    overlaysGroup = L.layerGroup();
    allOverlays: any;

    constructor(private readonly leafletMapSharedService: LeafletMapSharedService,
                private readonly pbfHelperService: PbfHelperService,
                readonly pbfSharedService: PbfSharedService) {

    }

    getMapConfig(layer): any {
        return {
            layers: [
                layer
            ],
            zoom: LeafletMapConfigService.DEFAULT_FR_ZOOM,
            center: LeafletMapConfigService.COORD_FR_CENTER
        };
    }

    getLayerConfig(url: string): any {
        return tileLayer(
            url, { attribution: '&copy; <a href="https://carto.com/attributions">CARTO</a>' },
        );
    }
    getOverlayGroup(): void {
        this.allOverlays = geoJSON(this.geoJson);
        this.allOverlays.addTo(this.overlaysGroup);
    }

    setGeoJson(geoJson: GeoJsonObject): void {
        this.geoJson = geoJson;
    }

    getLayersControlsOptions(pos: ControlPosition): ControlOptions {
        return { position: pos };
    }

    getLayersControls() {
        return {
            baseLayers: {
                'openstreetmap': tileLayer(LeafletCommunityMapService.BASE_MAP_URL, {
                    attribution: '&copy; <a href="https://openstreetmap.org">CARTO</a>',
                    zIndex: 0
                }),
                'cartodbpositron': tileLayer(LeafletCommunityMapService.CARTODB_LIGHT_URL, {
                    attribution: '&copy; <a href="https://carto.com/attributions">OpenStreetMap</a>',
                    zIndex: 0
                }),
                'stamentoner': tileLayer(LeafletCommunityMapService.STAMEN_TONER_URL, {
                    attribution: '&copy; <a href="http://stamen.com">Stamen Design</a>',
                    zIndex: 0

                }),
                'cartodbdark_matter': tileLayer(LeafletCommunityMapService.CARTODB_DARK_URL, {
                    attribution: '&copy; <a href="https://carto.com/attributions">CARTO</a>',
                    zIndex: 0
                })
            },
            overlays: {
                GeoJSON: this.overlaysGroup
            }
        };
    }

    /**
     * Update PBFLayer when layer item or subitem selection changed
     *
     * @param loaderName the layer loader to use
     */
    changePBFLayer(loaderName: string): void {
        if (isNil(loaderName) || isNil(this.vectorLayers[loaderName])) {
            return;
        }
        this.currentLayer = this.vectorLayers[loaderName];
        this.leafletMapSharedService.nextLayerControl(this.currentLayer);
    }

    initVectorLayersAndControls() {
        this.vectorLayers = this.getVectorsLayers();
    }

    changePBFColors(loaderName, valueMin, valueMax, propertyName, gradientRange?, securityId?): any {
        const counter = new PbfCounters();
        if (isNil(valueMin) && isNil(valueMax)) {
            this.altStyle = !this.altStyle;
        }
        if (this.isLoaderPresent(loaderName, this.PBF_LAYERS_DETAILS.getPbfLayersDetailsMap().get(LAYERS_TYPES.BIKE))) {
            this.pbfHelperService.changeBikeLayer(propertyName, this.currentLayer, this.altStyle, valueMin, valueMax, counter);
        } else if (this.isLoaderPresent(loaderName, this.PBF_LAYERS_DETAILS.getPbfLayersDetailsMap().get(LAYERS_TYPES.SECURITY))) {
            this.pbfHelperService.changeSafetyLayer(this.currentLayer, this.altStyle, securityId, valueMin, valueMax, counter);
        } else if (this.isLoaderPresent(loaderName, this.PBF_LAYERS_DETAILS.getPbfLayersDetailsMap().get(LAYERS_TYPES.POLLUTANTS))) {
            this.pbfHelperService.changePollutionLayer(propertyName, this.currentLayer, this.altStyle, valueMin, valueMax, gradientRange, counter);
        } else if (this.isLoaderPresent(loaderName, this.PBF_LAYERS_DETAILS.getPbfLayersDetailsMap().get(LAYERS_TYPES.HERE))) {
            this.pbfHelperService.changeHereLayer(propertyName, this.currentLayer, this.altStyle, valueMin, valueMax, gradientRange, counter);
        }
        this.currentLayer.redraw();
        setTimeout(() => this.pbfSharedService.setCounters(counter), 1200);
        return this.currentLayer;
    }

    getVectorsLayers(minValue?: number, maxValue?: number): GlobalLayersType {

        return new GlobalLayersType(
            this.buildProtocolBufferLayer(this.pbfHelperService.getSafetyPbfStyle(0, minValue, maxValue, null), 'security'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getSafetyPbfStyle(2, minValue, maxValue, null), 'security'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getSafetyPbfStyle(1, minValue, maxValue, null), 'security'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getSafetyPbfStyle(3, minValue, maxValue, null), 'security'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('Nox', minValue, maxValue), 'pollutants'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('QFuel', minValue, maxValue), 'pollutants'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('TPCO', minValue, maxValue), 'pollutants'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('TPSoot', minValue, maxValue), 'pollutants'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('NbTrip', minValue, maxValue), 'pollutants'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getBikePbfStyle('roughness', minValue, maxValue), 'bike'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getBikePbfStyle('roughnessRank', minValue, maxValue), 'bike'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('averagespeed', minValue, maxValue), 'here'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('freeflowspeed', minValue, maxValue), 'here'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('speedLimit', minValue, maxValue), 'here'),
            this.buildProtocolBufferLayer(this.pbfHelperService.getPollutantPbfStyle('FC', minValue, maxValue), 'here')
        );
    }

    buildProtocolBufferLayer(style, tileKey): any {
        tileKey = LeafletCommunityMapService.MAPS_BASE_PATH + tileKey + '/{z}/{x}/{y}.pbf';
        const options = {
            rendererFactory: L.canvas.tile,
            zIndex: 5,
            vectorTileLayerStyles: style
        };
        return vectorGrid.protobuf(tileKey, options);
    }

    isLoaderPresent(loaderName, loaderList): any {
        return chain(loaderList)
            .map(item => item.loader)
            .some(item => item === loaderName)
            .value();
    }
}
