import { ChartTheme } from './charts/chart-theme';

export class BoardConfig {

    constructor(public rowsNumber = 0, public columnsNumber = 0, public theme?: ChartTheme) {
    }

    getNumberOfCells(): number {
        return this.rowsNumber * this.columnsNumber;
    }
}
