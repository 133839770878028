import * as React from 'react';
import styled from 'styled-components';
import { rce } from 'app/utils/react-utils';
import { Button, SIZE } from 'baseui/button';
import { SidebarSettingsComponent } from './sidebar-settings.react.component';
import { MenuConfiguration } from './class/menu-configuration';
import { HttpClient } from '@angular/common/http';
import { ISidebarUIState } from './sidebar-ui-state.interface';

export const SIDEBAR_WIDTH_TRANSITION_TIME = 300;
const SIDEBAR_WIDTH = '330px';

const BdSidebarContentMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 0;
    opacity: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 20px, rgba(0, 0, 0, 0.1) 0px 2px 5px;
    transition: width ${SIDEBAR_WIDTH_TRANSITION_TIME}ms, opacity 500ms ease;
`;
const BdSidebarItemsContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
`;
const WsButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
`;
const ParamTitleContainer = styled.div`
    line-height: 45px;
    font-size: 18px;
    padding-left: 15px;
`;

interface IBdSidebarContentProps {
    sidebarOpen: boolean;
    config: MenuConfiguration;
    getValueFromPath: (path: string) => any;
    updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void;
    onCalculate: (model?: { [key: string]: any }) => void;
    httpClient: HttpClient;
    uiState: ISidebarUIState;
}

interface IBdSidebarContentState {
    style: React.CSSProperties;
}

export class SidebarContentComponent extends React.Component<IBdSidebarContentProps, IBdSidebarContentState> {

    constructor(props) {
        super(props);
        this.state = {
            style: {
                width: SIDEBAR_WIDTH,
                opacity: 1
            }
        };
        this.onClick = this.onClick.bind(this);
        this.onAnimationStart = this.onAnimationStart.bind(this);
        this.onAnimationEnd = this.onAnimationEnd.bind(this);
    }

    // componentDidUpdate(prev: IBdSidebarContentProps): void {
    //     if (this.props.sidebarOpen !== )
    // }
    // startStopAnimation() {
    //     const { sidebarOpen, getValueFromPath, updateModel, config } = this.props;
    //
    //     const open = this.props.sidebarOpen
    // }

    onAnimationStart() {
    }

    onAnimationEnd() {
    }

    render() {
        const { sidebarOpen, getValueFromPath, uiState, updateModel, config, httpClient } = this.props;
        const open = config && sidebarOpen;

        return rce(BdSidebarContentMainContainer, {
            key: 'BdSidebarContentMainContainer',
            onAnimationStart: this.onAnimationStart,
            onAnimationEnd: this.onAnimationEnd,
            style: { width: open ? SIDEBAR_WIDTH : 0, opacity: open ? 1 : 0 }
        }, open && [
            rce(ParamTitleContainer, { key: 'params-title' }, config.title),
            rce(BdSidebarItemsContainer, { key: 'items-container ' } as any,
                rce(SidebarSettingsComponent, {
                    key: 'settings',
                    getValueFromPath,
                    updateModel,
                    uiState,
                    groups: config.groups,
                    httpClient
                } as any)
            ),
            rce(WsButtonContainer, { key: 'button' }, rce(Button,
                {
                    size: SIZE.large,
                    onClick: this.onClick
                }, config.button.label
                )
            )
        ]);
    }

    private onClick(): void {
        this.props.onCalculate();
    }
}
