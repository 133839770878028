/**
 * Enumération des noms de démos et groupes de démos (pages de présentation, galleries...)
 * Sert d'identifiant pour chaque démo
 *
 * Chaque identifiant peut être ciblé par le service /api/disabledDemoPages, ce qui désactivera la démo / groupe associé
 *
 */
export enum DemoName {
    // Home
    MAIN = 'home',
    // Demos presentations
    PRESENTATION_AIRQUALITY = 'presentation/airQuality',
    PRESENTATION_BIKE = 'presentation/bike',
    PRESENTATION_DIGITALIZATION = 'presentation/digitalization',
    PRESENTATION_ELECTROMOBILITY = 'presentation/electromobility',
    PRESENTATION_ENVIRONMENTALFOOTPRINT = 'presentation/environmentalFootprint',
    PRESENTATION_EXAMPLE = 'presentation/example',
    PRESENTATION_LIGHTVEHICLE = 'presentation/lightVehicle',
    PRESENTATION_POLLUTINGEMISSIONS = 'presentation/pollutingEmissions',
    PRESENTATION_PROSPECTIVESTUDIES = 'presentation/prospectiveStudies',
    PRESENTATION_TRUCK = 'presentation/truck',
    // Galleries
    GALLERY_DOCUMENTATION = 'documentation',
    GALLERY_ENERGY_EFFICIENCY = 'energyEfficiency',
    GALLERY_TOOLKIT = 'toolkit',
    // Demo names
    AFTERSIM = 'aftersim',
    BIKE = 'bike-1',
    BIKEDATA_1 = 'bikedata-1',
    BIKE_PREDICTION = 'bike-prediction',
    RTAMS = 'rtams-1',
    CARTOREACT = 'carto-react-1',
    CEVOLVER_1 = 'cevolver-1',
    COMMUNITYMAP = 'communitymap-1',
    CONCAWE_1 = 'concawe-1',
    CONCAWE_2 = 'concawe-2',
    CP4SC_1 = 'cp4sc-1',
    DASHBOARDCMACGMACV = 'dashboard-cmacgm-acv-1',
    DASHBOARDCMACGMECO = 'dashboard-cmacgm-eco-1',
    DASHBOARDDJANGO = 'dashboard-django-1',
    DASHBOARDE4T = 'dashboard-e4t-ifpen-1',
    DASHBOARDE4TADEME = 'dashboard-e4t-1',
    DASHBOARDE85SNPAA = 'dashboard-e85-snpaa-1',
    DASHBOARDH24EU = 'dashboard-h24eu-1',
    DASHBOARDMOUVEMENT = 'dashboard-mouvement-1',
    DASHBOARDPORPOISE = 'dashboard-porpoise-1',
    DJANGOTEMPLATE = 'djangotemplate-1',
    ECOROUTING_1 = 'eco-routing-1',
    EMISSIONFACTORS = 'emissionfactors-1',
    EMPOWER_1 = 'empower-1',
    FUELPREDICTION = 'fuelprediction-1',
    GECOAIRCARTOGRAPHY = 'gecoaircartography-1',
    GECOAIRCITYSTATISTICS = 'gecoaircitystatistics-1',
    GECOAIRCOZYCLOUD = 'gecoaircozycloud-1',
    GECOAIRMONITORING = 'gecoairmonitoring-1',
    GECOAIRSDKANDROID_1 = 'gecoairsdkandroid-1',
    GECOAIRSDKIOS_1 = 'gecoairsdkios-1',
    GECOAIRUSERTRIPS = 'gecoairusertrips-1',
    GECOAIRUSERTRIPS_2 = 'gecoairusertrips-2',
    JCMF = 'dashboard-django-1/html/JCMVpro_demo_v1',
    JCMVLINK = 'dummy',
    LONGRUN_1 = 'longrun-1',
    MOBICLOUDDATA = 'mobiclouddata-1',
    MOBICLOUDDATASPARC = 'mobiclouddatasparc-1',
    MOBILITY_1 = 'mobilityobservatory-1',
    MTESHOMEPAGE_1 = 'mtes-homepage-1',
    MTES_1 = 'mtes-1',
    PDM_1 = 'pdm-1',
    REALEHOMEPAGE_1 = 'reale-homepage-1',
    REALE_1 = 'reale-1',
    REALTIMEEMISSIONS_1 = 'realtimeemissions-1',
    SIMULATIONTOOLS_1 = 'simulationtools-1',
    SIMULATIONTOOLS_2 = 'simulationtools-2',
    SIMULATIONZFE_1 = 'simulationzfe-1',
    SPEED_PROFILE = 'speed-profile',
    TCO2_1 = 'tco2-1',
    TCO2_2 = 'tco2-2',
    VERDIR_MA_FLOTTE = 'verdir-ma-flotte',
    TDBCARBONLOCAL = 'tdbcarbonlocal-1',
    TRUCK_ROUTING = 'truckrouting-1',
    VEHICLEFLEET = 'vehiclefleet-1',
    WEATHER_1 = 'weather-1',
    XDASHACVSCREENING = 'acvscreening-1',
    XDASHGEOCODING_1 = 'geocoding-1',
    XDASHGOOGLETIMELINES_1 = 'googletimelines-1',
    XDASHPDM_2 = 'pdm-2',
    XDASHREALE_1 = 'reale-2',
    XDASHSOFLEET_1 = 'sofleet-1',
    XDASHSPARC_1 = 'sparc-1',
    XDASHTCO_1 = 'tco-1',

}
