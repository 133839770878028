import * as React from 'react';
import { rce } from 'app/utils/react-utils';

import styled from 'styled-components';
import { Button, SHAPE, SIZE } from 'baseui/button';
import { ChevronLeft, ChevronRight } from 'baseui/icon';

const BdSidebarToggleMainContainer = styled.div`
`;

interface IBdSidebarToggleProps {
    sidebarOpen: boolean;
    toggleSidebar: Function;
}

interface IBdSidebarToggleState {
}

export class SidebarToggleComponent extends React.Component<IBdSidebarToggleProps, IBdSidebarToggleState> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
    }

    render() {
        const { sidebarOpen, toggleSidebar } = this.props;

        const icon = sidebarOpen ? ChevronLeft : ChevronRight;
        const style = {
            width: '30px',
            height: '30px',
            paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0,
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 8px 20px, rgba(0, 0, 0, 0.1) 0px 2px 5px'
        };

        return rce(BdSidebarToggleMainContainer, {
                key: 'BdSidebarToggleMainContainer'
            },
            rce(Button, {
                    shape: SHAPE.square,
                    size: SIZE.compact,
                    onClick: toggleSidebar,
                    overrides: { BaseButton: { style } }
                } as any,
                rce(icon))
        );
    }
}
