import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {FileNameType} from 'app/galleries/documentation/FileNameType';

@Injectable()
export class GalleryService {


    constructor(private readonly httpClient: HttpClient) {
    }

    getPdfFilesByRoles(roles: string[]): Observable<FileNameType[]> {
        return this.httpClient.post<FileNameType[]>('api/getPdfFilesList/', roles, {withCredentials: true});
    }

    fetchPdfFile(filename: string): Observable<any> {
        return this.httpClient.get('api/fetchPdfFile/' + filename + '.pdf', {responseType: 'blob',
            withCredentials: true});
    }

    fetchHtmlFile(filename: string): Observable<any> {
        return this.httpClient.get('api/fetchHtmlFile/' + filename + '.html', {responseType: 'blob',
            withCredentials: true});
    }

}
