import { DemoPresentationContent } from 'app/demos/presentation/class/demo-presentation-content';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { LightVehicleDemoGroupType } from 'app/demos/presentation/lightVehicle-presentation/lightVehicle-demo-group-type';
import { DemoName } from '../../demo-name';

// Demonstration groups
export const LightVehicleGroups: LightVehicleDemoGroupType[] = [LightVehicleDemoGroupType.FIRSTGROUP, LightVehicleDemoGroupType.SECONDGROUP];

// Demos for each presentation group - all demo information must be provided below
export const LightVehicleDemoGroups: Map<LightVehicleDemoGroupType, DemoName[]> = new Map<LightVehicleDemoGroupType, DemoName[]>()
    .set(LightVehicleDemoGroupType.FIRSTGROUP, [DemoName.SPEED_PROFILE, DemoName.SIMULATIONTOOLS_1, DemoName.SIMULATIONTOOLS_2])
    .set(LightVehicleDemoGroupType.SECONDGROUP, [DemoName.FUELPREDICTION]);

// Content for the header presentation, at the tof of the page
// Presentation images are in content/images/demos/presentation/
export const LightVehiclePresentationTitle = new DemoPresentationContent(
    'presentation.lightVehicle.intro.first.title',
    'presentation.lightVehicle.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const LightVehiclePresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.lightVehicle.intro.second.title',
        'presentation.lightVehicle.intro.second.description',
        'top-img1',
    )
];

// Group level content (presentation of the demonstration group)
export const LightVehicleDemoGroupContents: Map<LightVehicleDemoGroupType, DemoPresentationContent> = new Map<LightVehicleDemoGroupType, DemoPresentationContent>()
    .set(LightVehicleDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.lightVehicle.firstGroup.intro.title',
        'presentation.lightVehicle.firstGroup.intro.description',
        'firstGroup-desc',
    ))
    .set(LightVehicleDemoGroupType.SECONDGROUP, new DemoPresentationContent(
        'presentation.lightVehicle.secondGroup.intro.title',
        'presentation.lightVehicle.secondGroup.intro.description',
        'secondGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const LightVehiclePresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.SPEED_PROFILE, new DemoPresentationContent(
        'presentation.lightVehicle.firstGroup.demo.speedProfile.title',
        'presentation.lightVehicle.firstGroup.demo.speedProfile.description',
        'speed-profile',
    ))
    .set(DemoName.SIMULATIONTOOLS_1, new DemoPresentationContent(
        'presentation.lightVehicle.firstGroup.demo.demo1.title',
        'presentation.lightVehicle.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ))
    .set(DemoName.SIMULATIONTOOLS_2, new DemoPresentationContent(
        'presentation.lightVehicle.firstGroup.demo.demo2.title',
        'presentation.lightVehicle.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ))
    .set(DemoName.FUELPREDICTION, new DemoPresentationContent(
        'presentation.lightVehicle.secondGroup.demo.demo1.title',
        'presentation.lightVehicle.secondGroup.demo.demo1.description',
        'secondGroup-demo1',
    ));

// Access information for each demonstration
export const LightVehiclePresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.SPEED_PROFILE, new DemoPathAccess('demo/speed-profile', DEMO_ROLES.USER_ROLES))
    .set(DemoName.SIMULATIONTOOLS_1, new DemoPathAccess('demo/chartboard/simulationtools-1', DEMO_ROLES.SIMULATIONTOOLS_ROLES))
    .set(DemoName.SIMULATIONTOOLS_2, new DemoPathAccess('demo/simulationtools-2', DEMO_ROLES.SIMULATIONTOOLS_ROLES))
    .set(DemoName.FUELPREDICTION, new DemoPathAccess('demo/fuelprediction-1', DEMO_ROLES.FUELPREDICTION_ROLES));
