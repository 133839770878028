import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { GatewayAngularSharedModule } from 'app/shared/shared.module';
import { htmlViewerRoute } from 'app/docs/html-viewer/html-viewer.route';
import { HtmlViewerComponent } from 'app/docs/html-viewer/html-viewer.component';

@NgModule({
    imports: [
        GatewayAngularSharedModule,
        RouterModule.forChild([htmlViewerRoute])
    ],
    exports: [HtmlViewerComponent],
    declarations: [HtmlViewerComponent],
    entryComponents: [HtmlViewerComponent],
    providers: [],
})
export class HtmlViewerModule {
}
