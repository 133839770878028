import { random, isNil } from 'lodash';
import * as d3 from 'd3';

export function extractRGBValues(color: string): string {
    if (!color) {
        return;
    }
    // Look for opacity attribute in color
    const opacityIndexOf = getPosition(color, ',', 3);
    // The position of last color digit to keep : either before 3rd comma if opacity value is present, or before closing parenthesis
    const indexOfEnd = opacityIndexOf > -1 ? opacityIndexOf : color.indexOf(')');

    // Extract rgb values
    return color.substring(color.indexOf('(') + 1, indexOfEnd);
}

export function mapArrayToRGB(values: [number, number, number] | string): string {
    if (typeof values === 'string') {
        return values;
    }
    if (!values || values.length !== 3) {
        return '';
    }
    return values.reduce((memo: string, v: number, i: number) => {
        memo += (i === (values.length - 1)) ? `${v})` : `${v}, `;

        return memo;
    }, 'rgb(');
}

export function wrapInRGB(baseRGBValue: string): string {
    return `rgb(${baseRGBValue})`;
}

/**
 * Generates a new (non white) RGB color
 * @return {string} a string defining the random color in format 'R, G, B'
 */
export function getRandomColor(topMax = 250): string {
    return `${random(topMax)}, ${random(topMax)}, ${random(topMax)}`;
}

/**
 * Return the position of the Nth occurrence of a substring
 * If there is less than N occurrences of the substring, will return -1
 *
 * @param string
 * @param subString
 * @param index
 */
export function getPosition(string: string, subString: string, index: number): number {
    if (!string || !subString) {
        return;
    }
    const position = string.split(subString, index).join(subString).length;
    return position >= string.length ? -1 : position;
}

export function getPBFColorFromGradient(indicePolluant: number, domainInterval: number[], colorRange: string[]): string {
    let color;
    const colorScale = d3.scale.linear().domain(domainInterval)
        .range(colorRange);
    color = colorScale(indicePolluant);
    return color;
}

export function intermediateGradientValue(minValue: number, maxValue: number): number[] {
    const intermediateValue = (maxValue - minValue) / 3;
    return [minValue, minValue + intermediateValue, maxValue - intermediateValue, maxValue];
}

export function getColorFromScale(value: number, minValue: number, maxValue: number, colorRange?: number[][]): number[] | string {
    if (isNil(value) || value < minValue || value > maxValue) {
        return 'transparent';
    }
    const colors: number[][] = colorRange || [
        [255, 255, 204],
        [254, 217, 118],
        [253, 141, 60],
        [128, 0, 38]
    ];
    const colorScale = d3.scale.linear()
        .domain(intermediateGradientValue(minValue, maxValue))
        .range(colors);
    return colorScale(value);
}

export function getWidthFromScale(value: number, minValue: number, maxValue: number, range: [number, number]) {
    const valueIsValid = !isNil(value) && value >= minValue && value <= maxValue;
    return valueIsValid ? d3.scale.linear()
        .domain([minValue, maxValue])
        .range(range)(Number(value))
        : 0;
}
