import { Routes } from '@angular/router';
import { EnergyEfficiencyRoute } from 'app/galleries/energyEfficiency/gallery.route';
import { ToolkitRoute } from 'app/galleries/toolkit/gallery.route';
import { DocumentationRoute } from 'app/galleries/documentation/gallery.route';

const GALLERIES_ROUTES = [
    EnergyEfficiencyRoute,
    ToolkitRoute,
    DocumentationRoute
];

export const galleriesState: Routes = [
    {
        path: '',
        children: GALLERIES_ROUTES
    }
];
