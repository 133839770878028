import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'dc-base-modal',
    styleUrls: ['./modals.scss'],
    template: `
        <!-- Modal header -->
        <div class="modal-header base-modal-header" *ngIf="translationKey">
            <div class="modal-header-title" id="modal-title" [jhiTranslate]="getTranslation('title')"></div>
        </div>
        <!-- Modal body -->
        <div *ngIf="translationKey" class="modal-body modal-header-body">
            <div [jhiTranslate]="getTranslation('body1')"></div>
            <div [jhiTranslate]="getTranslation('body2')"></div>
        </div>
        <div class="modal-footer warning-modal-footer">
            <button class="btn btn-blue btn-base-modal" type="button" (click)="bsModalRef.hide()">OK</button>
        </div>
    `
})
export class BaseModalComponent {

    @Input() translationKey: string;

    constructor(public readonly bsModalRef: BsModalRef) {
    }

    getTranslation(sectionKey: string): string {
        return `modal.${ this.translationKey }.${ sectionKey }`;
    }
}
