import { isNil } from 'lodash';
import { isArrayEmpty } from './global.helper';

export function findClosestIndex(num: number, array: number[], tolerance?: number): number {
    if (isArrayEmpty(array) || isNil(num)) {
        return;
    }
    if (isNil(tolerance)) {
        // If no tolerance has been defined, use 1%
        tolerance = 1 / 100 * (Math.abs(array[array.length - 1]) - Math.abs(array[0]));
    }
    // Check if value is in last interval, if so return last index
    if (Math.abs(num - array[array.length - 1]) < tolerance) {
        return array.length - 1;
    }
    // Not in the last interval, so start iterating to find closest interval within that tolerance range
    let currentDiff;
    let closestIndex = 0; // Index to increment
    do {
        currentDiff = Math.abs(num - array[closestIndex]);
        closestIndex++;
    } while (currentDiff > tolerance && closestIndex < array.length - 1);
    if (closestIndex === array.length - 1 && currentDiff > tolerance) {
        return null;
    }
    return closestIndex;
}

export const getArrayBounds = <T>(src: T[]): [T, T] | [] => {
    if (isArrayEmpty(src) || src.length < 2) {
        return [];
    }
    const length = src.length;

    return [src[0], src[length - 1]];
};

export const getLastItemInArray = <T>(arr: T[]): T => isArrayEmpty(arr) ? null : arr[arr.length - 1];

export const moveItemToFirstPosition = <T>(arr: T[], item: T): T[] => {
    if (isArrayEmpty(arr) || !arr.includes(item) || arr.length === 1) {
        return arr;
    }
    arr.splice(arr.indexOf(item), 1);
    arr.unshift(item);
    return arr;
};
