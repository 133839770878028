import { DemoGroupInfo } from '../demos/demo-group-info';
import { DemoName } from '../demos/demo-name';
import { ROLES as GALLERY_ROLES } from './role.constants';

const GALLERY_ENERGY_EFFICIENCY_CHILDREN: DemoName[] = [DemoName.ECOROUTING_1, DemoName.CEVOLVER_1];

export const GalleryInfos: Map<DemoName, DemoGroupInfo> = new Map<DemoName, DemoGroupInfo>()
    .set(DemoName.GALLERY_ENERGY_EFFICIENCY, new DemoGroupInfo(DemoName.GALLERY_ENERGY_EFFICIENCY, GALLERY_ROLES.ENERGY_EFFICIENCY_ROLES, GALLERY_ENERGY_EFFICIENCY_CHILDREN))
    .set(DemoName.GALLERY_TOOLKIT, new DemoGroupInfo(DemoName.GALLERY_TOOLKIT, GALLERY_ROLES.TOOLKIT_ROLES))
    .set(DemoName.GALLERY_DOCUMENTATION, new DemoGroupInfo(DemoName.GALLERY_DOCUMENTATION, GALLERY_ROLES.DOCUMENTATION_ROLES));

