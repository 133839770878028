<div class="footer fixed-bottom">
    <div class="row bg">
        <div class="col img-container">
            <a href="https://fr.linkedin.com/company/ifp-energies-nouvelles"><img class="img-responsive" src="/content/images/contact/linkedin.png"></a>
            <a href="https://www.youtube.com/channel/UCLC0KYGsOya0E_7pwvBraVw"><img class="img-responsive" src="/content/images/contact/youtube.png"></a>
            <a href="https://twitter.com/IFPENinnovation"><img class="img-responsive" src="/content/images/contact/twitter.png"></a>
            <a href="mailto:contact@mobicloud.ifpen.com"><img class="img-responsive" src="/content/images/contact/mail.png"></a>
        </div>
        <div class="col-auto d-flex align-items-end justify-content-end">
            <a href="https://www.ifpenergiesnouvelles.fr/">© 2020 IFPEN</a>
        </div>
    </div>
</div>
