import * as React from 'react';

import { rce } from '../../../../utils/react-utils';
import styled from 'styled-components';
import { SidebarContentComponent } from './sidebar-content.react.component';
import { SidebarToggleComponent } from './sidebar-toggle.react.component';
import { MenuConfiguration } from './class/menu-configuration';
import { findValue, generateModel, updateModelValue } from './sidebar.helper';
import { STYLE_CONSTANTS } from '../common/react.constants';
import { MenuSettings } from './class/menu-settings';
import { HttpClient } from '@angular/common/http';
import { ISidebarUIState } from './sidebar-ui-state.interface';

const SidebarMainContainer = styled.div`
    display: flex;
    z-index: ${ STYLE_CONSTANTS.Z_INDEX } // This has to be set along with base ui provider's to guarantee BaseUI layer management's stacking order
`;

interface ISidebarProps {
    config: MenuConfiguration;
    settings: MenuSettings; // Options sidebar / modèle
    sidebarOpen?: boolean;
    toggleSidebar: () => void;
    updateModel?: (model: { [key: string]: any }) => void;
    onCalculate: (model: { [key: string]: any }) => void;
    httpClient: HttpClient;
}

interface ISidebarState {
    model: { [key: string]: any };
    uiState: ISidebarUIState;
}

export class SidebarComponent extends React.Component<ISidebarProps, ISidebarState> {

    constructor(props) {
        super(props);
        this.state = {
            model: null,
            uiState: {selectedAddress: {}}
        };
        this.getValueFromPath = this.getValueFromPath.bind(this);
        this.updateModel = this.updateModel.bind(this);
        this.onCalculate = this.onCalculate.bind(this);
    }

    componentDidMount(): void {
        this.setState({ model: generateModel(this.props.config) });
    }

    componentDidUpdate(prev: ISidebarProps): void {
        if (prev.config !== this.props.config) {
            const model = generateModel(this.props.config, this.props.settings?.targetPathSeparator);
            this.setState({ model });
            // Initial board loading after config updated
            this.onCalculate(model);
        }
    }

    render() {
        const { sidebarOpen, toggleSidebar, config, httpClient } = this.props;

        return rce(SidebarMainContainer, {
                key: 'MobilitySidebarMainContainer'
            }, [
                this.state.model && rce(SidebarContentComponent, {
                    key: 'content',
                    sidebarOpen,
                    config,
                    uiState: this.state.uiState,
                    getValueFromPath: this.getValueFromPath,
                    updateModel: this.updateModel,
                    onCalculate: this.onCalculate,
                    httpClient
                } as any),
                rce(SidebarToggleComponent, { key: 'toggle', toggleSidebar, sidebarOpen })
            ]
        );
    }

    /**
     * Retourne la valeur dans le modèle pour un chemin d'input donné
     *
     * @param targetPath le chemin ciblant la valeur d'un input donné dans le modèle
     */
    getValueFromPath(targetPath: string): any {
        const { model } = this.state;
        return findValue(model, targetPath, this.props.settings?.targetPathSeparator);
    }

    updateModel(value: any, targetPath: string, uiState?: ISidebarUIState): void {
        const { model } = this.state;
        const separator = this.props.settings?.targetPathSeparator;
        this.setState({ model: updateModelValue(model, value, targetPath, separator) });
        if (this.props.updateModel) {
            this.props.updateModel(updateModelValue(model, value, targetPath, separator));
        }
        if (uiState) {
            this.setState({ ...this.state, uiState: { ...this.state.uiState, ...uiState }});
        }
    }

    onCalculate(initialModel?: { [key: string]: any }): void {
        const model = initialModel || this.state.model;
        this.props.onCalculate(model);
    }
}
