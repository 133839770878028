import { DemoPresentationContent } from 'app/demos/presentation/class/demo-presentation-content';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { DigitalizationDemoGroupType } from 'app/demos/presentation/digitalization-presentation/digitalization-demo-group-type';
import { PresentationContentOptions } from 'app/demos/presentation/class/presentation-content-options';
import { DemoName } from '../../demo-name';

// Demonstration groups
export const DigitalizationGroups: DigitalizationDemoGroupType[] = [DigitalizationDemoGroupType.FIRSTGROUP, DigitalizationDemoGroupType.SECONDGROUP, DigitalizationDemoGroupType.THIRDGROUP];

// Demos for each presentation group - all demo information must be provided below
export const DigitalizationDemoGroups: Map<DigitalizationDemoGroupType, DemoName[]> = new Map<DigitalizationDemoGroupType, DemoName[]>()
    .set(DigitalizationDemoGroupType.FIRSTGROUP, [DemoName.FUELPREDICTION, DemoName.XDASHTCO_1, DemoName.JCMVLINK])
    .set(DigitalizationDemoGroupType.SECONDGROUP, [DemoName.JCMF])
    .set(DigitalizationDemoGroupType.THIRDGROUP, [DemoName.DASHBOARDE4T, DemoName.DASHBOARDE4TADEME, DemoName.TDBCARBONLOCAL]);

// Content for the header presentation, at the tof of the page
// Presentation images are in content/images/demos/presentation/
export const DigitalizationPresentationTitle = new DemoPresentationContent(
    'presentation.digitalization.intro.first.title',
    'presentation.digitalization.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const DigitalizationPresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.digitalization.intro.second.title',
        'presentation.digitalization.intro.second.description',
        'top-img1',
    )
];

// Group level content (presentation of the demonstration group)
export const DigitalizationDemoGroupContents: Map<DigitalizationDemoGroupType, DemoPresentationContent> = new Map<DigitalizationDemoGroupType, DemoPresentationContent>()
    .set(DigitalizationDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.digitalization.firstGroup.intro.title',
        'presentation.digitalization.firstGroup.intro.description',
        'firstGroup-desc',
    ))
    .set(DigitalizationDemoGroupType.SECONDGROUP, new DemoPresentationContent(
        'presentation.digitalization.secondGroup.intro.title',
        'presentation.digitalization.secondGroup.intro.description',
        'secondGroup-desc',
    ))
    .set(DigitalizationDemoGroupType.THIRDGROUP, new DemoPresentationContent(
        'presentation.digitalization.thirdGroup.intro.title',
        'presentation.digitalization.thirdGroup.intro.description',
        'thirdGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const DigitalizationPresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.FUELPREDICTION, new DemoPresentationContent(
        'presentation.digitalization.firstGroup.demo.demo1.title',
        'presentation.digitalization.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
        null,
        new PresentationContentOptions(true),
    ))
    .set(DemoName.XDASHTCO_1, new DemoPresentationContent(
        'presentation.digitalization.firstGroup.demo.demo2.title',
        'presentation.digitalization.firstGroup.demo.demo2.description',
        'firstGroup-demo2',
        null,
        new PresentationContentOptions(true),
    ))
    .set(DemoName.JCMVLINK, new DemoPresentationContent(
        'presentation.digitalization.firstGroup.demo.demo3.title',
        'presentation.digitalization.firstGroup.demo.demo3.description',
        'firstGroup-demo3',
        null,
        new PresentationContentOptions(true),
    ))
    .set(DemoName.JCMF, new DemoPresentationContent(
        'presentation.digitalization.secondGroup.demo.demo1.title',
        'presentation.digitalization.secondGroup.demo.demo1.description',
        'secondGroup-demo1',
        null,
        new PresentationContentOptions(true),
    ))
    .set(DemoName.DASHBOARDE4T, new DemoPresentationContent(
        'presentation.digitalization.thirdGroup.demo.demo1.title',
        'presentation.digitalization.thirdGroup.demo.demo1.description',
        'thirdGroup-demo1',
    ))
    .set(DemoName.DASHBOARDE4TADEME, new DemoPresentationContent(
        'presentation.digitalization.thirdGroup.demo.demo2.title',
        'presentation.digitalization.thirdGroup.demo.demo2.description',
        'thirdGroup-demo2',
    ))
    .set(DemoName.TDBCARBONLOCAL, new DemoPresentationContent(
        'presentation.digitalization.thirdGroup.demo.demo3.title',
        'presentation.digitalization.thirdGroup.demo.demo3.description',
        'thirdGroup-demo3',
    ));

// Access information for each demonstration
export const DigitalizationPresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.FUELPREDICTION, new DemoPathAccess('demo/fuelprediction-1', DEMO_ROLES.FUELPREDICTION_ROLES))
    .set(DemoName.XDASHTCO_1, new DemoPathAccess('demo/xdash/tco-1', DEMO_ROLES.XDASHTCO_ROLES))
    .set(DemoName.JCMVLINK, new DemoPathAccess('https://jechangemavoiture.gouv.fr', DEMO_ROLES.USER_ROLES))
    .set(DemoName.JCMF, new DemoPathAccess('demo/dashboard-django-1/html/JCMVpro_demo_v1', DEMO_ROLES.DASHBOARDDJANGO_ROLES))
    .set(DemoName.DASHBOARDE4T, new DemoPathAccess('demo/dashboard-e4t-ifpen-1', DEMO_ROLES.DASHBOARDE4T_ROLES))
    .set(DemoName.DASHBOARDE4TADEME, new DemoPathAccess('demo/dashboard-e4t-1', DEMO_ROLES.DASHBOARDE4TADEME_ROLES, false))
    .set(DemoName.TDBCARBONLOCAL, new DemoPathAccess('demo/xdash/tdbcarbonlocal-1', DEMO_ROLES.TDBCARBONLOCAL_ROLES));
