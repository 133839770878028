import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'dc-simple-error-modal',
    styleUrls: ['./modals.scss'],
    template: `
        <!-- Modal header -->
        <div class="modal-header alert-modal-header">
            <h5 class="modal-title pull-left" [jhiTranslate]="getTranslationKey('title')"></h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
            <span class="flex-align-start" [jhiTranslate]="getTranslationKey('body1')"></span>
            <span class="flex-align-start" [jhiTranslate]="getTranslationKey('body2')"></span>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-basic" (click)="hideModal()" [jhiTranslate]="'modal.buttons.close'">
            </button>
        </div>
    `
})
export class SimpleErrorModalComponent {

    translationKey = 'default';
    onClose: Function;

    constructor(public readonly bsModalRef: BsModalRef) {
    }

    getTranslationKey(key: string): string {
        return `modal.error.${this.translationKey}.${key}`;
    }

    hideModal(): void {
        this.bsModalRef.hide();
        if (this.onClose && typeof this.onClose === 'function') {
            this.onClose();
        }
    }
}
