import { DemoPresentationContent } from 'app/demos/presentation/class/demo-presentation-content';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { BikeDemoGroupType } from 'app/demos/presentation/bike-presentation/bike-demo-group-type';
import { DemoName } from '../../demo-name';

// Demonstration groups
export const BikeGroups: BikeDemoGroupType[] = [BikeDemoGroupType.FIRSTGROUP, BikeDemoGroupType.SECONDGROUP];

// Demos for each presentation group - all demo information must be provided below
export const BikeDemoGroups: Map<BikeDemoGroupType, DemoName[]> = new Map<BikeDemoGroupType, DemoName[]>()
    .set(BikeDemoGroupType.FIRSTGROUP, [DemoName.BIKE, DemoName.BIKE_PREDICTION, DemoName.WEATHER_1])
    .set(BikeDemoGroupType.SECONDGROUP, [DemoName.BIKEDATA_1, DemoName.COMMUNITYMAP]);

// Content for the header presentation, at the tof of the page
export const BikePresentationTitle = new DemoPresentationContent(
    'presentation.bike.intro.first.title',
    'presentation.bike.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const BikePresentationDescription = [new DemoPresentationContent(
    'presentation.bike.intro.second.title',
    'presentation.bike.intro.second.description',
    'top-img1',
)];

// Group level content (presentation of the demonstration group)
// Presentation images are in content/images/demos/presentation/
export const BikeDemoGroupContents: Map<BikeDemoGroupType, DemoPresentationContent> = new Map<BikeDemoGroupType, DemoPresentationContent>()
    .set(BikeDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.bike.firstGroup.intro.title',
        'presentation.bike.firstGroup.intro.description',
        'firstGroup-desc',
    ))
    .set(BikeDemoGroupType.SECONDGROUP, new DemoPresentationContent(
        'presentation.bike.secondGroup.intro.title',
        'presentation.bike.secondGroup.intro.description',
        'secondGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const BikePresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.BIKE, new DemoPresentationContent(
        'presentation.bike.firstGroup.demo.demo1.title',
        'presentation.bike.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ))
    .set(DemoName.BIKE_PREDICTION, new DemoPresentationContent(
        'presentation.bike.firstGroup.demo.bikePrediction.title',
        'presentation.bike.firstGroup.demo.bikePrediction.description',
        'bike-prediction',
    ))
    .set(DemoName.WEATHER_1, new DemoPresentationContent(
        'presentation.bike.firstGroup.demo.demo2.title',
        'presentation.bike.firstGroup.demo.demo2.description',
        'firstGroup-demo2',
    ))
    .set(DemoName.BIKEDATA_1, new DemoPresentationContent(
        'presentation.bike.secondGroup.demo.demo1.title',
        'presentation.bike.secondGroup.demo.demo1.description',
        'secondGroup-demo1',
    ))
    .set(DemoName.COMMUNITYMAP, new DemoPresentationContent(
        'presentation.bike.secondGroup.demo.demo2.title',
        'presentation.bike.secondGroup.demo.demo2.description',
        'secondGroup-demo2',
    ));

// Access information for each demonstration
export const BikePresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.BIKE, new DemoPathAccess('demo/bike-1', DEMO_ROLES.BIKE_ROLES))
    .set(DemoName.BIKE_PREDICTION, new DemoPathAccess('demo/bike-prediction', DEMO_ROLES.USER_ROLES))
    .set(DemoName.WEATHER_1, new DemoPathAccess('demo/weather-1', DEMO_ROLES.WEATHER_ROLES))
    .set(DemoName.BIKEDATA_1, new DemoPathAccess('demo/mapdata/bikedata-1', DEMO_ROLES.MOBILITYDOUCE_ROLES))
    .set(DemoName.COMMUNITYMAP, new DemoPathAccess('demo/communitymap-1', DEMO_ROLES.COMMUNITYMAP_ROLES));
