import { isArrayEmpty } from './global.helper';
import { PolylineGeometry } from '../demos/bike-demo/bike-route-results';
import { LatLngTuple } from 'leaflet';

/**
 * Extract the lines coordinates from raw result and convert them into an array of arrays
 * as expected by L.polyline constructor
 *
 * @param coords { latitude: [lat, lat2, ...], longitude: [lng, lng2, ...] }
 * @return {Array} [[lat, lng], [lat2, lng2], ...]
 */

export function mapGeometryToArray(coords: PolylineGeometry): LatLngTuple[] {
    if (!coords || isArrayEmpty(coords.latitude) || isArrayEmpty(coords.longitude)) {
        console.warn('Could not create L.polyline : empty values');
        return null;
    }
    if (coords.latitude.length !== coords.longitude.length) {
        console.warn('Could not create L.polyline : not as many latitude as longitude values');
        return null;
    }
    // Store latitudes and longitudes
    const latLngs: LatLngTuple[] = [];
    coords.latitude.forEach((lat: number, i: number) => latLngs.push([lat, coords.longitude[i]]));
    return latLngs;
}
