import { MenuGroup } from './menu-group';
import { MenuButton } from './menu-button';
import { IMenuConfiguration } from './menu-configuration.interface';

export class MenuConfiguration implements IMenuConfiguration {
    constructor(public title = '', // Titre de la barre de menu
                public groups: MenuGroup[] = [],
                public button: MenuButton = new MenuButton()) {
    }
}
