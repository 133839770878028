import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from 'app/core/login/login.service';
import {GeneralConditionService} from 'app/shared/general-condition/general-condition.service';
import {CguModel} from 'app/shared/general-condition/CguModel';
import {SafeHtmlPipe} from 'app/shared/general-condition/safe-html.pipe';
import {AccountService} from 'app/core/auth/account.service';
import {UserService} from 'app/core/user/user.service';
import {IUser} from 'app/core/user/user.model';
import {UpdateCguRequest} from 'app/core/user/UpdateCguRequest';

@Component({
  selector: 'dc-general-condition',
  templateUrl: './general-condition.component.html',
  styleUrls: ['./general-condition.component.scss'],
})
export class GeneralConditionComponent implements OnInit {
    checked: boolean = false;
    cgu: CguModel;
    @Input() fromParent: IUser;

  constructor(public activeModal: NgbActiveModal,
              private router: Router,
              private loginService: LoginService,
              private accountService: AccountService,
              private userService: UserService,
              private cguService: GeneralConditionService) { }

  ngOnInit(): void {
      this.fetchLatestCgu();
  }

  agree() {
      const updateRequest: UpdateCguRequest = {
          user_id: this.fromParent.id,
          currentCguVersion: this.fromParent.latestCguVersion.toString(),
          latestCguVersion: this.cgu.version.toString()
      };

      this.userService.updateCgu(updateRequest).subscribe(res => {
          if (res) {
              this.activeModal.dismiss('cancel');
          }
      });
  }

  fetchLatestCgu() {
    this.cguService.latestCguVersion().subscribe(res => {
        this.cgu = res;
    });
  }

  cancel() {
      this.loginService.logout();
      this.activeModal.dismiss('cancel');
  }

    setCheckState(event: any) {
    }
}
