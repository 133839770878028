const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const SET_SHOW_LOADER = 'SET_SHOW_LOADER';
const SET_SHOW_ERROR_MODAL = 'SET_SHOW_ERROR_MODAL';

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });

export const setShowLoader = (showLoader: boolean) => ({
    type: SET_SHOW_LOADER,
    showLoader
});

export const setShowErrorModal = (showErrorModal: boolean) => ({
    type: SET_SHOW_ERROR_MODAL,
    showErrorModal
});

export const UI_ACTIONS = {
    [TOGGLE_SIDEBAR]: state => ({
        ...state,
        sidebarOpen: !state.sidebarOpen
    }),
    [SET_SHOW_LOADER]: (state, action) => ({
        ...state,
        showLoader: action.showLoader
    }),
    [SET_SHOW_ERROR_MODAL]: (state, action) => ({
        ...state,
        showErrorModal: action.showErrorModal
    })
};
