import { NgModule } from '@angular/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { LoadingModalComponent } from './loading-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgJhipsterModule } from 'ng-jhipster';
import { SimpleErrorModalComponent } from './simple-error-modal.component';
import { CustomModalsService } from './custom-modals.service';
import { WarningModalComponent } from './warning-modal.component';
import { BaseModalComponent } from 'app/global/component/modals/base-modal.component';

@NgModule({
    imports: [
        NgJhipsterModule,
        ModalModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        LoadingModalComponent,
        SimpleErrorModalComponent,
        WarningModalComponent,
        BaseModalComponent
    ],
    providers: [
        BsModalService,
        CustomModalsService
    ],
    exports: [],
    entryComponents: [SimpleErrorModalComponent, WarningModalComponent, BaseModalComponent],
    bootstrap: [LoadingModalComponent]
})
export class ModalsModule {
}
