import { EMPTY, Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/internal/operators';

import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccountService } from 'app/core/auth/account.service';
import { DemoAccessSharedService } from 'app/global/service/demo-access-shared.service';
import { DemoAccess } from '../../demos/demo-access';
import { unsubscribe } from '../../utils/global.helper';
import { DemoInfo } from 'app/demos/demo-info';
import { DemoGroupInfo } from 'app/demos/demo-group-info';

/**
 * Directive pour gérer l'affichage d'un lien de démo en fonction des droits de l'utilisateur
 * et du status actif/inactif de la démo ciblée
 *
 */
@Directive({
    selector: '[dcDemoAccess]',
})
export class DemoAccessDirective implements OnDestroy {
    private subs: Subscription[] = [];

    // Les informations pour la démo associée à l'élément hôte de la directive, pour en gérer l'affichage
    private _demoInfos: DemoAccess | DemoInfo | DemoGroupInfo;

    get authorities(): string[] {
        return this._demoInfos && this._demoInfos.roles;
    }

    constructor(private readonly accountService: AccountService,
                private readonly templateRef: TemplateRef<any>,
                private readonly viewContainerRef: ViewContainerRef,
                private readonly demoAccessService: DemoAccessSharedService) {
    }

    @Input() set dcDemoAccess(value: DemoAccess | DemoInfo | DemoGroupInfo) {
        unsubscribe(this.subs);
        this.subs = [];
        this._demoInfos = value;
        this.updateView().subscribe();
        // Update the host component view each time authentication state or demos activation status changes
        this.subs.push(this.accountService.getAuthenticationState().pipe(switchMap(() => this.updateView()))
            .subscribe());
    }

    ngOnDestroy(): void {
        unsubscribe(this.subs);
    }

    /**
     * Affiche / masque l'élément hôte si la démo associée n'est pas accessible
     * Une démo n'est pas accessible si les rôles de l'utilisateurs sont insuffisants, ou si la démo a été désactivée
     *
     * @private
     */
    private updateView(): Observable<boolean> {
        if (!this.accountService.isAuthenticated()) {
            return EMPTY;
        }
        // Vérification des rôles pour la démo
        const hasAnyAuthority = this.accountService.hasAnyAuthority(this.authorities);
        // Async check
        return this.demoAccessService.checkDemoActive(this._demoInfos).pipe(
            tap((isDemoActive: boolean) => this.createViewIfAccessible(hasAnyAuthority, isDemoActive))
        );
    }

    private createViewIfAccessible(hasAnyAuthority: boolean, isDemoActive: boolean): void {
        // Retrait du composant du container
        this.viewContainerRef.clear();
        if (hasAnyAuthority && isDemoActive) {
            // Ajout seulement si accès validé
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
