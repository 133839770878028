export class DemoUrlHandler {

    constructor(private webServiceId: string,
                private baseUrl: string) {
    }

    getServiceId(): string {
        return this.webServiceId;
    }

    getBaseUrl(): string {
        return this.baseUrl;
    }
}
