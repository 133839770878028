import { Polyline, PolylineOptions, LatLngExpression } from 'leaflet';
import { ExtendedLayer, ExtendedLayerData } from './extended-layer';

export class ExtendedPolyline extends Polyline implements ExtendedLayer {
    data: PolylineData = null;

    constructor(latlngs: LatLngExpression[] | LatLngExpression[][], options?: PolylineOptions) {
        super(latlngs, options);
    }
}

export class PolylineData implements ExtendedLayerData {
    $selected = false;
    constructor(public routeColor: string) {}
}
