import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption, Pagination } from 'app/shared/util/request-util';
import { IUser } from './user.model';
import { map } from 'rxjs/internal/operators';
import {UpdateCguRequest} from 'app/core/user/UpdateCguRequest';
import {SearchRequest} from 'app/admin/user-management/models/searchRequest';

@Injectable({ providedIn: 'root' })
export class UserService {
    public resourceUrl = SERVER_API_URL + 'api/users';
    public basedUrl = SERVER_API_URL + 'api/';

    constructor(private http: HttpClient) {}

    create(user: IUser): Observable<HttpResponse<IUser>> {
        return this.http.post<IUser>(this.resourceUrl, user, { observe: 'response' });
    }

    update(user: IUser): Observable<HttpResponse<IUser>> {
        return this.http.put<IUser>(this.resourceUrl, user, { observe: 'response' });
    }

    updateCgu(user: UpdateCguRequest): Observable<HttpResponse<IUser>> {
        return this.http.put<IUser>(this.basedUrl + 'update-cgu', user, { observe: 'response' });
    }

    find(login: string): Observable<IUser> {
        return this.http.get<IUser>(`${this.resourceUrl}/${login}`, { observe: 'response' }).pipe(
            map((response: HttpResponse<IUser>) => response.body)
        );
    }

    query(req?: Pagination, searchText?: string, includeGecoair?: boolean): Observable<HttpResponse<IUser[]>> {
        if (searchText) {
            return this.search(searchText, includeGecoair);
        }
        let options = createRequestOption(req);
        if (includeGecoair) {
            options = options.set('includeGecoair', String(includeGecoair));
        }
        return this.http.get<IUser[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    search(searchText = '', includeGecoair?: boolean): Observable<HttpResponse<IUser[]>> {
        searchText = searchText.trim();
        if (!searchText) {
            return of(new HttpResponse({ body: [] }));
        }
        const url = this.resourceUrl + '/search';
        const params = new HttpParams().set('searchText', searchText).set('includeGecoair', String(includeGecoair));
        return this.http.get<IUser[]>(url, { params, observe: 'response' });
    }

    searchWithMultiFilters(searchReq: SearchRequest, req?: Pagination): Observable<any> {
        const url = this.resourceUrl + '/multi_filter';
        const options = createRequestOption(req);
        return this.http.post<IUser[]>(url, searchReq, { params: options, observe: 'response'});
    }

    delete(login: string): Observable<HttpResponse<any>> {
        return this.http.delete(`${this.resourceUrl}/${login}`, { observe: 'response' });
    }

    authorities(): Observable<string[]> {
        return this.http.get<string[]>(SERVER_API_URL + 'api/users/authorities');
    }
}
