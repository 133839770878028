
/* Modal */
export const ERROR_MODAL_TITLE = 'Erreur';
export const ERROR_MODAL_BODY_1 = 'Veuillez essayer dans quelques instants';

/* Configuration */

/* Configuration du modèle (input webservice) */
// Séparateur utilisé dans le chemin identifiant une valeur dans le modèle
export const MODEL_TARGET_PATH_SEPARATOR = '/';
