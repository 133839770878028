import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribe } from 'app/utils/global.helper';
import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core/user/account.model';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { DemoName } from 'app/demos/demo-name';
import { DemoAccess } from 'app/demos/demo-access';
import { getDemoInfosByName } from 'app/utils/demo-utils';

@Component({
    selector: 'dc-toolkit',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    demoNames = DemoName;
    roles = DEMO_ROLES;
    account: Account;
    subs: Subscription[] = [];

    constructor(private readonly accountService: AccountService) {
    }

    ngOnInit(): void {
        this.subs.push(this.accountService.identity().subscribe((account: Account) => {
        this.account = account;
        }));
    }

    ngOnDestroy(): void {
        unsubscribe(this.subs);
    }

    getDemoAccess(name: DemoName): DemoAccess {
        return getDemoInfosByName(name);
    }
}
