<div class="row gallery-container justify-content-around">
    <div class="gallery-inner-container" align="center">
        <div class="container-fluid" align="center">
            <div class="row m-3 justify-content-around">
                <div class="galleryColumn">
                    <a target="_blank" rel="noopener noreferrer" class="card galleryCard text-center rounded-lg p-5" href="https://xdash.io/">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/xdash_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.xdash"></h4>
                    </a>
                </div>
                <!--        TODO No demo listed in demo.constants.ts for these xdash app -->
                <div class="galleryColumn" *dcHasAnyAuthority="roles.XDASH_MOBICLOUD_ROLES">
                    <a target="_blank" rel="noopener noreferrer" class="card galleryCard text-center rounded-lg p-5" href="/content/xdash/dev/latest/index.html">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/xdash_mobicloud_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.xdashMobicloud"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcHasAnyAuthority="roles.GRAFANA_ROLES">
                    <a target="_blank" rel="noopener noreferrer" class="card galleryCard text-center rounded-lg p-5" href="https://monitormobicloud.ifpen.com:8443">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/grafana_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.grafana"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcHasAnyAuthority="roles.KIBANA_ROLES">
                    <a target="_blank" rel="noopener noreferrer" class="card galleryCard text-center rounded-lg p-5" href="https://logsmobicloud.com/app/kibana">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/kibana_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.kibana"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.XDASHGEOCODING_1)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/xdash/geocoding-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/xdash-geocoding/xdash-geocoding_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.xdashGeocoding"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.DJANGOTEMPLATE)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/djangotemplate-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/djangoTemplate_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.djangoTemplate"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.MOBICLOUDDATA)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/mobiclouddata-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/mobicloudData_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.mobicloudData"></h4>
                    </a>
                </div>
                <!--
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.XDASHGOOGLETIMELINES_1)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/xdash/googletimelines-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/xdash-googletimelines/xdash-googletimelines_round.png">
                        <h4 class="card-title" jhiTranslate=toolkit.xdashGoogletimelines></h4>
                    </a>
                </div>
                -->
                <div class="galleryColumn">
                    <a target="_blank" rel="noopener noreferrer" class="card galleryCard text-center rounded-lg p-5" href="https://mobilitywidgets.com">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/mobilitywidgets_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.mobilityWidgets"></h4>
                    </a>
                </div>
                <div class="galleryColumn">
                    <a target="_blank" rel="noopener noreferrer" class="card galleryCard text-center rounded-lg p-5" href="https://apps.mobicloud.fr/gmtl/">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/gmtl_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.gmtl"></h4>
                    </a>
                </div>
                <div class="galleryColumn">
                    <a target="_blank" rel="noopener noreferrer" class="card galleryCard text-center rounded-lg p-5" href="https://apps.mobicloud.fr/ev/">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/electromobility_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.electromobility"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.CP4SC_1)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/xdash/cp4sc-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/cp4sc_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.cp4sc"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.VEHICLEFLEET)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../demo/vehiclefleet-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/vehiclefleet_round.png">
                        <h4 class="card-title" jhiTranslate="toolkit.vehiclefleet"></h4>
                    </a>
                </div>
            </div>
            <div class="btn btn-dark" jhiTranslate="entity.action.back" routerLink="/"></div>
            <div class="row m-3 justify-content-around"></div>
        </div>
    </div>
</div>
