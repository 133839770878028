import { Route } from '@angular/router';

import { HomeComponent } from './home.component';
import { DemoRouteAccessService } from 'app/core/auth/demo-route-access.service';
import { DemoName } from 'app/demos/demo-name';

export const HOME_ROUTE: Route = {
    path: '',
    component: HomeComponent,
    data: {
        authorities: [],
        demoName: DemoName.MAIN,
        pageTitle: 'home.title',
    },
    canActivate: [DemoRouteAccessService]
};
