import { AccountService } from 'app/core/auth/account.service';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CustomModalsService } from 'app/global/component/modals/custom-modals.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { isButton } from 'app/utils/html-utils';

@Directive({
    selector: '[dcFilterAccess]'
})
export class FilterAccessDirective {
    private authorities: string[] = [];

    @Input() navigateTo: string;
    @Input() embedded: boolean;
    @Input() returnTo: string;
    @Input()
    set dcFilterAccess(value: string | string[]) {
        if (!value || !value.length) {
            // Set minimum authorities if not defined
            this.authorities = DEMO_ROLES.USER_ROLES;
        } else {
            this.authorities = typeof value === 'string' ? [value] : value;
        }
        this.initStyle();
    }

    @HostListener('click', ['$event'])
    onClick(e) {
        if (!this.isAccessGranted() || !this.navigateTo) {
            e.preventDefault();
            e.stopPropagation();
            this.showModal();
        } else {
            this.navigate(this.navigateTo, this.returnTo);
        }
    }

    constructor(private readonly accountService: AccountService,
                private readonly hostElement: ElementRef<Element>,
                private readonly renderer: Renderer2,
                private readonly router: Router,
                private readonly modalService: CustomModalsService) {
    }

    private initStyle(): void {
        this.renderer.setStyle(this.hostElement.nativeElement, 'cursor', 'pointer');
        if (!this.isAccessGranted() && isButton(this.hostElement.nativeElement)) {
            this.renderer.setAttribute(this.hostElement.nativeElement, 'hidden', 'true');
        } else {
            this.renderer.removeAttribute(this.hostElement.nativeElement, 'hidden');
        }
    }

    private isAccessGranted(): boolean {
        return this.accountService.hasAnyAuthority(this.authorities);
    }

    private showModal(): void {
        const options = new ModalOptions();
        options.class = 'access-rejected';
        this.modalService.basicModal('accessNotAllowed', options);
    }

    private navigate(path: string, returnTo: string): void {
        if (!path) {
            return;
        }
        if (path.startsWith('http')) {
            // Use blank as second param to open url in a new tab
            window.open(path, '_blank');
        } else {
            if (this.embedded) {
                this.router.navigate(['/docs/html', { path, returnTo }]);
            } else {
                this.router.navigate([path]);
            }
        }
    }
}
