import * as React from 'react';
import { rce } from 'app/utils/react-utils';
import { styled } from 'baseui';
import { Hint, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import Plot from 'react-plotly.js';
import ReactECharts from 'echarts-for-react';
import { ChartItem } from './chart-item';
import { isArrayEmpty } from '../../../../utils/global.helper';
import { StackedBarData } from './charts/stacked-bar-data';
import { ChartDataFormat } from './chart-data-format';
import { extractStringValue, mathRound } from 'app/global/component/react/react-map-demo/utils/math.utils';

const CELL_MIN_HEIGHT = 380;

const ChartBoardItemMainContainer = styled('div', (({ $theme }) => ({
    height: '100%',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: 'rgb(252, 252, 252)'
})));

const ChartHTMLContainer = styled('div', (({ $theme }) => ({
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
})));

interface IChartBoardItemProps {
    chartId: string;
    chart: ChartItem;
    resizedChart: string; // Valeur mise à jour quand une cellule de la grille est redimensionnée
    theme?: string;
}

interface IChartBoardItemState {
    hint: any;
    width: number;
    height: number;
}

export class ChartBoardItemComponent extends React.Component<IChartBoardItemProps, IChartBoardItemState> {
    elRef;

    private get elementWidth(): number {
        const horizontalMargin = 50;
        return this.elRef?.current?.clientWidth > 0 ? (this.elRef.current.clientWidth - horizontalMargin) : 350;
    }

    private get elementHeight(): number {
        const verticalMargin = 100;
        return this.elRef?.current?.clientHeight > 0 ? (this.elRef.current.clientHeight - verticalMargin) : CELL_MIN_HEIGHT;
    }

    componentDidUpdate(prev: IChartBoardItemProps): void {
        // Si le graph est de type plotly, il faut regénérer le composant après un redimensionnement de la grille
        const isPlotly = this.props.chart && this.props.chart.chartType === ChartDataFormat.PLOTLY;
        if (isPlotly && this.props.chartId === this.props.resizedChart &&
            this.props.resizedChart !== prev.resizedChart) {
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        this.setState({ width: this.elementWidth, height: this.elementHeight });
    }

    constructor(props) {
        super(props);
        this.state = { hint: null, width: 0, height: 0 };

        this.onHover = this.onHover.bind(this);
    }

    render() {
        const { chart } = this.props;

        return rce(ChartBoardItemMainContainer, { key: 'chartItem', ref: this.elRef }, [
                this.getStackedBarChart(chart)
            ]
        );
    }

    private getStackedBarChart(chart: ChartItem): React.ReactNode {
        if (!chart) {
            return null;
        }
        let componentGenerator: (data: any, content?: string) => React.ReactNode;

        switch (chart.chartType) {
            case ChartDataFormat.ECHARTS:
                componentGenerator = this.generateEChart.bind(this);
                break;
            case ChartDataFormat.PLOTLY:
                componentGenerator = this.generatePlotlyChart.bind(this);
                break;
            case ChartDataFormat.HTML:
                componentGenerator = this.generateRawComponent.bind(this);
                break;
            default:
                componentGenerator = this.generateDefaultChart.bind(this);
        }
        return componentGenerator(chart.options, chart.html);
    }

    private generateRawComponent(options: any, content?: string): React.ReactNode {
        return content && rce(ChartHTMLContainer, { key: 'html-content', dangerouslySetInnerHTML: { __html: content } });
    }

    private generateEChart(option): React.ReactNode {
        return rce(ReactECharts, {
            key: 'echart-chart',
            option,
            notMerge: true,
            theme: this.props.theme,
            style: { height: '100%', width: '100%', margin: 'auto' }
        });
    }

    private generatePlotlyChart(chartData): React.ReactNode {
        if (!chartData) {
            return null;
        }
        const layout = chartData.layout || {};
        // Unset width and height values so the autosize can kick in
        layout.width = undefined;
        layout.height = undefined;
        layout.autosize = true;
        const data = chartData.data;

        return rce(Plot, {
            key: 'plotly-chart',
            data,
            layout,
            responsive: true,
            useResizeHandler: true,
            style: { width: '100%', height: '100%' }
        });
    }

    private generateDefaultChart(itemData: ChartItem): React.ReactNode {
        if (!itemData) {
            return null;
        }
        const titleHeight = 30;
        const width = this.state.width;
        const height = this.state.height - titleHeight;
        const { hint } = this.state;

        return rce(XYPlot, {
            key: 'vis-chart', xType: 'ordinal', stackBy: 'y',
            animation: { duration: 100 },
            width,
            height,
            margin: { left: 60, right: 10, top: 10, bottom: 10 },
            onMouseLeave: () => this.setState({ hint: undefined })
        }, [
            rce(XAxis, { key: 'x-axis' }),
            rce(YAxis, { key: 'y-axis' }),
            this.renderChart(itemData.options),
            hint && rce(Hint, {
                    key: 'tooltip',
                    value: hint,
                    format: () => [{
                        title: hint.title,
                        value: extractStringValue(mathRound(hint.y - (hint.y0 || 0)), '')
                    }]
                }
            )
        ]);
    }

    private renderChart(chartData: StackedBarData[]): React.ReactNode[] {
        if (isArrayEmpty(chartData)) {
            return [];
        }
        return chartData.map((d: StackedBarData, index: number) => {
            const { color, data, cluster } = d;
            return rce(VerticalBarSeries, {
                key: `bar-series${ index }`, cluster, data, color,
                onValueMouseOver: this.onHover
            });
        });
    }

    private onHover(value: any): void {
        this.setState({
            hint: value
        });
    }
}
