import { ChartBoardDemoConfig } from '../chart-board-demos/chart-board-demo.config';
import { ChartDemoUrlHandler } from 'app/global/component/react/react-chart-board-demo/chart-demo-url.handler';
import { DemoName } from '../demo-name';
import { HttpMethod } from 'app/global/enum/http-method';
import { WsDefinition } from 'app/global/class/ws/ws-definition';

const WEBSERVICE_ID = 'truckComparison';
const SERVICE_BASE_URL = 'tco2/service/v1';
const SERVICE_CONFIG_PATH = 'dashBoardConfiguration';
const SERVICE_CALCULATE_PATH = 'truckComparison';

// A ajouter dans le service app/global/service/VersionService (méthode initUrls())
export const TCO2_1_SERVICE_VERSION_URL = `tco2/service/getVersion`;

// Handler pour les urls de la démo de type chartboard
const URL_HANDLER = new ChartDemoUrlHandler(
    WEBSERVICE_ID, // Identifiant du webservice
    SERVICE_BASE_URL, // racine du webservice,
    new WsDefinition(SERVICE_CONFIG_PATH, HttpMethod.GET, { serviceName: WEBSERVICE_ID }), // chemin pour le webservice de configuration (menu de gauche, board)
    new WsDefinition(SERVICE_CALCULATE_PATH, HttpMethod.POST));  // chemin pour le webservice de calcul

// Chemin et identifiant de la démo
// (DemoName : webapp/app/demos/demo-name.ts)
export const TCO2_PATH = DemoName.TCO2_1;

// Configuration à charger depuis la démo générique chartboard demo,
// à ajouter au dictionnaire des démos chartboard (webapp/app/demos/chart-board-demos/chart-board-demos.constants.ts)
export const TCO2_DEMO_CONFIG: ChartBoardDemoConfig = new ChartBoardDemoConfig(TCO2_PATH, URL_HANDLER);
