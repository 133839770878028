import { NgModule } from '@angular/core';
import { MapControlComponent } from './map-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MapControlSharedService } from './map-control.shared.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule
    ],
    declarations: [
        MapControlComponent
        ],
    providers: [
        MapControlSharedService
    ],
    bootstrap: [
        MapControlComponent
    ],
    exports: [
        MapControlComponent
    ]
})
export class MapControlSharedModule {}
