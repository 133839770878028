import { ChartBoardDemoConfig } from './chart-board-demo-config';
import { MobicloudResponse } from '../../../class/mobicloud-response';
import { extractIFPResponse } from '../../../../utils/http-utils';
import { MenuConfiguration } from '../react-sidebar/class/menu-configuration';
import { IDashBoardOutput } from './class/output/dash-board-output.interface';
import { IMenuGroupOutput } from './class/output/menu-group-output.interface';
import { isArrayEmpty } from '../../../../utils/global.helper';
import { MenuGroup } from '../react-sidebar/class/menu-group';
import { BoardConfig } from '../react-chart-board/board-config';
import { ChartTheme } from '../react-chart-board/charts/chart-theme';
import { DEFAULT_ECHART_THEME } from '../react-chart-board/chart-board.constants';
import * as echarts from 'echarts';
import { IMenuOptionOutput } from './class/output/menu-option-output.interface';
import { InputType } from '../react-sidebar/class/input-type';
import { IMenuOption } from '../react-sidebar/class/menu-option.interface';
import { MenuSelectOption } from '../react-sidebar/class/menu-select-option';
import { IMenuSelectOptionOutput } from './class/output/menu-select-option-output';

const resolveInputType = (option: IMenuOptionOutput): InputType => {
    if (!option) {
        return null;
    }
    if (option.inputType) {
        return option.inputType;
    }
    // Input type non défini, on le déduit à partir des autres données de l'option
    let type: InputType;
    if (!isArrayEmpty(option.selectValues)) {
        type = InputType.SELECT;
    } else {
        switch (typeof option.defaultValue) {
            case 'string':
                type = InputType.TEXT;
                break;
            case 'number':
                type = InputType.NUMBER;
                break;
            case 'object':
                type = InputType.TABLE;
                break;
            default:
                console.warn('Could not find input type, will use input text as default');
                type = InputType.TEXT;
        }
    }
    return type;
};

export const mapToMenuGroup = (group: IMenuGroupOutput): MenuGroup => {
    if (!group) {
        console.warn('Configuration menu group not defined');
        return null;
    }
    if (isArrayEmpty(group.options)) {
        console.warn('Configuration menu options are empty for group ', group);
        return null;
    }
    const options = group.options.map(mapToMenuOption);
    return new MenuGroup(group.label, options);
};

export const mapToMenuOption = (option: IMenuOptionOutput): IMenuOption => {
    if (!option) {
        console.warn('Chart configuration option is not defined');
        return null;
    }

    const inputType: InputType = resolveInputType(option);
    let defaultValue;
    let selectValues: MenuSelectOption[] = [];

    switch (inputType) {
        case InputType.ADDRESS:
            defaultValue = null;
            break;
        case InputType.NUMBER:
            defaultValue = option.defaultValue;
            break;
        case InputType.TABLE:
            if (isArrayEmpty(option.defaultTable)) {
                console.error('Please check board configuration: table default values not defined for option:', option);
                throw Error('Could not generate board configuration menu, table input values not defined');
            }
            defaultValue = option.defaultTable;
            break;
        case InputType.TEXT:
            defaultValue = option.defaultText;
            break;
        case InputType.SELECT:
            if (isArrayEmpty(option.selectValues)) {
                console.error('Please check board configuration: select values not defined for option:', option);
                throw Error('Could not generate board configuration menu, select values not defined');
            }
            selectValues = option.selectValues.map(mapSelectOutputToMenuSelectOption).filter(Boolean);
            if (isArrayEmpty(selectValues)) {
                console.error('Please check board configuration: no valid select values were found for option:', option);
                throw Error('Could not generate board configuration menu, select values not valid');
            }
            defaultValue = selectValues[0].propertyValue;
            break;
        default:
            console.warn('Chart configuration option has no input type, returning as number', option);
            defaultValue = option.defaultValue;
            break;
    }
    return {
        targetPath: option.targetPath,
        label: option.label,
        inputType,
        defaultValue,
        selectValues
    };
};

const mapSelectOutputToMenuSelectOption = (selectOptionOutput: IMenuSelectOptionOutput): MenuSelectOption => {
    if (!selectOptionOutput) {
        return null;
    }
    return new MenuSelectOption(selectOptionOutput.id, selectOptionOutput.propertyValue, selectOptionOutput.label);
};

/**
 * Extrait la configuration du résultat envoyé par le webservice de configuration
 * Vérifie la validité de la configuration,
 * transforme la partie configuration du menu dans le format attendu par le composant
 *
 * @param r la réponse du ws
 */
export const extractChartBoardConfig = (r: MobicloudResponse<IDashBoardOutput>): ChartBoardDemoConfig => {
    const configSrc: IDashBoardOutput = extractIFPResponse(r);
    const chartConfig = new BoardConfig();
    if (configSrc.grid) {
        chartConfig.columnsNumber = configSrc.grid.columnsNumber;
        chartConfig.rowsNumber = configSrc.grid.rowsNumber;
    }
    const menuConfig: MenuConfiguration = new MenuConfiguration();
    if (configSrc.menuConfiguration) {
        menuConfig.title = configSrc.menuConfiguration.title;
        const groups: IMenuGroupOutput[] = configSrc.menuConfiguration.groups;
        if (!isArrayEmpty(groups)) {
            menuConfig.groups = groups
                .map(mapToMenuGroup)
                .filter(Boolean);
        }
        menuConfig.button = configSrc.menuConfiguration.button;
    }
    return new ChartBoardDemoConfig(chartConfig, menuConfig);
};

export const extractComparisonResult = <T>(r: MobicloudResponse<T>): T => {
    const result: T = extractIFPResponse<T>(r);
    if (!result) {
        throw new Error('Service result is empty');
    }
    return extractIFPResponse(r);
};

export const loadTheme = (config: ChartBoardDemoConfig): void => {
    const boardConfig = config?.grid;
    let theme: ChartTheme;
    if (boardConfig && boardConfig.theme && boardConfig.theme.theme) {
        theme = boardConfig.theme;
    } else {
        theme = DEFAULT_ECHART_THEME;
    }

    echarts.registerTheme(theme.themeName, theme.theme);
};
