import { NgModule } from '@angular/core';
import { GatewayAngularSharedModule } from '../../shared/shared.module';
import { GalleryComponent } from './gallery.component';
import { DocumentationRoute } from './gallery.route';
import { GalleryService } from './gallery.service';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from 'app/global/directive/shared-directives.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
    imports: [
        GatewayAngularSharedModule,
        SharedDirectivesModule,
        NgxExtendedPdfViewerModule,
        PdfViewerModule,
        RouterModule.forChild([DocumentationRoute])
    ],
    declarations: [GalleryComponent],
    providers: [GalleryService],
    bootstrap: [GalleryComponent],
    exports: [GalleryComponent]
})
export class DocumentationModule {
}
