import { DemoPresentationContent } from 'app/demos/presentation/class/demo-presentation-content';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';
import { EnvironmentalFootprintDemoGroupType } from 'app/demos/presentation/environmentalFootprint-presentation/environmentalFootprint-demo-group-type';
import { DemoName } from '../../demo-name';
import {DEMO_ROLES} from "app/demos/role.constants";

// Demonstration groups
export const EnvironmentalFootprintGroups: EnvironmentalFootprintDemoGroupType[] = [
    EnvironmentalFootprintDemoGroupType.FIRSTGROUP,
//    EnvironmentalFootprintDemoGroupType.SECONDGROUP
];

// Demos for each presentation group - all demo information must be provided below
export const EnvironmentalFootprintDemoGroups: Map<EnvironmentalFootprintDemoGroupType, DemoName[]> = new Map<EnvironmentalFootprintDemoGroupType, DemoName[]>()
    .set(EnvironmentalFootprintDemoGroupType.FIRSTGROUP, [DemoName.XDASHACVSCREENING]);
    //.set(EnvironmentalFootprintDemoGroupType.SECONDGROUP, [DemoName.WEATHER_1, DemoName.PDM_1]);

// Content for the header presentation, at the top of the page
// Presentation images are in content/images/demos/presentation/
export const EnvironmentalFootprintPresentationTitle = new DemoPresentationContent(
    'presentation.environmentalFootprint.intro.first.title',
    'presentation.environmentalFootprint.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const EnvironmentalFootprintPresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.environmentalFootprint.intro.second.title',
        'presentation.environmentalFootprint.intro.second.description',
        'top-img1',
    )
];

// Group level content (presentation of the demonstration group)
export const EnvironmentalFootprintDemoGroupContents: Map<EnvironmentalFootprintDemoGroupType, DemoPresentationContent> = new Map<EnvironmentalFootprintDemoGroupType, DemoPresentationContent>()
    .set(EnvironmentalFootprintDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.environmentalFootprint.firstGroup.intro.title',
        'presentation.environmentalFootprint.firstGroup.intro.description',
        'firstGroup-desc',
    ));
    //.set(EnvironmentalFootprintDemoGroupType.SECONDGROUP, new DemoPresentationContent(
    //    'presentation.environmentalFootprint.secondGroup.intro.title',
    //    'presentation.environmentalFootprint.secondGroup.intro.description',
    //    'secondGroup-desc',
    //));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const EnvironmentalFootprintPresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.XDASHACVSCREENING, new DemoPresentationContent(
        'presentation.environmentalFootprint.firstGroup.demo.demo1.title',
        'presentation.environmentalFootprint.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ));
    //.set(DemoName.WEATHER_1, new DemoPresentationContent(
    //    'presentation.environmentalFootprint.secondGroup.demo.demo1.title',
    //    'presentation.environmentalFootprint.secondGroup.demo.demo1.description',
    //    'secondGroup-demo1',
    //))
    //.set(DemoName.PDM_1, new DemoPresentationContent(
    //    'presentation.environmentalFootprint.secondGroup.demo.demo2.title',
    //    'presentation.environmentalFootprint.secondGroup.demo.demo2.description',
    //    'secondGroup-demo2',
    //    new DemoPathAccess('documentation/sample_documentation.html', [], true, true)
    //));

// Access information for each demonstration
export const EnvironmentalFootprintPresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.XDASHACVSCREENING, new DemoPathAccess('demo/xdash/acvscreening-1', DEMO_ROLES.XDASHACVSCREENING_ROLES));
    //.set(DemoName.WEATHER_1, new DemoPathAccess('demo/weather-1', ['DUMMY']))
    //.set(DemoName.PDM_1, new DemoPathAccess('demo/pdm-1', ['DUMMY']));
