import { forEach, get, isArray } from 'lodash';
import { Subscription } from 'rxjs';

import * as moment from 'moment';
import { IsoUnit } from 'app/global/enum/iso-unit';

export const isNil = value => value === undefined || value === null;
// Note: could also use v == null, since null is loosely equal to undefined

export function isNotEmpty(arr: Array<any>): boolean {
    return Array.isArray(arr) && arr.length > 0;
}

export function isArrayEmpty(arr: Array<any>): boolean {
    return !isNotEmpty(arr);
}

export function containsAtLeast(arr: Array<any>, numberOfItems: number): boolean {
    return isNotEmpty(arr) && arr.length > numberOfItems;
}

export function hasSomeNilValues(arr: Array<any>): boolean {
    return isArrayEmpty(arr) || arr.some(v => isNil(v));
}

export function isLengthEqualTo(arr: Array<any>, length: number): boolean {
    return Array.isArray(arr) && arr.length === length;
}

export const isObject = (v: any): boolean => !isNil(v) && typeof v === 'object';

export function getElementAt(arr: Array<any>, index: number): any {
    if (isArrayEmpty(arr) || isNil(index) || index >= arr.length || index < 0) {
        return null;
    }
    return arr[index];
}

export function arrayContainsValues(array: Array<any>, values: Array<any>): boolean {
    return Array.isArray(array) && Array.isArray(values) &&
        values.every(value => array.includes(value));
}

export function findKeyFromValue<T, V>(value: V, map: Map<T, V>): T {
    if (!map || !map.size) {
        return null;
    }
    let keyToFind = null;
    map.forEach((val, key) => {
        if (!keyToFind && val === value) {
            keyToFind = key;
        }
    });
    return keyToFind;
}

/**
 * Unsubscribe a single or an array of subscriptions.
 * @param subs a subscription, or an array of subscriptions.
 */
export function unsubscribe(subs: Subscription | Subscription[]) {
    if (isNil(subs)) {
        return;
    }
    if (isArray(subs)) {
        forEach(subs, sub => sub && !sub.closed ? sub.unsubscribe() : noop);
    } else {
        (subs as Subscription).unsubscribe();
    }
}

export function noop(): void {
    return null;
}

export function sortListFn(staticGetter: Function): any {
    return (a, b) => {
        const val1 = staticGetter(a);
        const val2 = staticGetter(b);
        let sortedResult = 0;
        if (val1 < val2) {
            sortedResult = -1;
        } else if (val1 > val2) {
            sortedResult = 1;
        }
        return sortedResult;
    };
}
export function getFPOutputResponse(output, keyOutput): number {
    if (!isArrayEmpty(output)) {
        return get(output[0], keyOutput);
    }
}

export function convertDateToHoursMs(milliseconds: number): string {
    if (!milliseconds || !moment.duration(milliseconds)) {
        return '';
    }
    const tempTime = moment.duration(milliseconds);
    return `${Math.floor(tempTime.asHours()) } ${IsoUnit.HOUR}  ${tempTime.minutes()} ${IsoUnit.MINUTE}`;
}

export function fillSequentialArray(maxValue: number, minValue = 0, step = 1): number[] {
    const newArray = [];
    for (let i = minValue; i <= maxValue; i += step) {
        newArray.push(i);
    }
    return newArray;
}

export function scrollIntoView(container: Element, selector: string, delay = 100, position: ScrollLogicalPosition = 'start'): void {
    if (!container || !selector) {
        return;
    }
    setTimeout(() => {
        // Find the result header element
        const headerElement = container.querySelector(selector);
        if (headerElement && typeof headerElement.scrollIntoView === 'function') {
            headerElement.scrollIntoView({ behavior: 'smooth', block: position });
        }
    }, delay);
}

/**
 * Replicate object values from their keys
 *
 * (https://github.com/STRML/keyMirror)
 *
 * @param obj
 */
export function keyMirror(obj) {
    const ret = {};
    let key;
    if (!(obj instanceof Object && !Array.isArray(obj))) {
        throw new Error('keyMirror(...): Argument must be an object.');
    }
    for (key in obj) {
        if (!obj.hasOwnProperty(key)) {
            continue;
        }
        ret[key] = key;
    }
    return ret;
}
