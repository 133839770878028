import * as React from 'react';
import { styled } from 'baseui';

import { BdModalContent } from '../../react-modal/bd-modal-content';
import { rce } from '../../../../utils/react-utils';
import { WsErrorModalComponent } from '../../react-modal/ws-error-modal.react.component';
import { ERROR_MODAL_BODY_1, ERROR_MODAL_TITLE, MODEL_TARGET_PATH_SEPARATOR } from './chart-board-demo.constants';
import { ChartBoardComponent } from '../react-chart-board/chart-board.react.component';
import { SidebarComponent } from '../react-sidebar/sidebar.react.component';
import { LoaderComponent } from '../react-loader/loader.react.component';
import { BoardConfig } from '../react-chart-board/board-config';
import { MenuConfiguration } from '../react-sidebar/class/menu-configuration';
import { MenuSettings } from '../react-sidebar/class/menu-settings';
import { ChartItem } from '../react-chart-board/chart-item';
import { HttpClient } from '@angular/common/http';

const ChartBoardDemoMainContainer = styled('div', (({ $theme }) => ({
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    display: 'flex',
    padding: '10px'
})));

interface IChartBoardDemoProps {
    sidebarOpen: boolean;
    boardConfig: BoardConfig;
    menuConfig: MenuConfiguration;
    chartResults: ChartItem[];
    showErrorModal: boolean;
    showLoader: boolean;
    closeModal: () => void;
    onCalculate: (model: { [key: string]: any }) => void;
    toggleSidebar: () => void;
    httpClient: HttpClient;
}

interface IChartBoardState {
}

export class ChartBoardDemoReactComponent extends React.Component<IChartBoardDemoProps, IChartBoardState> {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            sidebarOpen,
            boardConfig,
            closeModal,
            toggleSidebar,
            menuConfig,
            showErrorModal,
            showLoader,
            onCalculate,
            chartResults,
            httpClient
        } = this.props;
        const content = new BdModalContent(ERROR_MODAL_TITLE, ERROR_MODAL_BODY_1);

        return rce(ChartBoardDemoMainContainer, { key: 'main' }, [
                rce(SidebarComponent, {
                    key: 'sidebar',
                    sidebarOpen,
                    config: menuConfig,
                    onCalculate,
                    toggleSidebar,
                    settings: new MenuSettings(MODEL_TARGET_PATH_SEPARATOR),
                    httpClient
                }),
                rce(ChartBoardComponent, { key: 'tco-board', boardConfig, charts: chartResults, sidebarOpen }),
                showLoader && rce(LoaderComponent, { key: 'loading-spinner', showLoader }),
                showErrorModal && rce(WsErrorModalComponent, { key: 'error-modal', content, closeModal }),
            ]
        );
    }
}
