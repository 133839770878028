import { NgModule } from '@angular/core';
import { LeafletMapsModule } from 'app/maps/leaflet-maps/leaflet-maps.module';
import { RouteColorsService } from './route-colors.service';
import { MapFacadeService } from './map-facade.service';
import { MeteoResultService } from 'app/demos/meteo/meteo-result.service';

@NgModule({
    imports: [
        LeafletMapsModule
    ],
    declarations: [],
    providers: [
        MapFacadeService,
        RouteColorsService,
        MeteoResultService
    ]
})
export class MapsModule {}
