import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CompanyEnum, CompanyImgEnum } from 'app/demos/pdm-demo/company-enum';
import {CompanyResponse} from 'app/global/interface/CompanyResponse';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable()
export class CompanyService {

    readonly url = SERVER_API_URL + '/api/company/';

    company_img = new Map<CompanyEnum, CompanyImgEnum>();

    constructor(private readonly httpClient: HttpClient) {
        this.initCompanyImg();
    }

    getCompanyNames(): Observable<string[]> {
        return this.httpClient.get<string[]>(this.url + 'get-names');
    }

    fetchAllCompanies(): Observable<CompanyResponse[]> {
        return this.httpClient.get<CompanyResponse[]>(this.url + 'get-all');
    }

    initCompanyImg(): void {
        this.company_img.set(CompanyEnum.IFPEN, CompanyImgEnum.IFPEN_IMG);
        this.company_img.set(CompanyEnum.RUEIL, CompanyImgEnum.RUEIL_IMG);

    }

    getCompanyImg(companyName: string): CompanyImgEnum {
        return companyName === CompanyEnum.RUEIL ? this.company_img.get(CompanyEnum.RUEIL) : this.company_img.get(CompanyEnum.IFPEN);

    }
}
