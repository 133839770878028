import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { PbfCounters } from './pbf-counters';

@Injectable()
export class PbfSharedService {
    private counters: BehaviorSubject<PbfCounters> = new BehaviorSubject(undefined);
    private isCounterActivated: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
    private mapEvent: BehaviorSubject<any> = new BehaviorSubject(undefined);

    getCounters(): BehaviorSubject<PbfCounters> {
        return this.counters;
    }

    getCountersValue(): PbfCounters {
        return this.counters.getValue();
    }

    setCounters(counters: PbfCounters): void {
        if (this.getCounterStateValue()) {
            this.counters.next(counters);
        }
    }

    setCounterState(counter): void {
        this.setCounters(new PbfCounters());
        this.isCounterActivated.next(counter);
    }

    getCounterState(): BehaviorSubject<boolean> {
        return this.isCounterActivated;
    }

    getCounterStateValue(): boolean {
        return this.isCounterActivated.getValue();
    }

    setMapEvent(event): void {
        if (this.getCounterStateValue()) {
            this.mapEvent.next(event);
        }
    }

    getMapEvent(): BehaviorSubject<any> {
        return this.mapEvent;
    }
}
