export enum RainRiskIntensityLabel {
    AUCUNE_PLUIE = 'Aucune pluie',
    BRUINE = 'Bruine',
    PLUIE_FAIBLE = 'Pluie faible',
    PLUIE_MODEREE = 'Pluie Modérée',
    PLUIE_FORTE = 'Pluie forte'
}

export const METEO_DEFAULTS = {
    rainProperties: [
        {
            label: RainRiskIntensityLabel.AUCUNE_PLUIE,
            value: 0.1,
            relativeValue: 0.1,
            color: 'rgb(255, 255, 255)'
        },
        {
            label: RainRiskIntensityLabel.BRUINE,
            value: 1,
            relativeValue: 0.6,
            color: 'rgb(205, 225, 240)'
        },
        {
            label: RainRiskIntensityLabel.PLUIE_FAIBLE,
            value: 3,
            relativeValue: 2,
            color: 'rgb(94, 197, 237)'
        },
        {
            label: RainRiskIntensityLabel.PLUIE_MODEREE,
            value: 7,
            relativeValue: 5,
            color: 'rgb(0, 158, 224)'
        },
        {
            label: RainRiskIntensityLabel.PLUIE_FORTE,
            value: 20,
            relativeValue: 13,
            color: 'rgb(0, 106, 179)'
        },
        { // abstract property
            label: '',
            value: 30,
            relativeValue: 20,
            color: 'rgb(0, 0, 64)'
        }
    ]
};
