import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DemoAccessSharedService } from 'app/global/service/demo-access-shared.service';
import { getDemoInfosByName } from 'app/utils/demo-utils';
import { AccountService } from 'app/core/auth/account.service';

@Injectable({ providedIn: 'root' })
export class DemoRouteAccessService implements CanActivate {

    constructor(private readonly demoService: DemoAccessSharedService,
                private readonly accountService: AccountService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        let demoName = route.data['demoName'];
        const demoId: string = route.paramMap.get('id');
        if (demoId) {
            demoName += demoId;
        }
        return !this.accountService.isAuthenticated() || this.demoService.checkDemoActive(getDemoInfosByName(demoName));
    }

}
