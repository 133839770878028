<div class="row home-container justify-content-around">
    <div class="home-inner-container" align="center" [ngSwitch]="isAuthenticated()">
        <div class="row justify-content-around" *ngSwitchCase="false">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-10" style="padding-top: 50px;">
                    <h1>Bienvenue</h1>
                    <div [ngSwitch]="isAuthenticated()" style="padding-top: 30px;">
                        <div class="alert alert-success" *ngSwitchCase="true">
                            <span *ngIf="account"> Vous êtes connecté en tant que "{{account.login}}". </span>
                        </div>
                        <div class="alert alert-warning" *ngSwitchCase="false">
                            <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign in</a>
                        </div>
                        <div class="alert alert-warning" *ngSwitchCase="false">
                            <span jhiTranslate="global.messages.info.register.noaccount">You don't have an account yet?</span>&nbsp;
                            <a class="alert-link" routerLink="account/register" jhiTranslate="global.messages.info.register.link">
                                Register a new account</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid" align="center" *ngSwitchCase="true">
            <div class="row m-3 justify-content-around">
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_DIGITALIZATION)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/digitalization">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/digitalization.png" alt="Digitalisation">
                        <h4 class="card-title" jhiTranslate="presentation.digitalization.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_POLLUTINGEMISSIONS)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/pollutingEmissions">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/pollutingEmissions.png" alt="PollutingEmissions">
                        <h4 class="card-title" jhiTranslate="presentation.pollutingEmissions.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_AIRQUALITY)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/airQuality">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/airQuality.png" alt="Services Qualité de l'air">
                        <h4 class="card-title" jhiTranslate="presentation.airQuality.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_TRUCK)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/truck">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/truck.png" alt="Poids lourds services connectés">
                        <h4 class="card-title" jhiTranslate="presentation.truck.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_LIGHTVEHICLE)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/lightVehicle">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/lightVehicle.png" alt="Light Vehicle">
                        <h4 class="card-title" jhiTranslate="presentation.lightVehicle.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_BIKE)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/bike">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/bike.png" alt="Services Vélos">
                        <h4 class="card-title" jhiTranslate="presentation.bike.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_ELECTROMOBILITY)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/electromobility">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/electromobility.png" alt="Electromobility">
                        <h4 class="card-title" jhiTranslate="presentation.electromobility.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_PROSPECTIVESTUDIES)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/prospectiveStudies">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/prospectiveStudies.png" alt="Etudes prospectives">
                        <h4 class="card-title" jhiTranslate="presentation.prospectiveStudies.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_ENVIRONMENTALFOOTPRINT)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/environmentalFootprint">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/presentation/environmentalFootprint.png" alt="Environmental Footprint">
                        <h4 class="card-title" jhiTranslate="presentation.environmentalFootprint.title"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.MOBILITY_1)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/mobilityobservatory-1">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/demos/kepler/kepler_round.png">
                        <h4 class="card-title" jhiTranslate="GalleryHome.mobilityObservatory"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.AFTERSIM)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/aftersim">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/mobilitySimulation.png" alt="Modélisation de la mobilité">
                        <h4 class="card-title" jhiTranslate="GalleryHome.aftersim"></h4>
                    </a>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.GALLERY_TOOLKIT)">
                    <div class="card galleryCard text-center rounded-lg p-5" routerLink="gallery/toolkit">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/toolkit.png">
                        <h4 class="card-title" jhiTranslate="toolkit.title"></h4>
                    </div>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.GALLERY_DOCUMENTATION)">
                    <div class="card galleryCard text-center rounded-lg p-5" routerLink="gallery/documentation">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/documentation.png">
                        <h4 class="card-title" jhiTranslate="documentation.title"></h4>
                    </div>
                </div>
                <div class="galleryColumn" *dcDemoAccess="getDemoAccess(demoNames.PRESENTATION_EXAMPLE)">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="demo/presentation/example">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/mobicloud.png" alt="Example">
                        <h4 class="card-title" jhiTranslate="presentation.example.title"></h4>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-around">
    <footer class="footer text-center">
        <div class="row justify-content-around">
            <!-- Footer Location-->
            <div class="col-lg-4 mb-2 mb-lg-0">
                <a href="https://www.ifpenergiesnouvelles.com"><img class="img-fluid" src="/content/images/IFPEN_LogoBlanc.png" height="50px" alt=""></a>
                <!--<h4 class="text-uppercase mb-4">IFP Energies nouvelles</h4>-->
                <p class="lead mb-1">
                    1 et 4 avenue de Bois-Préau
                    <br>
                    92852 Rueil-Malmaison Cedex
                    <br>
                    France
                </p>
            </div>
            <!-- Footer About Text-->
            <div class="col-lg-4 mb-2 mb-lg-0">
                <h4 class="text-uppercase mb-2" jhiTranslate="home.footer.aboutus">About Us</h4>
                <p class="lead mb-0" jhiTranslate="home.footer.aboutIfpen">IFPEN</p>
            </div>
            <!-- Footer Social Icons-->
            <div class="col-lg-4 mb-2 mb-lg-0">
                <h4 class="text-uppercase mb-2" jhiTranslate="home.footer.web"></h4>
                <div class="row justify-content-center">
                    <div class="col-2"><a href="https://fr.linkedin.com/company/ifp-energies-nouvelles"><img class="img-responsive" src="/content/images/contact/linkedin.png" height="35px"></a></div>
                    <div class="col-2"><a href="https://www.youtube.com/channel/UCLC0KYGsOya0E_7pwvBraVw"><img class="img-responsive" src="/content/images/contact/youtube.png" height="35px"></a></div>
                    <div class="col-2"><a href="https://twitter.com/IFPENinnovation"><img class="img-responsive" src="/content/images/contact/twitter.png" height="35px"></a></div>
                </div>
                <br>
                <h4 class="text-uppercase mb-2 mt-2" jhiTranslate="home.footer.contact"></h4>
                <a href="mailto:contact@mobicloud.ifpen.com"><img class="img-responsive" src="/content/images/contact/mail.png" height="35px"></a>
            </div>
        </div>
    </footer>
    <!-- Copyright Section-->
    <div class="copyright py-2 text-center text-white">
        <div class="container" jhiTranslate="home.footer.copyright">© Copyright IFP Energies nouvelles. All Rights Reserved</div>
    </div>
</div>
