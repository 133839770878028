import { filter } from 'rxjs/operators';

import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, PreloadAllModules, Router, RouterEvent, RouterModule } from '@angular/router';
import { errorRoute } from './layouts/error/error.route';
import { navbarRoute } from './layouts/navbar/navbar.route';
import { Authority } from 'app/shared/constants/authority.constants';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { footerRoute } from './layouts';

const LAYOUT_ROUTES = [navbarRoute, footerRoute, ...errorRoute];

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'admin',
                    data: {
                        authorities: [Authority.ADMIN],
                    },
                    canActivate: [UserRouteAccessService],
                    loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule),
                },
                {
                    path: 'account',
                    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
                },
                {
                    path: 'demo',
                    loadChildren: () => import('./demos/demos-routing.module').then(m => m.DemosRoutingModule),
                },
                {
                    path: 'gallery',
                    loadChildren: () => import('./galleries/galleries.module').then(m => m.GalleriesModule)
                },
                {
                    path: 'docs',
                    loadChildren: () => import('./docs/docs.module').then(m => m.DocsModule)
                },
                ...LAYOUT_ROUTES,
            ],
            {
                enableTracing: false,
                preloadingStrategy: PreloadAllModules
                // scrollPositionRestoration: 'top' -> issue, see https://github.com/angular/angular/issues/24547
            }
        )
    ],
    exports: [RouterModule],
})
export class GatewayAngularAppRoutingModule {
    // Hack pour régler un problème de scrolling : https://github.com/angular/angular/issues/24547
    constructor(router: Router) {
        router.events.pipe(
            filter((e: RouterEvent) => e instanceof NavigationStart || e instanceof NavigationEnd)
        ).subscribe({
            next: () => {
                setTimeout(() => {
                    document.getElementById('main-app-container').scrollTop = 0;
                }, 10);
            }
        });
    }
}
