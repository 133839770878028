import { Route } from '@angular/router';
import { PdfviewerComponent } from './pdfviewer.component';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';
import { ROLES } from 'app/docs/role.constants';

export const pdfviewerRoute: Route = {
    path: 'pdf/:pdfdocument',
    component: PdfviewerComponent,
    data: {
        authorities: ROLES.PDFVIEWER_ROLES,
        pageTitle: 'pdfviewer.title'
    },
    canActivate: [UserRouteAccessService]
};
