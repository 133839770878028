import { styled } from 'baseui';

export const SidebarLabelContainer = styled('div', ({ $theme }) => ({
    fontSize: '14px',
    color: $theme.colors.primary500
}));

export const SidebarSmallLabelContainer = styled('div', ({ $theme }) => {
    return {
        fontSize: '13px',
        color: $theme.colors.primary500
    };
});
export const FilterInputCriteria = styled('div', (({ $theme }) => ({
    lineHeight: '48px',
    paddingLeft: '10px',
    width: '100%',
    cursor: 'pointer'
})));
