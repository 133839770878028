import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { MapControlName } from './map-controle-name';

@Injectable()
export class MapControlSharedService {
    activated = new BehaviorSubject<MapControlName>(undefined);

    init(): void {
        this.activated.next(null);
    }

    getActivated(): BehaviorSubject<MapControlName> {
        return this.activated;
    }

    getActivatedValue(): MapControlName {
        return this.activated.getValue();
    }

    nextActivated(name: MapControlName): void {
        this.activated.next(name);
    }

}
