import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FileUtils } from 'app/utils/file-utils';

@Injectable()
export class PdfviewerService {

    private static readonly ROOT_URL = 'files';

    constructor(private readonly httpClient: HttpClient) {
    }

    getWebContent(url: string): Observable<Blob> {
        const queryOptions = {
            responseType: 'blob' as 'json',
            withCredentials: true,
        };
        return this.httpClient.get<Blob>(url, queryOptions);
    }

    getWebContentAsObjectUrl(filePath: string): Observable<string> {
        if (!filePath || !filePath.trim().length) {
            throwError('File path is not valid');
        }
        return this.getWebContent(`${ PdfviewerService.ROOT_URL }/${ filePath }`).pipe(
            map((file: Blob) => FileUtils.htmlToDataURL(file))
        );
    }
}
