import { Routes } from '@angular/router';
import { pdfviewerRoute } from 'app/docs/pdfviewer/pdfviewer.route';
import { htmlViewerRoute } from 'app/docs/html-viewer/html-viewer.route';
import {GalleryPdfViewerRoute} from 'app/docs/gallery-pdf-viewer/gallery-pdf-viewer.route';

const DOCS_ROUTES = [
    pdfviewerRoute,
    htmlViewerRoute,
    GalleryPdfViewerRoute
];

export const docsState: Routes = [
    {
        path: '',
        children: DOCS_ROUTES
    }
];
