export class RangeValues {
    constructor(public  minValue?: number,
                public  maxValue?: number,
                public  floor?: number,
                public  ceil?: number,
                public  rightToLeft?: boolean,
                public  step?: number) {

    }
}
