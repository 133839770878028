import * as React from 'react';
import { rce } from 'app/utils/react-utils';
import { SimpleModalComponent } from './simple-modal.react.component';
import { BdModalContent } from './bd-modal-content';

interface IBdWsErrorModalProps {
    title: string;
    content?: BdModalContent;
    closeModal: Function;
}

interface IBdWsErrorModalState {
}

export class WsErrorModalComponent extends React.Component<IBdWsErrorModalProps, IBdWsErrorModalState> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { content } = this.props;
        return rce(SimpleModalComponent, {
            key: 'WsErrorModal',
            title: content && content.title,
            body1: content && content.body1,
            body2: content && content.body2,
            closeModal: this.props.closeModal
        });
    }
}
