import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribe } from 'app/utils/global.helper';
import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core/user/account.model';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { DemoName } from 'app/demos/demo-name';
import { DemoAccess } from 'app/demos/demo-access';
import { getDemoInfosByName } from 'app/utils/demo-utils';
import {GalleryService} from 'app/galleries/documentation/gallery.service';
import jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute } from '@angular/router';
import {STATIC_IMAGE} from 'app/app.constants';
import {FileNameType} from 'app/galleries/documentation/FileNameType';


@Component({
    selector: 'dc-documentation',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    demoNames = DemoName;
    roles = DEMO_ROLES;
    account: Account;
    subs: Subscription[] = [];
    rolesWithinToken: string[];
    img: string = STATIC_IMAGE;
    fileNameType: FileNameType[];

    constructor(private readonly accountService: AccountService,
                private galleryService: GalleryService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        const authToken = JSON.parse(localStorage.getItem('dc-authenticationtoken'));
        const tokenPayload = this.getDecodedAccessToken(authToken);
        this.rolesWithinToken = tokenPayload.auth.split(',');
        this.subs.push(this.accountService.identity().subscribe((account: Account) => {
            this.account = account;
        }));
        this.fetchAllPdfFilesByRole();
    }

        fetchAllPdfFilesByRole() {
        this.galleryService.getPdfFilesByRoles(this.rolesWithinToken).subscribe(res => {
           if (res) {
               this.fileNameType = res;
           }
        });
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    ngOnDestroy(): void {
        unsubscribe(this.subs);
    }

    getDemoAccess(name: DemoName): DemoAccess {
        return getDemoInfosByName(name);
    }

    navigate(file: FileNameType) {
        if (file.fileType === 'pdf') {
            this.router.navigate(['docs/pdf-view', {data: file.fileName}]);
        } else {
            this.router.navigate(['docs/html', {data: file.fileName}]);
        }
    }
}
