import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {GECOAIR_HOST} from '../../demos/pdm-demo/token.constant';
import { Observable } from 'rxjs';
import {CguModel} from 'app/shared/general-condition/CguModel';

@Injectable({
  providedIn: 'root'
})
export class GeneralConditionService {

    constructor(private httpClient: HttpClient) { }

    latestCguVersion(): Observable<CguModel> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('dc-authenticationtoken')));
        return this.httpClient.get<CguModel>(GECOAIR_HOST + '/api/latest-cgu', {headers});
    }
}
