import { ChartItem } from '../react-chart-board/chart-item';
import { ChartDataFormat } from '../react-chart-board/chart-data-format';
import { isArrayEmpty } from '../../../../utils/global.helper';
import { ChartboardDemoResult } from './class/output/chartboard-demo-result';

const checkChartType = (format: ChartDataFormat): ChartDataFormat => {
    let checkedFormat: ChartDataFormat;

    switch (format) {
        case ChartDataFormat.ECHARTS:
            checkedFormat = ChartDataFormat.ECHARTS;
            break;
        case ChartDataFormat.PLOTLY:
            checkedFormat = ChartDataFormat.PLOTLY;
            break;
        case ChartDataFormat.HTML:
            checkedFormat = ChartDataFormat.HTML;
            break;
        default:
            checkedFormat = ChartDataFormat.VIS;
    }
    return checkedFormat;
};

const mapToChartItem = (item: ChartItem): ChartItem => {
    if (!item) {
        return null;
    }
    item.chartType = checkChartType(item.chartType);

    return item;
};

export const mapResultToChartItems = (result: ChartboardDemoResult): ChartItem[] => {
    if (!result || isArrayEmpty(result.chart)) {
        return [];
    }
    return result.chart.map(mapToChartItem)
        .filter(Boolean);
};
