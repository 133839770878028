import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { ItineraryPointLayer } from '../../class/itinerary-point-layer';

@Injectable()
export class ItinerarySharedService {

    // The cached layers, built from the itinerary form values
    private pointLayers: BehaviorSubject<ItineraryPointLayer[]> = new BehaviorSubject(undefined);
    private isFocus: boolean;

    getPointLayersValue(): ItineraryPointLayer[] {
        return this.pointLayers.getValue();
    }

    getPointLayerValueAt(index: number): ItineraryPointLayer {
        const layers = this.getPointLayersValue() || [];
        if (index < 0 || index >= layers.length) {
            return null;
        }
        return layers[index];
    }

    getPointLayers(): BehaviorSubject<ItineraryPointLayer[]> {
        return this.pointLayers;
    }

    nextPointLayers(value: ItineraryPointLayer[]): void {
        this.pointLayers.next(value);
    }

    refreshPointLayers(): void {
        this.pointLayers.next([...this.getPointLayersValue()]);
    }

    clearValue(): void {
        this.pointLayers.next(null);
    }

    setIsItineraryFocused(isFocus: boolean): void {
        this.isFocus = isFocus;
    }

    getIsItineraryFocused(): boolean {
        return this.isFocus;
    }

}
