import { PresentationContentOptions } from 'app/demos/presentation/class/presentation-content-options';
import { IPresentationContent } from 'app/demos/presentation/component/presentation-content.interface';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';

export class DemoPresentationContent implements IPresentationContent {
    constructor(public titleKey: string,
                public descriptionKey: string,
                public imgUrl: string,
                public showMore?: DemoPathAccess, // For the show more page - if not provided, button won't show
                public options?: PresentationContentOptions) {
    }
}
