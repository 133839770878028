import { Route } from '@angular/router';
import { GalleryComponent } from './gallery.component';
import { RouteAccess } from 'app/global/decorator/route-access.decorator';
import { DemoName } from 'app/demos/demo-name';

@RouteAccess({ demoName: DemoName.GALLERY_DOCUMENTATION })
export class DocumentationRoute implements Route {
    static path = 'documentation';
    static component = GalleryComponent;
    static data = { pageTitle: 'documentation.title' };
}
