import {DemoPresentationContent} from 'app/demos/presentation/class/demo-presentation-content';
import {DemoPathAccess} from 'app/demos/presentation/class/demo-path-access';
import {DEMO_ROLES} from 'app/demos/role.constants';
import {
    ProspectiveStudiesDemoGroupType
} from 'app/demos/presentation/prospectiveStudies-presentation/prospectiveStudies-demo-group-type';
import {DemoName} from '../../demo-name';

// Demonstration groups
export const ProspectiveStudiesGroups: ProspectiveStudiesDemoGroupType[] = [ProspectiveStudiesDemoGroupType.FIRSTGROUP];

// Demos for each presentation group - all demo information must be provided below
export const ProspectiveStudiesDemoGroups: Map<ProspectiveStudiesDemoGroupType, DemoName[]> = new Map<ProspectiveStudiesDemoGroupType, DemoName[]>()
    .set(ProspectiveStudiesDemoGroupType.FIRSTGROUP, [DemoName.DASHBOARDH24EU, DemoName.DASHBOARDE85SNPAA, DemoName.DASHBOARDCMACGMACV, DemoName.DASHBOARDCMACGMECO])

// Content for the header presentation, at the tof of the page
// Presentation images are in content/images/demos/presentation/
export const ProspectiveStudiesPresentationTitle = new DemoPresentationContent(
    'presentation.prospectiveStudies.intro.first.title',
    'presentation.prospectiveStudies.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const ProspectiveStudiesPresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.prospectiveStudies.intro.second.title',
        'presentation.prospectiveStudies.intro.second.description',
        'top-img1'
    )
];

// Group level content (presentation of the demonstration group)
export const ProspectiveStudiesDemoGroupContents: Map<ProspectiveStudiesDemoGroupType, DemoPresentationContent> = new Map<ProspectiveStudiesDemoGroupType, DemoPresentationContent>()
    .set(ProspectiveStudiesDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.prospectiveStudies.firstGroup.intro.title',
        'presentation.prospectiveStudies.firstGroup.intro.description',
        'firstGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const ProspectiveStudiesPresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.DASHBOARDH24EU, new DemoPresentationContent(
        'presentation.prospectiveStudies.firstGroup.demo.demo1.title',
        'presentation.prospectiveStudies.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ))
    .set(DemoName.DASHBOARDE85SNPAA, new DemoPresentationContent(
        'presentation.prospectiveStudies.firstGroup.demo.demo2.title',
        'presentation.prospectiveStudies.firstGroup.demo.demo2.description',
        'firstGroup-demo2',
    )).set(DemoName.DASHBOARDCMACGMACV, new DemoPresentationContent(
        'presentation.prospectiveStudies.firstGroup.demo.demo3.title',
        'presentation.prospectiveStudies.firstGroup.demo.demo3.description',
        'firstGroup-demo3',
    )).set(DemoName.DASHBOARDCMACGMECO, new DemoPresentationContent(
        'presentation.prospectiveStudies.firstGroup.demo.demo4.title',
        'presentation.prospectiveStudies.firstGroup.demo.demo4.description',
        'firstGroup-demo4',
    ));

// Access information for each demonstration
export const ProspectiveStudiesPresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.DASHBOARDH24EU, new DemoPathAccess('demo/dashboard-h24eu-1', DEMO_ROLES.DASHBOARDH24EU_ROLES))
    .set(DemoName.DASHBOARDE85SNPAA, new DemoPathAccess('demo/dashboard-e85-snpaa-1', DEMO_ROLES.DASHBOARDE85SNPAA_ROLES))
    .set(DemoName.DASHBOARDCMACGMACV, new DemoPathAccess('demo/dashboard-cmacgm-acv-1', DEMO_ROLES.DASHBOARDCMACGMACV_ROLES))
    .set(DemoName.DASHBOARDCMACGMECO, new DemoPathAccess('demo/dashboard-cmacgm-eco-1', DEMO_ROLES.DASHBOARDCMACGMECO_ROLES));
