/* after changing this file run 'npm run webpack:build' */
import '../content/scss/vendor.scss';

import 'lodash';
import 'd3';
import 'd3-timeline';
import * as Leaflet from 'leaflet';
import 'leaflet.vectorgrid';
import 'hammerjs';

export const L: any = Leaflet;
// Create global const for vectorGrid leaflet plugin
export const vectorGrid = L.vectorGrid;
// jhipster-needle-add-element-to-vendor - JHipster will add new menu items here
