import * as React from 'react';

export const rce = React.createElement;

/*
 TODO check: Doesn't work with BaseUi's BaseProvider (children attribute in props returned undefined)
 */
export function rc(component: any, props?: any, childElements?: any): any {
    const keyId = Math.round(Math.random() * 1000000);
    return React.createElement(component, {
        key: `component${keyId}`,
        ...props
    }, childElements);
}

/**
 * Log function to check on a component's state,
 * can be called from componentDidUpdate(prevProps, prevState)
 *
 * componentDidUpdate(prevProps, prevState): void {
 *      logPropsChanged(prevProps, this.props, prevState, newState);
 * }
 *
 * @param prevProps
 * @param newProps
 * @param prevState
 * @param newState
 */
export function logPropsChanged(prevProps, newProps, prevState, newState): void {
    if (prevProps) {
        Object.entries(newProps).forEach(([key, val]) =>
            prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        );
    }
    if (newState && prevState) {
        Object.entries(newState).forEach(([key, val]) =>
            prevState[key] !== val && console.log(`State '${key}' changed`)
        );
    }
}

export function lookForPropsChanges(nextProps, prevProps): boolean {
    const nullDiff = (!prevProps && nextProps) || (prevProps && !nextProps);
    if (nullDiff) {
        return true;
    }
    if (!nextProps && !prevProps) {
        return false;
    }
    return Object.entries(nextProps).some(([key, value]) => prevProps[key] !== value);
}
