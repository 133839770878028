import { DemoInfo } from '../demos/demo-info';
import { isArrayEmpty } from './global.helper';
import { DemoName } from 'app/demos/demo-name';
import { DemoInfos } from 'app/demos/demo.constants';
import { DemoGroupInfo } from 'app/demos/demo-group-info';
import { camelCase } from 'lodash';

export const findDemoFromUrl = (url: string, includeDemoGroups?: boolean): DemoInfo => {
    if (!url) {
        return null;
    }
    const demoInfos: DemoInfo[] = Array.from(DemoInfos.values());
    const matches = demoInfos.filter(d => d && d.name && url.endsWith(`/${d.name.toLowerCase()}`));
    let demo = isArrayEmpty(matches) ? null : matches[0];
    // Handle demo groups
    if (demo && (!includeDemoGroups && demo instanceof DemoGroupInfo)) {
        demo = DemoInfos.get(DemoName.MAIN);
    }
    return demo;
};

export const mapDemoNamesToDemoInfos = (names: DemoName[]): DemoInfo[] => {
    if (isArrayEmpty(names)) {
        return [];
    }
    return names.map((name: DemoName) => getDemoInfosByName(name)).filter(Boolean);
};

export const existsByDemoName = (name: DemoName): boolean => DemoInfos.has(name);

export const getDemoInfosByName = (name: DemoName): DemoInfo => DemoInfos.get(name);
export const getDemoRolesByName = (name: DemoName): string[] => DemoInfos.get(name)?.roles;

export const getDemoTitleKey = (demoTitleKey: string): string => demoTitleKey ? `global.demos.title.${ demoTitleKey }` : 'global.demos.title.home';
export const extractDemoTitleKey = (demoName: string): string => demoName ? camelCase(demoName) : null;
