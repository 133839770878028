import { DemoPresentationContent } from 'app/demos/presentation/class/demo-presentation-content';
import { DemoPathAccess } from 'app/demos/presentation/class/demo-path-access';
import { DEMO_ROLES } from 'app/demos/role.constants';
import { TruckDemoGroupType } from 'app/demos/presentation/truck-presentation/truck-demo-group-type';
import { DemoName } from '../../demo-name';

// Demonstration groups
export const TruckGroups: TruckDemoGroupType[] = [TruckDemoGroupType.FIRSTGROUP, TruckDemoGroupType.SECONDGROUP];

// Demos for each presentation group - all demo information must be provided below
export const TruckDemoGroups: Map<TruckDemoGroupType, DemoName[]> = new Map<TruckDemoGroupType, DemoName[]>()
    .set(TruckDemoGroupType.FIRSTGROUP, [DemoName.VERDIR_MA_FLOTTE, DemoName.TCO2_1, DemoName.TCO2_2])
    .set(TruckDemoGroupType.SECONDGROUP, [DemoName.TRUCK_ROUTING, DemoName.LONGRUN_1, DemoName.EMPOWER_1])    ;

// Content for the header presentation, at the tof of the page
// Presentation images are in content/images/demos/presentation/
export const TruckPresentationTitle = new DemoPresentationContent(
    'presentation.truck.intro.first.title',
    'presentation.truck.intro.first.description',
    'top-bg',
);

// Content for the global presentation description (second section)
// Providing a DemoPathAccess as 4th arg will display a "show more" button
export const TruckPresentationDescription: DemoPresentationContent[] = [
    new DemoPresentationContent(
        'presentation.truck.intro.second.title',
        'presentation.truck.intro.second.description',
        'top-img1',
        new DemoPathAccess('documentation/HDVConnected.html', DEMO_ROLES.USER_ROLES, true, true, '/demo/presentation/truck')
    )
];

// Group level content (presentation of the demonstration group)
export const TruckDemoGroupContents: Map<TruckDemoGroupType, DemoPresentationContent> = new Map<TruckDemoGroupType, DemoPresentationContent>()
    .set(TruckDemoGroupType.FIRSTGROUP, new DemoPresentationContent(
        'presentation.truck.firstGroup.intro.title',
        'presentation.truck.firstGroup.intro.description',
        'firstGroup-desc',
    ))
    .set(TruckDemoGroupType.SECONDGROUP, new DemoPresentationContent(
        'presentation.truck.secondGroup.intro.title',
        'presentation.truck.secondGroup.intro.description',
        'secondGroup-desc',
    ));

// Demo level content (presentation of the demonstration)
// Providing a DemoPathAccess as 4th arg will display a "show more" button, if specified authorities match
export const TruckPresentationContents: Map<DemoName, DemoPresentationContent> = new Map()
    .set(DemoName.VERDIR_MA_FLOTTE, new DemoPresentationContent(
        'presentation.truck.firstGroup.demo.verdirMaFlotte.title',
        'presentation.truck.firstGroup.demo.verdirMaFlotte.description',
        'Verdir-ma-flotte',
    ))
    .set(DemoName.TCO2_1, new DemoPresentationContent(
        'presentation.truck.firstGroup.demo.demo1.title',
        'presentation.truck.firstGroup.demo.demo1.description',
        'firstGroup-demo1',
    ))
    .set(DemoName.TCO2_2, new DemoPresentationContent(
        'presentation.truck.firstGroup.demo.demo2.title',
        'presentation.truck.firstGroup.demo.demo2.description',
        'firstGroup-demo2',
        ))
    .set(DemoName.TRUCK_ROUTING, new DemoPresentationContent(
        'presentation.truck.secondGroup.demo.demo1.title',
        'presentation.truck.secondGroup.demo.demo1.description',
        'secondGroup-demo1',
    ))
    .set(DemoName.LONGRUN_1, new DemoPresentationContent(
        'presentation.truck.secondGroup.demo.demo2.title',
        'presentation.truck.secondGroup.demo.demo2.description',
        'secondGroup-demo2',
        new DemoPathAccess('documentation/HDVConnected.html', DEMO_ROLES.USER_ROLES, true, true, '/demo/presentation/truck')
    ))
    .set(DemoName.EMPOWER_1, new DemoPresentationContent(
        'presentation.truck.secondGroup.demo.demo3.title',
        'presentation.truck.secondGroup.demo.demo3.description',
        'secondGroup-demo3',
        new DemoPathAccess('documentation/HDVConnected.html', DEMO_ROLES.USER_ROLES, true, true, '/demo/presentation/truck')
    ));

// Access information for each demonstration
export const TruckPresentationAccess: Map<DemoName, DemoPathAccess> = new Map()
    .set(DemoName.VERDIR_MA_FLOTTE, new DemoPathAccess('https://verdirmaflotte.fr', DEMO_ROLES.USER_ROLES))
    .set(DemoName.TCO2_1, new DemoPathAccess('demo/chartboard/tco2-1', DEMO_ROLES.TCO2_ROLES))
    .set(DemoName.TCO2_2, new DemoPathAccess('demo/chartboard/tco2-2', DEMO_ROLES.TCO2DATA_ROLES))
    .set(DemoName.TRUCK_ROUTING, new DemoPathAccess('demo/truckrouting-1', DEMO_ROLES.TRUCKROUTING_ROLES))
    .set(DemoName.LONGRUN_1, new DemoPathAccess('demo/longrun-1', DEMO_ROLES.LONGRUNECOROUTING_ROLES))
    .set(DemoName.EMPOWER_1, new DemoPathAccess('demo/empower-1', DEMO_ROLES.EMPOWERECOROUTING_ROLES));
