export function getFirstElementByClassName(className: string): Element {
    if (!className || !className.trim()) {
        throw new Error('Can not find element if class name is not defined');
    }
    const elements = document.getElementsByClassName(className);
    if (!elements || !elements.length) {
        throw new Error('Could not find any elements with class name: ' + className);
    }
    return elements[0];
}

export const isButton = (el: Element): boolean => {
    return el && el.tagName === 'button' || el.getAttribute('type') === 'button';
};
