import { Injectable } from '@angular/core';
import { IMapService } from '../map-service.interface';
import { LeafletRouteLayerService } from './leaflet-route-layer.service';
import { PolylineGeometry } from '../../demos/bike-demo/bike-route-results';
import { ExtendedPolyline } from './extended-polyline';
import { GeoJsonObject } from 'geojson';
import { GeoJSON } from 'leaflet';
import { ItineraryPointLayer } from 'app/global/class/itinerary-point-layer';
import { LeafletPointService } from './leaflet-map-component/leaflet-point.service';
import { ItineraryPoint } from 'app/global/class/itinerary-point';

@Injectable()
export class LeafletMapService implements IMapService {

    constructor(private readonly leafletRouteLayerService: LeafletRouteLayerService,
                private readonly leafletPointService: LeafletPointService) {}

    buildLayersFromRawResult(geometry: PolylineGeometry): any {
        return this.leafletRouteLayerService.buildLayersFromRawResult(geometry);
    }

    buildMeteoGeoJSON(geoJSON: GeoJsonObject): GeoJSON {
        return this.leafletRouteLayerService.buildMeteoGeoJSON(geoJSON);
    }

    buildAirQualityGeoJSON(geoJSON: GeoJsonObject): GeoJSON {
        return this.leafletRouteLayerService.buildAirQualityGeoJSON(geoJSON);
    }

    refreshSelected(layers: ExtendedPolyline[], selected: ExtendedPolyline): void {
        this.leafletRouteLayerService.refreshSelected(layers, selected);
    }

    buildTimelinePointLayer(srcPoint: ItineraryPoint): ItineraryPointLayer {
        return this.leafletPointService.buildTimelinePointLayer(srcPoint);
    }
}
