import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { docsState } from './docs.route';
import { PdfviewerModule } from 'app/docs/pdfviewer/pdfviewer.module';
import { HtmlViewerModule } from 'app/docs/html-viewer/html-viewer.module';
import { PdfviewerService } from 'app/docs/pdfviewer/pdfviewer.service';
import {GalleryPdfViewerModule} from 'app/docs/gallery-pdf-viewer/gallery-pdf-viewer.module';

@NgModule({
    imports: [RouterModule.forChild(docsState),
        PdfviewerModule,
        HtmlViewerModule,
        GalleryPdfViewerModule
    ],
    exports: [],
    providers: [
        PdfviewerService,
        // { provide: JhiLanguageService, useClass: JhiLanguageService }
        ],
    declarations: [],
})
export class DocsModule {
    // constructor(private languageService: JhiLanguageService, private languageHelper: JhiLanguageHelper) {
    //     this.languageService..subscribe((languageKey: string) => {
    //         if (languageKey !== undefined) {
    //             this.languageService.changeLanguage(languageKey);
    //         }
    //     });
    // }
}
