import * as React from 'react';
import { rce } from 'app/utils/react-utils';
import { Select } from 'baseui/select';
import { isArrayEmpty } from 'app/utils/global.helper';
import { ISelectOption } from './select-option.interface';

interface ISelectProps {
    options: ISelectOption[];
    containerStyle?: any;
    valueStyle?: any;
    selected?: ISelectOption;
    onSelect?: (selected: ISelectOption, options?: ISelectOption[]) => void;
}

interface ISelectState {
    selected: ISelectOption[];
    options: ISelectOption[];
}

const defaultStyle = ({$theme}) => ({
    outline: `${$theme.colors.backgroundPrimary} solid`,
    borderTopWidth: 0, borderRightWidth: 0, borderBottomWidth: 0, borderLeftWidth: 0,
    backgroundColor: $theme.colors.backgroundPrimary
});

const defaultValueStyle = ({$theme}) => ({});

export class SelectComponent extends React.Component<ISelectProps, ISelectState> {

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            options: []
        };
    }

    componentDidMount(): void {
        this.initOptions(this.props.options);
    }

    componentDidUpdate(previousProps: ISelectProps): void {
        // Update when user changes current Area
        if (this.props.selected &&
            (!previousProps.selected || (previousProps.selected && previousProps.selected.id !== this.props.selected.id))) {
            this.setState({ selected: [this.props.selected] });
        }
    }

    render() {
        const { selected, options } = this.state;

        return rce(Select, {
            options,
            labelKey: 'label',
            valueKey: 'id',
            value: selected,
            clearable: false,
            searchable: false,
            maxDropdownHeight: '150px',
            onChange: newSelected => this.onSelect(newSelected),
            overrides: {
                ControlContainer: {
                    style: this.props.containerStyle || defaultStyle
                },
                ValueContainer: {
                    style: this.props.valueStyle || defaultValueStyle
                }
            }
        });
    }

    private initOptions(newOptions: ISelectOption[]): ISelectOption[] {
        if (isArrayEmpty(newOptions)) {
            return [];
        }
        const options = newOptions
            .filter(Boolean)
            .map((opt: ISelectOption) => ({ id: opt.id, label: opt.label }));
        const selected = this.props.selected || (isArrayEmpty(options) ? null : options[options.length - 1]);
        this.setState({ options, selected: [selected] });
    }

    private onSelect(param) {
        const { onSelect } = this.props;
        if (onSelect && param.option) {
            onSelect(param.option, this.state.options);
        }
    }
}
