import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DemoName } from 'app/demos/demo-name';

@Injectable()
export class DemoService {

    readonly DISABLED_DEMO_URL = 'api/disabledDemoPages';

    constructor(private readonly httpClient: HttpClient) {
    }

    getDisabledDemo(): Observable<DemoName[]> {
        return this.httpClient.get<DemoName[]>(this.DISABLED_DEMO_URL);
    }
}
