
export class GlobalRoutingData {
    constructor(
                public departure = DEP_ARRIV_DATES.NOW,
                public arrival = '',
                public enableTraffic = true,
                public enableWeather = false,
                public enableWaypoints = true,
                public tollRoadExcluded = false,
                public transitAround = true) {
    }
}

export enum DEP_ARRIV_DATES {
    NOW = 'now'
}
