import { isNil } from 'lodash';
import { Injectable } from '@angular/core';

import { icon, Icon } from 'leaflet';

import { getPBFColorFromGradient, intermediateGradientValue } from '../../../../utils/color-utils';
import { PbfCounters } from './pbf-counters';

@Injectable()
export class PbfHelperService {

    icon: Icon;

    /**
     * Return an object containing style attributes
     * Warning index filters out security attributes and creates corresponding icon
     * Codes : 1: Adherence, 2: Acceleration, 3: Deceleration, 0 || nil: All types
     *
     * @param warningIndex identifies the type of warning, 0 or not defined means all types
     * @param doubleSize double icon size if true
     * @return {{road: road}}
     */

    getSafetyPbfStyle(warningIndex: number,
                      minValue?: number,
                      maxValue?: number,
                      doubleSize?: boolean,
                      counters?: PbfCounters): any {

        return {
            road: (properties, zoom, args) => {
                counters.totalCounter++;
                const secu = this.checkSecurityValues(properties, warningIndex, minValue, maxValue);
                if (!secu) {
                    return {
                        weight: 0
                    };
                }

                const p = properties.WarningType;
                const v = properties.Value;
                counters.filteredCounter++;
                return {
                    stroke: true,
                    weight: 3,
                    icon: this.getIcon(p, v, zoom, doubleSize)
                };
            }
        };
    }

    changeSafetyLayer(layer: any, doubleSize?: boolean, criterionId?: number, minValue?: number, maxValue?: number, counter?: PbfCounters): any {
        layer.options.vectorTileLayerStyles = this.getSafetyPbfStyle(criterionId, minValue, maxValue, doubleSize, counter);
        return layer;
    }

    getPollutantPbfStyle(propertyName: string, valueMin: number, valueMax: number, counters?: PbfCounters): any {
        return {
            road(properties, zoom) {
                counters.totalCounter++;
                const p = properties[propertyName];
                let outOfRange = false;
                if (!isNil(valueMin) && !isNil(valueMax)) {
                    outOfRange = p < valueMin || p > valueMax;
                }
                if (!outOfRange) {
                    counters.filteredCounter++;
                }
                return {
                    stroke: !outOfRange,
                    color: outOfRange ? 'transparent' : getPBFColorFromGradient(p, intermediateGradientValue(valueMin, valueMax), ['green', 'yellow', 'orange', 'red']),
                    weight: outOfRange ? 0 : 3
                };
            }
        };
    }

    changePollutionLayer(propertyName: string, layer: any, altColor?: boolean, valueMin?: number, valueMax?: number, gradientRange?: number[], counters?: PbfCounters): any {
        layer.options.vectorTileLayerStyles = this.getPollutantPbfStyle(propertyName, valueMin, valueMax, counters);
        return layer;
    }

    getBikePbfStyle(propertyName: string, valueMin?: number, valueMax?: number, counters?: PbfCounters): any {
        return {
            road(properties, zoom) {
                counters.totalCounter++;
                const p = properties[propertyName];
                let outOfRange = false;
                if (isNil(p)) {
                    outOfRange = true;
                } else if (!isNil(valueMin) && !isNil(valueMax)) {
                    outOfRange = p < valueMin || p > valueMax;
                }
                if (!outOfRange) {
                    counters.filteredCounter++;
                }
                return {
                    stroke: !outOfRange,
                    color: outOfRange ? 'transparent' : getPBFColorFromGradient(p, intermediateGradientValue(valueMin, valueMax), ['green', 'yellow', 'orange', 'red']),
                    weight: 3
                };
            }
        };
    }

    changeBikeLayer(propertyName: string, layer, alterLayer, valueMin, valueMax, counters?: PbfCounters): any {
        layer.options.vectorTileLayerStyles = this.getBikePbfStyle(propertyName, valueMin, valueMax, counters);
        return layer;
    }

    changeHereLayer(propertyName: string, layer: any, altColor, valueMin: number, valueMax: number, gradientRange, counters?: PbfCounters): any {
        layer.options.vectorTileLayerStyles = {
            road(properties, zoom) {
                counters.totalCounter++;
                const p = properties[propertyName];
                let outOfRange = false;
                if (isNil(p)) {
                    outOfRange = true;
                } else if (!isNil(valueMin) && !isNil(valueMax)) {
                    outOfRange = p < valueMin || p > valueMax;
                }
                if (!outOfRange) {
                    counters.filteredCounter++;
                }

                return {
                    stroke: !outOfRange,
                    color: outOfRange ? 'transparent' : getPBFColorFromGradient(p, intermediateGradientValue(valueMin, valueMax), ['green', 'yellow', 'orange', 'red']),
                    weight: outOfRange ? 0 : 3
                };
            }
        };
        return layer;
    }

    /**
     * Returns true if values are defined and within the given range, else false
     *
     * skip warningType filtering if securityIndex is not defined
     * Skip range values test if minValue or maxValue are not defined
     * @param properties
     * @param securityIndex
     * @param minValue min value for the requested range
     * @param maxValue max value for the requested range
     * @return {boolean}
     */
    checkSecurityValues(properties, securityIndex?: number, minValue?: number, maxValue?: number): boolean {

        if (properties === null) {
            return false;
        }
        return this.checkWarningType(properties.WarningType, securityIndex) && this.checkRangeValues(properties.Value, minValue, maxValue);
    }

    /**
     * Check if geoJSON object warning type value matches with selected warning type
     *
     * Always return true if security index equals 0 or is not defined (=> filter is off, take all values in the range)
     *
     * @param value value of current geoJSON object
     * @param securityIndex the type of security warning objects we want
     * @return {boolean} true if securityIndex equals 0 or is not defined (skip filtering) or if values match
     */
    checkWarningType(value, securityIndex): boolean {
        return isNil(securityIndex) || securityIndex === 0 || value === securityIndex;
    }

    checkRangeValues(value, minValue, maxValue): boolean {
        return isNil(minValue) || isNil(maxValue) || (value >= minValue && value <= maxValue);
    }

    getIcon(p, v, zoom, doubleSize): Icon {
        const factor = doubleSize ? 2 : 1;
        let size;
        let iconUrl;
        if (p === 1) {
            if (v > 0.3) {
                size = [40 * factor, 40 * factor];
                iconUrl = 'content/images/icons/maps/adherence_red.png';
            } else {
                size = [20 * factor, 20 * factor];
                iconUrl = 'content/images/icons/maps/adherence_orange.png';
            }
        } else if (p === 2) {
            if (v > 2.5) {
                size = [40 * factor, 40 * factor];
                iconUrl = 'content/images/icons/maps/accel_red.png';
            } else {
                size = [20 * factor, 20 * factor];
                iconUrl = 'content/images/icons/maps/accel_orange.png';
            }
        } else {
            if (v < -2.4) {
                size = [40 * factor, 40 * factor];
                iconUrl = 'content/images/icons/maps/brake_red.png';
            } else {
                size = [20 * factor, 20 * factor];
                iconUrl = 'content/images/icons/maps/brake_orange.png';
            }
        }
        if (zoom > 13) {
            size = [60, 60];
        }
        const myIcon = icon({
            iconUrl,
            iconSize: size,
            iconAnchor: [size[0] / 2, size[0]]
        });
        return myIcon;

    }
}
