export function formatDecimal(value, decimal = 2) {
    const decimals = Math.pow(10, decimal);
    return Math.round(value * decimals) / decimals;
}

/**
 * Insert white spaces every 10³
 * example: 1257856 -> '1 257 856'
 *
 * Returns the formatted string, empty string if null or undefined
 *
 * @param x the number to format
 */
export function formatIntegers(x: number): string {
    if (x !== 0 && !x) {
        return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
