import * as React from 'react';
import { rce } from 'app/utils/react-utils';
import { Panel, StatelessAccordion } from 'baseui/accordion';

import styled from 'styled-components';
import { SidebarGroupComponent } from './sidebar-group.react.component';
import { isArrayEmpty } from '../../../../utils/global.helper';
import { IMenuGroup } from './class/menu-group.interface';
import { IMenuOption } from './class/menu-option.interface';
import { HttpClient } from '@angular/common/http';
import { ISidebarUIState } from './sidebar-ui-state.interface';

const SidebarSettingsMainContainer = styled.div`
    user-select: none;
`;

interface ISidebarSettingsProps {
    groups: IMenuGroup[];
    getValueFromPath: (path: string) => any;
    updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void;
    uiState: ISidebarUIState;
    httpClient: HttpClient;
}

interface ISidebarSettingsState {
    options: IMenuOption[];
    expanded: string[];
    disabled: boolean;
}

export class SidebarSettingsComponent extends React.Component<ISidebarSettingsProps, ISidebarSettingsState> {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            expanded: [],
            disabled: false
        };

        this.changeOpenPanel = this.changeOpenPanel.bind(this);
    }

    render() {
        const { groups } = this.props;

        const { expanded, disabled } = this.state;
        const children = this.renderPanels(groups);
        return rce(SidebarSettingsMainContainer, {
                key: 'SidebarSettingsMainContainer'
            }, rce(StatelessAccordion,
            {
                key: 'settings-accordion',
                expanded,
                onChange: this.changeOpenPanel,
                disabled,
                children
            })
        );
    }

    renderPanels(groups: IMenuGroup[]): React.ReactNode[] {
        if (isArrayEmpty(groups)) {
            return [];
        }
        return groups.map((group: IMenuGroup) => this.buildPanel(group));
    }

    private buildPanel(menuGroup: IMenuGroup): React.ReactNode {
        const paddingValue = '0 !important';
        // Base ui doesnt provide overrides for the panel container, which comes with an awkwardly high padding, workaround
        const forcePadding = {
            paddingTop: paddingValue,
            paddingRight: paddingValue,
            paddingBottom: paddingValue,
            paddingLeft: paddingValue,
        };
        const { getValueFromPath, updateModel, uiState, httpClient } = this.props;
        const { label: title } = menuGroup;

        return rce(Panel, {
            key: title,
            title,
            children: [rce(SidebarGroupComponent, { key: 'panel-child' + title, getValueFromPath, updateModel, uiState, menuGroup, httpClient })],
            overrides: {
                Content: {
                    // Apply only on the open panel
                    style: () => this.state.expanded.includes(title) ? forcePadding : {}
                }
            }
        });
    }

    changeOpenPanel(panel): void {
        // Stateless accordion, needs to provide state storage
        const { expanded } = panel;
        this.setState({
            expanded
        });
    }
}
