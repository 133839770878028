import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { BarAction } from 'app/left-sidebar/bar-actions/bar-action';

@Injectable()
export class LeftSidebarSharedService {

    private barAction = new BehaviorSubject<any>(BarAction.ACTION_EXPAND);

    constructor() {
    }

    onBarAction(): BehaviorSubject<BarAction> {
        return this.barAction;
    }

    getLastBarAction(): BarAction {
        return this.barAction.getValue();
    }

    getNextBarAction(): BarAction {
        return  this.getLastBarAction() === BarAction.ACTION_EXPAND ?
            BarAction.ACTION_COLLAPSE : BarAction.ACTION_EXPAND;
    }

    nextBarAction(action?: BarAction): void {
        const newAction = action || this.getNextBarAction();
        this.barAction.next(newAction);
    }
}
