import {find, isNil, some, split} from 'lodash';
import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {ItineraryImgUrl, ItineraryPoint} from '../../class/itinerary-point';
import {ItineraryPointLayer} from '../../class/itinerary-point-layer';
import {isArrayEmpty} from '../../../utils/global.helper';
import {ItineraryService} from './itinerary.service';
import {TranslateService} from '@ngx-translate/core';
import {ITI_TIME_TRANSLATE_KEYS} from 'app/global/enum/itinerary-time-keys';
import {ISelectOption} from 'app/global/interface/select-option';

@Injectable()
export class ItineraryHelperService {

    constructor(private readonly  translateService: TranslateService) {

    }

    /**
     * Return the right image url based on point form group and its position in the list
     *
     * @param {FormGroup} pointGroup
     * @return {ItineraryImgUrl}
     */
    getImageUrl(pointGroup: FormGroup, points: FormArray): ItineraryImgUrl {
        let imgUrl = ItineraryImgUrl.INTERMEDIATE;
        if (pointGroup === points.at(0)) {
            // It's the first point
            imgUrl = ItineraryImgUrl.START;
        } else if (pointGroup === points.at(points.length - 1)) {
            // It's the last point
            imgUrl = ItineraryImgUrl.STOP;
        }
        return imgUrl;
    }

    /**
     * Return the right image url based on an itinerary point and its position in the list
     *
     * @param {ItineraryPoint} point
     * @param {ItineraryPoint[]} pointList
     * @return {ItineraryImgUrl}
     */
    getItineraryPointImageUrl(point: ItineraryPoint, pointList: ItineraryPoint[]): ItineraryImgUrl {
        let imgUrl = ItineraryImgUrl.INTERMEDIATE;
        if (pointList.indexOf(point) === 0) {
            // It's the first point
            imgUrl = ItineraryImgUrl.START;
        } else if (pointList.indexOf(point) === (pointList.length - 1)) {
            // It's the last point
            imgUrl = ItineraryImgUrl.STOP;
        }
        return imgUrl;
    }

    getControlsFromArray(array: FormArray): FormGroup[] {
        if (!array || !array.controls) {
            return null;
        }
        return array.controls as FormGroup[];
    }

    canRemove(points: FormArray): boolean {
        return points.length > 2;
    }

    findPointForm(pointToFind: ItineraryPointLayer, controlArray: FormGroup[]): FormGroup {
        if (!pointToFind || isArrayEmpty(controlArray)) {
            return null;
        }
        return find(controlArray, (pointControl: FormGroup) => !isNil(pointControl) && !isNil(pointControl.getRawValue())
            && pointControl.getRawValue().id === pointToFind.id);
    }

    canAddPoint(formArray: FormArray): boolean {
        const controls: FormGroup[] = this.getControlsFromArray(formArray);
        return controls && controls.length < ItineraryService.MAX_POINTS &&
            !some(controls, (control: FormControl) => !control.get('address').value);
    }

    findIndexInFormArray(targetControl: AbstractControl, formArray: FormArray): number {
        const controls = this.getControlsFromArray(formArray) || [];
        return controls.findIndex(control => control === targetControl);
    }

    getSelectTimeItems(): ISelectOption[] {
        return [
            {value: 'Maintenant', text: this.translateService.instant(ITI_TIME_TRANSLATE_KEYS.ITI_TIME_LEAVE_NOW)},
            {value: 'Partir', text: this.translateService.instant(ITI_TIME_TRANSLATE_KEYS.ITI_TIME_LEAVE)},
            {value: 'Arriver', text: this.translateService.instant(ITI_TIME_TRANSLATE_KEYS.ITI_TIME_ARRIVE)},
        ];
    }

    getSelectTimeItems1() {

    }

    getSimpleDate(date: string) {
        return split(date, '.')[0] + 'Z';
    }
}
