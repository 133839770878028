import { DemoAccess } from 'app/demos/demo-access';
import { DemoName } from 'app/demos/demo-name';

export class DemoInfo extends DemoAccess {

    constructor(name: DemoName, roles: string[] = [], public version?: string) {
        super(name, roles);
    }

}
