import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { GatewayAngularSharedModule } from 'app/shared/shared.module';
import {GalleryPdfViewerRoute} from './gallery-pdf-viewer.route';
import {GalleryPdfViewerComponent} from './gallery-pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
    imports: [
        GatewayAngularSharedModule,
        RouterModule.forChild([GalleryPdfViewerRoute]),
        PdfViewerModule
    ],
    exports: [GalleryPdfViewerComponent],
    declarations: [GalleryPdfViewerComponent],
    entryComponents: [GalleryPdfViewerComponent],
    providers: [],
})
export class GalleryPdfViewerModule {
}
