import { BehaviorSubject, Observable, of } from 'rxjs/index';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DemoInfo } from 'app/demos/demo-info';
import { VersionService } from './version.service';
import { DemoInfos } from 'app/demos/demo.constants';
import { DemoName } from 'app/demos/demo-name';
import { findDemoFromUrl } from 'app/utils/demo-utils';

/**
 * Service gérant le statut de démo courante, et permettant les paramétrages associés
 * (titre dans l'onglet, numéro de version, type de composant à charger...)
 */
@Injectable()
export class CurrentDemoSharedService {
    private currentDemo = new BehaviorSubject<DemoInfo>(undefined);

    constructor(private readonly router: Router,
                private readonly versionService: VersionService) {
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map((e: NavigationEnd) => this.findDemo(e)),
            switchMap(demo => this.updateDemo(demo)))
            .subscribe();
    }

    getCurrentDemoSubject(): BehaviorSubject<DemoInfo> {
        return this.currentDemo;
    }

    getCurrentDemoValue(): DemoInfo {
        return this.currentDemo.getValue();
    }

    setNext(demo: DemoInfo): void {
        this.currentDemo.next(demo);
    }

    private findDemo(navEvent: NavigationEnd): DemoInfo {
        const path = navEvent.urlAfterRedirects;
        const demo: DemoInfo = findDemoFromUrl(path) || DemoInfos.get(DemoName.MAIN);
        if (!demo) {
            throw new Error('Could not create demoType for demo: ' + path);
        }
        return demo;
    }

    private updateDemo(openingDemo: DemoInfo): Observable<DemoInfo> {
        if (!openingDemo) {
            throw new Error('Could not get version for demo: ' + openingDemo);
        }
        return this.versionService.getDemoVersion(openingDemo.name).pipe(
            catchError(error => this.handleError(error)),
            map(version => this.updateCurrentDemo(openingDemo, version)
            ));
    }

    private updateCurrentDemo(demo: DemoInfo, version: string): DemoInfo {
        if (!demo) {
            throw new Error('Could not update demo, demo undefined');
        }
        if (!version) {
            console.warn('Version not found, setting current demo without version, demo : ', demo);
        }
        if (version && version.length < 100) {
            demo.version = version;
        }
        this.setNext(demo);
        return demo;
    }

    private handleError(error): Observable<string> {
        console.error('Could not get version : ', error);
        return of('');
    }
}
