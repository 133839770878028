import * as React from 'react';
import { styled } from 'baseui';
import { BoardConfig } from './board-config';
import { isArrayEmpty } from '../../../../utils/global.helper';
import { ChartItem } from './chart-item';
import { rce } from '../../../../utils/react-utils';
import { ChartBoardItemComponent } from './chart-board-item.react.component';
import { DEFAULT_ECHART_THEME } from './chart-board.constants';
import { Layout, WidthProvider, Responsive, ResponsiveProps, ItemCallback } from 'react-grid-layout';
import { generateChartIndex, buildLayout } from './chart-board.helper';
import { SIDEBAR_WIDTH_TRANSITION_TIME } from '../react-sidebar/sidebar-content.react.component';

const draggableHandleClass = 'grid-item-handle';
const ReactGridLayout = WidthProvider(Responsive);
const rowHeight = 380;

const ChartBoardMainContainer = styled('div', (({ $theme }) => ({
    width: '100%',
    overflowY: 'auto',
    padding: '0 10px',
    color: $theme.colors.primary500
})));

interface IChartBoardProps {
    charts: ChartItem[];
    boardConfig: BoardConfig;
    sidebarOpen: boolean;
}

interface IChartBoardState {
    resizedChart: string; // The id of the chart being resized
}

export class ChartBoardComponent extends React.Component<IChartBoardProps, IChartBoardState> {

    constructor(props) {
        super(props);
        this.state = {
            resizedChart: null
        };
        this.forceChartResize = this.forceChartResize.bind(this);
    }

    componentDidMount(): void {
        // Remettre à jour le GridLayout, en ajoutant un délai pour que la taille du menu de gauche soit initialisée
        this.initGridResize(SIDEBAR_WIDTH_TRANSITION_TIME);
    }

    componentDidUpdate(nextProps: IChartBoardProps): void {
        // Remettre à jour le GridLayout, en ajoutant un délai pour que la taille du menu de gauche soit définie
        if (this.props.sidebarOpen !== nextProps.sidebarOpen) {
            this.initGridResize(SIDEBAR_WIDTH_TRANSITION_TIME);
        }
    }

    render() {
        const { charts, boardConfig } = this.props;
        const props = this.getGridProps(boardConfig, charts);
        const style = this.getStyle(boardConfig);
        const className = this.getClassName(boardConfig);

        return rce(ChartBoardMainContainer, { key: 'Tco2BoardComponentmain', className: 'tco-board' },
            rce(ReactGridLayout, {
                    className,
                    rowHeight,
                    ...props,
                    style,
                    draggableHandle: `.${draggableHandleClass}`,
                    onResizeStop: this.forceChartResize
                },
                this.renderCharts(boardConfig, charts)
            )
        );
    }

    getStyle(boardConfig: BoardConfig) {
        if (boardConfig && boardConfig.rowsNumber === 1) {
            return { height: '100%' };
        }
    }

    getClassName(boardConfig: BoardConfig) {
        let className = 'layout';
        if (boardConfig && boardConfig.rowsNumber === 1) {
            className += ' full-height';
        }
        return className;
    }

    forceChartResize(layout: Layout[], resized: Layout): ItemCallback {
        // Certains graphs (plotly) ne se redimensionnent pas à après un resize, il faut le faire manuellement
        this.setState({ resizedChart: resized.i });
        return;
    }

    private getGridProps(config: BoardConfig, charts): ResponsiveProps {
        const layout: Layout[] = buildLayout(charts);
        const defaultProps: ResponsiveProps = {
            breakpoints: { lg: 1600, md: 1280, sm: 800, xs: 768, xxs: 0 },
            cols: { lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 },
            layouts: { lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }
        };
        if (config && config.columnsNumber) {
            defaultProps.cols = { lg: config.columnsNumber, md: config.columnsNumber, sm: 1, xs: 1, xxs: 1 };
        }
        return defaultProps;
    }

    private initGridResize(delay): void {
        setTimeout(() => window.dispatchEvent(new Event('resize')), delay);
    }

    private renderCharts(boardConfig: BoardConfig, charts: ChartItem[]): React.ReactNode[] {
        // Initialisation de la grille, en cas d'absence de données
        if (isArrayEmpty(charts)) {
            const containers = [];
            const total = boardConfig && boardConfig.getNumberOfCells();
            // On remplit la grille avec le nombre de cellules définies par la configuration
            for (let i = 0; i < total; i++) {
                containers.push(rce(ChartBoardItemComponent, { key: 'chart' + i }));
            }
            // Retour des containers vides
            return containers;
        }
        const handleStyle = {
            position: 'absolute',
            cursor: 'move',
            top: 2, left: 5, width: '40px', height: '40px',
        };
        const dragIcon = '/content/images/icons/drag-drop-icon.png';
        // Sinon les données sont présentes, on poursuit le chargement
        const theme = boardConfig?.theme?.themeName || DEFAULT_ECHART_THEME.themeName;
        // La grille a besoin d'une div pour contenir les éléments, dont chaque clé doit correspondre à l'index défini dans le layout
        return charts
            .map((chart, i: number) => rce('div', { key: generateChartIndex(i) }, [
                    rce('div', { key: 'handle', className: draggableHandleClass, style: handleStyle },
                        rce('img', { src: dragIcon, style: { width: '15px' } })),
                    rce(ChartBoardItemComponent, {
                        key: 'chart-board-item',
                        chartId: generateChartIndex(i),
                        chart,
                        theme,
                        resizedChart: this.state.resizedChart // Informe le composant qu'un resize a eu lieu
                    })
                ]
            ));
    }
}
