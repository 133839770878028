import { NgModule } from '@angular/core';
import { LeafletMapComponent } from './leaflet-map-component/leaflet-map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CommonModule } from '@angular/common';
import { LeafletMapConfigService } from './leaflet-map-component/leaflet-map-config.service';
import { LeafletPointService } from 'app/maps/leaflet-maps/leaflet-map-component/leaflet-point.service';
import { LeafletRouteLayerService } from './leaflet-route-layer.service';
import { LeafletMapService } from './leaflet-map.service';
import { MapControlSharedModule } from '../map-control/map-control.shared.module';
import { PbfHelperService } from '../../demos/community-map-demo/side-menu.component/pbf-layers/pbf-helper';
import { TemporarySignalChartComponent } from '../../demos/bike-demo/temporary-signal-chart/temporary-signal-chart.component';
import { TemporarySignalChartService } from '../../demos/bike-demo/temporary-signal-chart/temporary-signal-chart.service';
import { LeafletMapSharedService } from './leaflet-map.shared.service';
import { LeafletCommunityMapService } from './leaflet-community-map/leaflet-community-map.service';

@NgModule({
    imports: [
        LeafletModule,
        CommonModule,
        MapControlSharedModule
    ],
    declarations: [
        LeafletMapComponent,
        TemporarySignalChartComponent
    ],
    providers: [
        LeafletMapConfigService,
        LeafletMapService,
        LeafletRouteLayerService,
        LeafletPointService,
        PbfHelperService,
        LeafletPointService,
        TemporarySignalChartService,
        LeafletMapSharedService,
        LeafletCommunityMapService
    ],
    bootstrap: [
        LeafletMapComponent
    ],
    exports: [
        LeafletMapComponent
    ]
})
export class LeafletMapsModule {}
