import { get } from 'lodash';

import { Injectable } from '@angular/core';
import { mapGeometryToArray } from '../../utils/leaflet-map.utils';
import { isArrayEmpty } from '../../utils/global.helper';
import { MeteoInterval } from './meteo-interval';
import { LatLngTuple } from 'leaflet';
import { METEO_DEFAULTS } from './meteo-defaults';

@Injectable()
export class MeteoResultService {

    buildMeteoIntervals(rainRisk) {
        // The array containing the point positions
        const rainRiskGeometry = get(rainRisk, 'rainRiskSeries.geometry');
        // The array containing the intensity values, respectively
        const intensityValues: number[] = get(rainRisk, 'rainRiskSeries.rainRiskIntensity');
        if (!rainRiskGeometry || !intensityValues) {
            return null;
        }
        // Transform geometries into coordinates
        const coordinates: LatLngTuple[] = mapGeometryToArray(rainRiskGeometry);
        if (isArrayEmpty(coordinates)) {
            return null;
        }
        const meteoIntervals = [];
        let currentGeometry = this.createNewMeteoInterval(intensityValues[0], coordinates[0]);
        // Initialize counter
        let counter = 1;

        while (counter < coordinates.length) {
            // If sequence is identical, and is not the last value yet
            if (intensityValues[counter] === intensityValues[counter - 1] && counter !== coordinates.length - 1) {
                // Add coordinates value to the coordinates sequence of current interval
                currentGeometry.latlngSequence.push(coordinates[counter]);
            } else {
                // Else sequence of same color has ended, either because we found a new color or we reached last element
                if (currentGeometry.latlngSequence.length > 2) {
                    // There must be at least a 2 elements to constitute a route layer, if that's the case add to the sequence
                    meteoIntervals.push(currentGeometry);
                    // Start a new interval
                    currentGeometry = this.createNewMeteoInterval(intensityValues[counter], coordinates[counter]);
                }
            }
            counter++;
        }
        return meteoIntervals;
    }

    getRGBFromIntensity(intensityValue: number): string {
        if (intensityValue < 0 || intensityValue >= METEO_DEFAULTS.rainProperties.length) {
            return null;
        }
        // Find the color corresponding to the given intensity value
        const meteoColor = METEO_DEFAULTS.rainProperties[intensityValue].color;
        // Extract the RGV values
        return meteoColor;
    }

    private createNewMeteoInterval(intensityValue: number, coordinates: LatLngTuple): MeteoInterval {
        const rGBColorValues = this.getRGBFromIntensity(intensityValue);
        // We need to wrap coordinates into an array that will be filled with the coordinates sequence
        const coordinatesSequence = [coordinates];
        return new MeteoInterval(rGBColorValues, coordinatesSequence);
    }
}
