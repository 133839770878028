import { isArrayEmpty, isNil, isObject } from '../../../../utils/global.helper';
import { IMenuConfiguration } from './class/menu-configuration.interface';
import { IMenuGroup } from './class/menu-group.interface';
import { IMenuOption } from './class/menu-option.interface';
import { InputType } from './class/input-type';
import { ISelectOption } from '../react-select/select-option.interface';
import { MenuSelectOption } from './class/menu-select-option';
import { getLastItemInArray } from '../../../../utils/collection-utils';
import { MODEL_TARGET_PATH_SEPARATOR } from './sidebar.constant';
import { MapCoordinates } from '../../../class/map-coordinates';

/**
 * Génère le modèle pour le webservice à partir de la configuration du menu de gauche
 *
 * @param menuConfiguration la configuration du menu de gauche
 * @param separator le séparateur utilisé pour délimiter les sections du chemin vers chaque input (/)
 */
export const generateModel = (menuConfiguration: IMenuConfiguration, separator = MODEL_TARGET_PATH_SEPARATOR): { [key: string]: any } => {
    if (!menuConfiguration || isArrayEmpty(menuConfiguration.groups)) {
        return null;
    }
    const groups: IMenuGroup[] = getMenuGroups(menuConfiguration);
    // On boucle sur chaque groupe de menu (= sections de l'accordéon)
    return isArrayEmpty(groups) ? null : groups
        .reduce((memo: { [key: string]: any }, value: IMenuGroup) =>
            // Et on génère le modèle à partir de chaque input présent dans le groupe
            value.options.reduce((m: { [key: string]: any } = {}, v: IMenuOption) => generateModelFromInput(m, v, separator), memo), {});
};

const getMenuGroups = (menuConfiguration: IMenuConfiguration): IMenuGroup[] => {
    if (!menuConfiguration || isArrayEmpty(menuConfiguration.groups)) {
        return [];
    }
    // Tableau pour vérifier l'unicité des chemins des inputs
    const targetPathCheck: string[] = [];
    // Filtre les éléments non valides
    return menuConfiguration.groups.filter(g => !isArrayEmpty(g?.options?.filter(o => {
        const target = o?.targetPath?.trim();
        if (!target) {
            console.warn('Problème de configuration du board: Input incomplet, target path manquant, cet élément ne pourra pas être pris en compte: ', o);
            return false;
        }
        if (targetPathCheck.includes(target)) {
            console.warn('Problème de configuration du board: Doublon de chemin d\'input trouvé, veuillez vérifier la configuration: ', o);
            console.warn('Attribut targetPath doit être unique: ', target);
        } else {
            targetPathCheck.push(target);
        }
        return !!o;
    })));
};

/**
 * Construit le modèle pour l'appel au web service à partir de l'état du modèle courant et des infos de l'input donné
 *
 * Le modèle peut être soit vide si c'est le premier input traité,
 * soit ne contient pas l'object associé à l'input donné, dans quel cas un nouvel attribut sera ajouté au model
 *
 * L'identification de l'objet concerné dans le modèle se fait grâce à la variable targetPath du menuOption
 *
 * @param m le modèle qui servira à l'appel au webservice
 * @param input les informations de l'input
 * @param separator le séparateur utilisé pour délimiter les sections du chemin vers l'input (/)
 */
export const generateModelFromInput = (m: { [key: string]: any } = {}, input: IMenuOption, separator = MODEL_TARGET_PATH_SEPARATOR): { [key: string]: any } => {
    // input.targetPath correspond au chemin pour atteindre l'attribut ciblé: ex: foo/bar => { foo: { bar: 'valeur' } }
    if (!input || !input.targetPath || !input.targetPath.trim()) {
        return m;
    }
    // On récupère tous les niveaux de l'arborescence vers l'objet à atteindre
    const attributeNames = input.targetPath.split(separator).filter(Boolean);

    let i = 0; // Compteur pour itérer sur tous chaque niveau de l'arborescence du modèle
    let currentParent = m; // Valeur utile courante
    let valueToAdd; // La valeur à ajouter à l'attribut une fois la cible identifiée

    // On boucle sur la chaine d'attributs, on met à jour le modèle, et on renseigne la valeur lorsqu'on arrive au dernier tronçon
    while (i < attributeNames.length) {
        // Nom de l'attribut courant
        const currentAttributeName = attributeNames[i];
        // Si c'est le dernier fragment du chemin, définir la valeur à attribuer à l'élément final de l'arborescence
        if (i === (attributeNames.length - 1)) {
            valueToAdd = getDefaultValue(input);
        }
        // On récupère ou crée si encore inexistant le parent correspondant au fragment courant dans le modèle
        currentParent = createParent(currentParent, currentAttributeName, valueToAdd);

        i++;
    }
    return m;
};

const createParent = (obj: any = {}, attribute: string, value: any): any => {
    if (!obj.hasOwnProperty(attribute)) {
        // L'attribut n'existe pas, il faut le créer dans le parent
        obj[attribute] = {};
    }
    if (value !== undefined) {
        // Si la valeur est définie, il s'agit du dernier enfant; on lui affecte la valeur
        obj[attribute] = value;
    }
    // on retourne le parent modifié
    return obj[attribute];
};

const getDefaultValue = (option: IMenuOption): string | number | string[] | number[] | MapCoordinates | null => {
    if (!option) {
        console.warn('Sidebar configuration default value: input option is not defined');
        return null;
    }
    if (option.defaultValue) {
        return option.defaultValue;
    }
    let value: string | number | string[] | number[] | MapCoordinates | null;
    switch (option.inputType) {
        case InputType.NUMBER:
            value = 0;
            break;
        case InputType.TEXT:
            value = '';
            break;
        case InputType.SELECT:
            value = isArrayEmpty(option.selectValues) ? null : option.selectValues[0].propertyValue;
            break;
        case InputType.TABLE:
            if (!Array.isArray(option.defaultValue) || isArrayEmpty(option.defaultValue)) {
                console.warn('Sidebar configuration invalid: input table defaultValue is not a valid array');
                return null;
            }
            value = option.defaultValue;
            break;
        case InputType.ADDRESS:
            value = new MapCoordinates(null, null);
            break;
        default:
            value = null;
    }
    return value;
};

export const updateModelValue = (current: { [key: string]: any },
                                 value: any,
                                 targetPath: string,
                                 separator = MODEL_TARGET_PATH_SEPARATOR): { [key: string]: any } => {
    const lastPathStep = findLastStep(targetPath, separator);
    const parentElement = findParent(current, targetPath, separator);
    if (!parentElement || !lastPathStep) {
        return current;
    }
    if (!parentElement.hasOwnProperty(lastPathStep)) {
        throw Error('Could not update model');
    }
    parentElement[lastPathStep] = value;
    return { ...current };
};

/**
 * Retourne la valeur de la cible identifiée par le chemin targetPath dans le modèle
 *
 * Format du chemin: a/b/c..
 * Par exemple, si le modèle est { a: { b: { c: 5, d: 6 } } } et le chemin a/b/c, la valeur retournée sera 5
 *
 * @param model l'objet associé au modèle
 * @param targetPath le chemin de la valeur cible dans le modèle
 * @param separator chaîne de caractères pour la séparation des niveaux dans le chemin
 */
export const findValue = (model: { [key: string]: any }, targetPath: string, separator: string = MODEL_TARGET_PATH_SEPARATOR): any => {
    const parent = findParent(model, targetPath, separator);
    const lastPathStep = findLastStep(targetPath, separator);

    return (parent && lastPathStep) ? parent[lastPathStep] : null;
};

/**
 * Retourne la référence du parent de l'objet correspondant au chemin donné
 *
 * Format du chemin: a/b/c..
 * Si le modèle est { a: { b: { c: 5, d: 6 } } }, le parent est { c: 5, d: 6 }
 *
 * renvoie null si le parent n'a pas été trouvé
 *
 * @param model L'objet global contenant la cible
 * @param targetPath le chemin de la cible dans l'objet parent
 * @param separator chaîne de caractères pour la séparation des niveaux dans le chemin (/)
 */
export const findParent = (model: { [key: string]: any }, targetPath: string, separator = MODEL_TARGET_PATH_SEPARATOR): { [key: string]: any } => {
    if (isNil(model) || !targetPath || !targetPath.trim()) {
        return null;
    }
    // Sépare chaque degré du chemin
    const pathSteps = targetPath.trim().split(separator).filter(Boolean);
    // Si le chemin n'a qu'un niveau, le parent est le modèle lui-même
    if (pathSteps.length <= 1) {
        return model;
    }
    // Cheminement dans le modèle, qu'on parcourt en suivant chaque niveau du chemin
    let depth = 0; // Le compteur pour suivre la profondeur du cheminement dans le modèle
    let currentStep = pathSteps[0]; // La valeur de la portion du chemin en cours
    let currentTarget = model[currentStep]; // le sous objet extrait correspondant à la portion du chemin en cours
    while (isObject(currentTarget) && depth < pathSteps.length) {
        depth++;
        // On veut le parent de la cible, donc...
        if (depth < pathSteps.length - 1) {
            // Tant qu'on n'est pas arrivé à l'avant dernier niveau, on écrase la valeur précédente
            currentStep = pathSteps[depth];
            currentTarget = currentTarget[currentStep];
        }
    }
    // Si l'itération s'est déroulée jusqu'au dernier élément du chemin, le parent a été identifié, sinon renvoie null
    return (depth === (pathSteps.length)) ? currentTarget : null;
};

export const findSelectedOption = (value: string, options: MenuSelectOption[]): ISelectOption | null => {
    if (isArrayEmpty(options)) {
        return null;
    }
    return !value ? options[0] : options.find(o => o.propertyValue === value);
};

export const findOptionById = (id: string, options: MenuSelectOption[]): MenuSelectOption | null => {
    if (isArrayEmpty(options)) {
        return null;
    }
    return options.find(o => o.id === id);
};

export const getSelectedValue = (option: MenuSelectOption): string => option?.propertyValue;

/**
 * Retourne le dernier niveau d'un chemin
 * ex: si le chemin est 'a/b/c', le dernier niveau sera 'c'
 *
 * Ignore les caractères vides en début et fin du chemin donné.
 *
 * @param targetPath le chemin pour identifier une valeur dans le modèle
 * @param separator chaîne de caractères pour la séparation des niveaux dans le chemin
 */
const findLastStep = (targetPath: string, separator = MODEL_TARGET_PATH_SEPARATOR): string => targetPath && getLastItemInArray(targetPath.trim().split(separator));
