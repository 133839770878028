import { Component, OnInit } from '@angular/core';
import { PdfviewerService } from 'app/docs/pdfviewer/pdfviewer.service';
import { Router } from '@angular/router';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
    selector: 'dc-pdfviewer',
    templateUrl: './pdfviewer.component.html',
    styleUrls: ['./pdfviewer.component.scss'],
    providers: [NgxExtendedPdfViewerModule]
})
export class PdfviewerComponent implements OnInit {
	pdfSrc = 'default';
    public pdfdocument = '';

    constructor(private readonly pdfviewerService: PdfviewerService,
                private readonly router: Router)   {
    }

    ngOnInit(): void {
        this.pdfdocument = this.router.url.replace('/docs/pdf/', 'files/documentation/');

        this.pdfviewerService.getWebContent(this.pdfdocument).subscribe( value => {
            const binaryData = new Blob([value], {type : 'application/pdf'});
            const objectUrl = window.URL.createObjectURL(binaryData);
            this.pdfSrc = objectUrl;
            return true;
            }, error => {
            console.error('An error occurred: ', error);
            return false;
            }
        );
    }
}
