import { HttpParams } from '@angular/common/http';
import { HttpMethod } from '../../enum/http-method';

export class WsDefinition {
    private readonly params: HttpParams;

    constructor(private url: string,
                private method: HttpMethod = HttpMethod.GET,
                httpParams?: { [param: string]: string | string[] }) {
        if (httpParams) {
            this.params = new HttpParams({ fromObject: httpParams });
        }
    }

    hasUrl(): boolean {
        return !!this.url;
    }

    getURL(baseUrl?: string): string {
        return `${ baseUrl }/${ this.url }`;
    }

    getMethod(): HttpMethod {
        return this.method;
    }

    getParams(): HttpParams {
        return this.params;
    }
}
