<div class="modal-header">
    <h4 class="modal-title" jhiTranslate="modal.cgu.title">cguTitle</h4>
</div>
<div class="modal-body">
    <div [innerHTML]="cgu?.content"></div>
</div>

<div class="modal-footer-cgu">
    <div class="checkbox d-flex align-items-center justify-content-between">
        <label for="cgu">
            <input type="checkbox" id="cgu" name="cgu" [(ngModel)]="checked" (ngModelChange)="setCheckState($event)">
            <span>I accept the general condition</span>
        </label>

    </div>
    <br>
    <div class="btn-footer">
        <button type="button" class="btn btn-secondary" aria-label="cancel" (click)="cancel()">Cancel
        </button>
        <button type="button" class="btn btn-primary" aria-label="agree" [disabled]="!checked" (click)="agree()"> Accept
        </button>
    </div>

</div>
