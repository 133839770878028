import { Observable } from 'rxjs';

import { IImageContainer } from 'app/global/interface/image-container.interface';

export class FileUtils {

    static loadImage<T extends IImageContainer>(container: T, image: Blob): Observable<T> {
        const reader = new FileReader();
        const fileReaderObs = new Observable<T>(observer => {
            reader.addEventListener('load', () => {
                container.image = reader.result;
                observer.next(container);
                observer.complete();
            }, false);
            if (image) {
                reader.readAsDataURL(image);
            }
        });
        return fileReaderObs;
    }

    static resizePng(dataURI: string, width: number, height: number): Promise<string> {
        if (!dataURI) {
            throw new Error('Could not resize image, no image data');
        }
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.src = dataURI;
            img.onload = () => {
                const resizedDataURI = FileUtils.imageToDataUri(img, width, height);
                resolve(resizedDataURI);
            };
        });
    }

    static imageToDataUri(img, width: number, height: number): string {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        const context = canvas.getContext('2d');
        if (!context) {
            throw new Error('Could not getDataUri from canvas, context not ready');
        }
        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL();
    }

    static htmlToDataURL(blob: Blob): string {
        if (!blob) {
            throw Error('Can not transform file to object url, file parts not found');
        }
        return window.URL.createObjectURL(blob);
    }

    static getFileExtension(fileName: string): string | null {
        if (!fileName || !fileName.trim().length) {
            return null;
        }
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    }

    static hasExtension(fileName: string, extension: string): boolean {
        const fileExtension: string = FileUtils.getFileExtension(fileName);
        return !fileExtension || fileExtension !== extension;
    }
}
