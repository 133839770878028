import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'dc-warning-modal',
    styleUrls: ['./modals.scss'],
    template: `
        <!-- Modal header -->
        <div class="modal-header warning-modal-header">
            <div class="modal-header-title" id="modal-title" jhiTranslate="modal.warning.title"></div>
        </div>
        <!-- Modal body -->
        <div class="modal-body modal-header-body">
            <span>{{warningText}}</span>
        </div>
        <div class="modal-footer warning-modal-footer">
            <button class="btn btn-danger btn-warning-modal" type="button" (click)="bsModalRef.hide()">OK</button>
        </div>
    `
})
export class WarningModalComponent {
    @Input() warningText: string;

    constructor(public readonly bsModalRef: BsModalRef) {
    }
}
