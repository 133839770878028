import { Observable } from 'rxjs';

import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GalleryService {

    constructor(private readonly httpClient: HttpClient) {
    }

}
