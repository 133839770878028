import { Injectable } from '@angular/core';
import { CurrentDemoSharedService } from 'app/global/service/current-demo-shared.service';
import { DemoInfo } from '../demos/demo-info';
import { IMapService } from './map-service.interface';
import { LeafletMapService } from './leaflet-maps/leaflet-map.service';
import { PolylineGeometry } from '../demos/bike-demo/bike-route-results';
import { GeoJsonObject } from 'geojson';
import { GeoJSON } from 'leaflet';
import { ItineraryPointLayer } from 'app/global/class/itinerary-point-layer';
import { ItineraryPoint } from 'app/global/class/itinerary-point';
import { ExtendedLayer } from './leaflet-maps/extended-layer';
import { DemoName } from 'app/demos/demo-name';

@Injectable()
export class MapFacadeService {

    currentMapService: IMapService;

    constructor(private readonly currentDemoSharedService: CurrentDemoSharedService,
                private readonly leafletMapService: LeafletMapService) {
        currentDemoSharedService.getCurrentDemoSubject().subscribe(demoType => this.setDemoService(demoType));
    }

    buildLayersFromRawResult(geometry: PolylineGeometry): any {
        if (!geometry) {
            return null;
        }
        if (!this.currentMapService) {
            this.currentMapService = this.leafletMapService;
        }
        return this.currentMapService.buildLayersFromRawResult(geometry);
    }

    buildMeteoGeoJSON(geoJSON: GeoJsonObject): GeoJSON {
        return this.currentMapService.buildMeteoGeoJSON(geoJSON);
    }

    buildAirQualityGeoJSON(geoJSON: GeoJsonObject): GeoJSON {
        return this.currentMapService.buildAirQualityGeoJSON(geoJSON);
    }

    refreshSelected(layers: ExtendedLayer[], selected: ExtendedLayer): void {
        this.currentMapService.refreshSelected(layers, selected);
    }

    buildTimelinePointLayer(srcPoint: ItineraryPoint): ItineraryPointLayer {
        return this.leafletMapService.buildTimelinePointLayer(srcPoint);
    }

    private setDemoService(demoType: DemoInfo): void {
        if (!demoType || !demoType.name) {
            return null;
        }
        switch (demoType.name) {
            case DemoName.BIKE:
                this.currentMapService = this.leafletMapService;
                break;
            default:
                this.currentMapService = this.leafletMapService;
        }
    }
}
