import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import * as React from 'react';
import { rce } from 'app/utils/react-utils';
import { GeocodingBaseService } from './geocoding-base.service';
import { HttpClient } from '@angular/common/http';
import { MapCoordinates } from '../../../class/map-coordinates';
import { Select, TYPE } from 'baseui/select';
import { isArrayEmpty, unsubscribe } from '../../../../utils/global.helper';
import { SearchSelectedItem } from './search-selected-item';
import { ISelectable } from 'app/global/component/react/react-map-demo/bd-sidebar/bd-select-area/selectable';
import { GeocodingSuggestion } from 'app/global/class/geocoding/geocoding-suggestion';

interface IReactSearchAddressProps {
    onSelect: (selected: SearchSelectedItem) => void;
    httpClient: HttpClient;
    initialValue: SearchSelectedItem;
}

interface IReactSearchAddressState {
    options: ISelectable[];
    selected: ISelectable[];
}

export class ReactSearchAddressComponent extends React.Component<IReactSearchAddressProps, IReactSearchAddressState> {

    popoverAnchorRef;
    nextValue: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    subs: Subscription[] = [];

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: []
        };
        this.popoverAnchorRef = React.createRef();
        this.subs.push(this.nextValue.pipe(
            filter(v => v !== undefined),
            debounceTime(300),
            distinctUntilChanged()
            ).subscribe((value => this.autoComplete(value)))
        );
    }

    componentDidMount(): void {
        const initialValues = this.props.initialValue && this.props.initialValue.item;
        if (initialValues && initialValues.length) {
            this.setState(state => ({
                ...state,
                options: [...initialValues],
                selected: [...initialValues],
            }));
        }
    }

    componentWillUnmount(): void {
        unsubscribe(this.subs);
    }

    render() {
        const { onSelect, httpClient } = this.props;
        const { options, selected } = this.state;

        return rce('div', {
            key: 'select-address-container',
            ref: this.popoverAnchorRef,
        }, rce(Select,
            {
                type: TYPE.search,
                placeholder: 'Adresse...',
                noResultsMsg: 'Pas de résultats',
                options,
                value: selected,
                filterOptions: opts => opts, // Filtering will be done by autocomplete ws, no filter here
                maxDropdownHeight: '250px',
                filterOutSelected: false,
                onOpen: () => this.onOpen(),
                onInputChange: event => this.onQueryChange(event),
                onChange: (params: any) => this.onSelect(params.value, onSelect, httpClient),
            }
        ));
    }

    private onOpen() {
        // Fix erratic popover placement
        setTimeout(() => {
            const popoverElement: HTMLDivElement = document.querySelector('div[data-baseweb="popover"]');
            if (!this.popoverAnchorRef || !this.popoverAnchorRef.current || !popoverElement) {
                return;
            }
            const rect = this.popoverAnchorRef.current.getBoundingClientRect();
            popoverElement.style.transform = `translate3d(${ rect?.left }px, ${ rect?.top }px, 0px)`;
        });
    }

    private onQueryChange(event: any): void {
        this.nextValue.next(event?.target?.value);
    }

    private onSelect(selection: ISelectable[],
                     onSelect: (selected: SearchSelectedItem) => void,
                     httpClient: HttpClient): void {
        if (isArrayEmpty(selection)) {
            this.updateSelection(selection, onSelect);
            return;
        }
        const selected = selection[0];
        GeocodingBaseService.getPosition(String(selected.id), httpClient).pipe(
            tap((position: MapCoordinates) => this.updateSelection(selection, onSelect, position)),
        ).subscribe();
    }

    private updateSelection(selection: ISelectable[],
                            onSelect: (selected: SearchSelectedItem) => void,
                            position: MapCoordinates = new MapCoordinates(null, null)): void {
        this.setState({
            selected: selection,
            options: isArrayEmpty(selection) ? [] : this.state.options
        });
        onSelect(new SearchSelectedItem(selection, position));
    }

    private updateOptions(suggestions: GeocodingSuggestion[]): void {
        const options = (suggestions || []).map((suggestion: GeocodingSuggestion) => ({
            id: suggestion.id,
            label: suggestion.label
        }));

        this.setState({ options, selected: [] });
    }

    private autoComplete(value: string): void {
        if (value) {
            GeocodingBaseService.autocomplete(value, this.props.httpClient).pipe(
                tap((suggestions: GeocodingSuggestion[]) => this.updateOptions(suggestions))
            ).subscribe();
        } else if (!isArrayEmpty(this.state.selected) || !isArrayEmpty(this.state.options)) {
            this.setState({
                selected: [],
                options: []
            });
        }
    }
}
