import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { ItineraryPointLayer } from 'app/global/class/itinerary-point-layer';
import { MapCoordinates } from 'app/global/class/map-coordinates';

@Injectable()
export class BikeTimelineSharedService {
    private timeLinePointLayer: BehaviorSubject<ItineraryPointLayer> = new BehaviorSubject<ItineraryPointLayer>(undefined);

    getTimelineLayerValue(): ItineraryPointLayer {
        return this.timeLinePointLayer.getValue();
    }

    getTimelineLayer(): BehaviorSubject<ItineraryPointLayer> {
        return this.timeLinePointLayer;
    }

    nextTimelineLayer(pointLayer: ItineraryPointLayer): void {
        this.timeLinePointLayer.next(pointLayer);
    }

    /**
     * Update the current marker position
     * Will not trigger next subject value, only update LatLng object attribute
     *
     * @param {MapCoordinates} coordinates
     */
    updateLayerPosition(coordinates: MapCoordinates): void {
        if (isNil(coordinates) || isNil(coordinates.latitude) || isNil(coordinates.longitude)) {
            return null;
        }
        const pointLayer = this.getTimelineLayerValue();
        if (pointLayer && pointLayer.layer) {
            pointLayer.layer.setLatLng([coordinates.latitude, coordinates.longitude]);
        }
    }
}
