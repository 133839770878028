import { cloneDeep } from 'lodash';
import { ChartBoardDemoState } from '../chart-board-demo.state';

const initialState: ChartBoardDemoState = {
    httpClient: null,
    roles: [],
    showLoader: false,
    sidebarOpen: true,
    showErrorModal: false,
    chartResult: null
};

export const getInitialState = (): ChartBoardDemoState => cloneDeep(initialState);
