import { DemoInfo } from './demo-info';
import { DemoName } from './demo-name';

/**
 * Contient les informations d'accès pour à un groupe de démos
 * Permet la définition des droits d'accès, pour déterminer la désactivation éventuelle d'une route
 * de présentation / galleries
 *
 */
export class DemoGroupInfo extends DemoInfo {
    constructor(name: DemoName,
                roles: string[] = [],
                public children: DemoName[] = [],
                public ignoreChildren?: boolean) {
        super(name, roles);
    }
}
