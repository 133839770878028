import { RouteAccessConfiguration } from './route-access-configuration';
import { isArrayEmpty } from '../../utils/global.helper';
import { getDemoRolesByName } from '../../utils/demo-utils';
import { DemoRouteAccessService } from '../../core/auth/demo-route-access.service';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';

export function RouteAccess(config: RouteAccessConfiguration) {
    return function(target) {
        if (!target || !config || !config.demoName) {
            return target;
        }
        // Resolve route data, merging default with property from the config
        const { demoName } = config;
        // Override roles with the ones from the config if provided, or else use default from the demo constant files
        const authorities = isArrayEmpty(config.roles) ? getDemoRolesByName(demoName) : config.roles;
        const data = {
            ...target.data,
            demoName,
            authorities
        };
        // If at least 1 role is defined, include the UserRouteAccessService class to perform authorization check
        const canActivate: Array<any> = isArrayEmpty(authorities) ? [] : [UserRouteAccessService];
        // Add the demo route access service to check the demo activation status
        canActivate.push(DemoRouteAccessService);
        // Rebuild the route object for the router
        return {
            ...target,
            data,
            canActivate
        };
    };
}
