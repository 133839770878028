import { get, isEmpty, isNil } from 'lodash';
import { Subscription } from 'rxjs';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalBikeResult } from '../result/global-bike-result';
import { unsubscribe } from '../../../utils/global.helper';
import { TimeSeries } from '../energy-prediction-output';
import { TemporarySignalChartService } from './temporary-signal-chart.service';
import { ResultsSharedService } from '../../results-shared.service';
import { LeftSidebarSharedService } from 'app/left-sidebar/left-sidebar.shared.service';

@Component({
    selector: 'dc-temporary-signal-chart',
    templateUrl: './temporary-signal-chart.component.html',
    styleUrls: ['./temporary-signal-chart.component.scss']
})
export class TemporarySignalChartComponent implements OnInit, OnDestroy {

    timeSeries: TimeSeries;
    showChart = false;
    subs: Subscription[] = [];

    constructor(private readonly resultsSharedService: ResultsSharedService,
                private readonly temporarySignalChartService: TemporarySignalChartService,
                private readonly leftSidebarSharedService: LeftSidebarSharedService) {
    }

    ngOnInit(): void {
        this.subs.push(this.leftSidebarSharedService.onBarAction()
            .subscribe(action => {
                setTimeout(() => this.temporarySignalChartService.renderChart(), 100);
            }));
        this.subs.push(this.resultsSharedService.getSelectedResult()
            .subscribe((selected: GlobalBikeResult) => this.updateChart(selected)));
    }

    ngOnDestroy(): void {
        unsubscribe(this.subs);
        // Call the chart function to clean up old chart
        this.temporarySignalChartService.drawChart(null);
    }

    showIcon(): boolean {
        return !isEmpty(this.timeSeries);
    }

    updateChart(result: GlobalBikeResult): void {
        if (isNil(result)) {
            // Null results happen when no result are selected, like at deselection. Clean up for next selection
            this.resetChart();
            return;
        }
        // First get electrical assistance level if any, else set it as 0
        const electricalAssistanceLvl = get(result, 'consoResult.electricAssistanceLvl') || 0;
        // Use the electrical level assistance to select the associated timeSeries
        this.timeSeries = get(result, `consoResult.timeSeries[${electricalAssistanceLvl}]`);
        this.drawChart(this.timeSeries);
    }

    toggleChart(): void {
        this.showChart = !this.showChart;
        this.drawChart(this.timeSeries);
        if (!this.showChart) {
            this.temporarySignalChartService.removeTimelinePoint();
        }
    }

    drawChart(timeSeries: TimeSeries): void {
        if (this.showChart) {
            // Build chart only if user asked to display chart
            // Use timeout to let the other processes (draw the route on the map etc) execute
            // before starting generating the chart, to avoid delaying the main processes
            setTimeout(() => this.temporarySignalChartService.drawChart(timeSeries));
        }
    }

    resetChart(): void {
        // Reinitialize show chart state if user deselected a result
        this.showChart = false;
        this.timeSeries = null;
        // Call the chart function to clean up old chart
        this.temporarySignalChartService.drawChart(null);
    }
}
