import { chain, forEach, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { DragEndEvent, LatLng, LatLngTuple, LeafletMouseEvent, Marker, tileLayer } from 'leaflet';

import { Injectable } from '@angular/core';

import { LeafletPointService } from './leaflet-point.service';
import { MapCoordinates } from 'app/global/class/map-coordinates';

import { ItineraryPointLayer } from 'app/global/class/itinerary-point-layer';
import { ItineraryService } from 'app/global/component/itinerary/itinerary.service';
import { isArrayEmpty } from 'app/utils/global.helper';
import { GeoCoderAddress } from 'app/global/class/geo-coder-address';

@Injectable()
export class LeafletMapConfigService {
    static readonly DEFAULT_FR_ZOOM = 6.35;
    static readonly COORD_FR_CENTER = new LatLng(46.6, 1.8);

    constructor(readonly itineraryService: ItineraryService, readonly leafletPointService: LeafletPointService) {
    }

    getMapConfig(layer): any {
        return {
            layers: [layer],
            zoom: LeafletMapConfigService.DEFAULT_FR_ZOOM,
            center: LeafletMapConfigService.COORD_FR_CENTER
        };
    }

    getLayerConfig(url: string): any {
        return tileLayer(url, { attribution: '&copy; <a href="https://carto.com/attributions">CARTO</a>' });
    }

    getPointLayers(): Observable<ItineraryPointLayer[]> {
        return this.itineraryService
            .getItineraryPointsSubject()
            .pipe(tap((points: ItineraryPointLayer[]) => this.updatePointLayers(points)));
    }

    mapAndFilterLayers(pointLayers: ItineraryPointLayer[]): Marker[] {
        return chain(pointLayers)
            .map((point: ItineraryPointLayer) => point.layer)
            .filter(layer => !isNil(layer))
            .valueOf();
    }

    updatePointLayers(points: ItineraryPointLayer[]): ItineraryPointLayer[] {
        forEach(points, (point: ItineraryPointLayer) => {
            if (point.needRefresh) {
                point.layer = this.leafletPointService.buildPointLayer(
                    point,
                    this.itineraryService.getItineraryPointImageUrl(point, points)
                );
                this.addPointMarkerListener(point);
            }
        });
        return points;
    }

    addPointMarkerListener(point: ItineraryPointLayer): void {
        if (point && point.layer) {
            // Add drag'n drop listener
            point.layer.on('dragend', (value: DragEndEvent) => {
                if (!value || !value.target || typeof value.target.getLatLng !== 'function') {
                    console.warn('Could not perform drag, leaving');
                    return;
                }
                const latLong: LatLng = value.target.getLatLng();
                if (latLong) {
                    const coordinates = new MapCoordinates(latLong.lat, latLong.lng);
                    this.itineraryService.updateOnDrag(coordinates, point);
                }
            });
            // Update needRefresh status
            point.needRefresh = false;
        }
    }

    updatePoint(event: LeafletMouseEvent): Observable<GeoCoderAddress> {
        const coordinates = new MapCoordinates(event.latlng.lat, event.latlng.lng);
        // First get Address from points coordinates
        return this.itineraryService.updatePointFromGPS(coordinates);
    }

    getFitBounds(itineraryPoints: ItineraryPointLayer[]): LatLngTuple[] {
        let bounds: LatLngTuple[] = undefined;
        if (itineraryPoints) {
            bounds = itineraryPoints
                .map((point: ItineraryPointLayer) => {
                    let latLng: LatLngTuple = null;
                    if (point && point.coordinates) {
                        latLng = [point.coordinates.latitude, point.coordinates.longitude];
                    }
                    return latLng;
                })
                .filter(points => points && points[0] && points[1]);
        }
        return isArrayEmpty(bounds) ? undefined : bounds;
    }
}
