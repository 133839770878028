<div class="row gallery-container justify-content-around">
    <div class="gallery-inner-container" align="center">
        <div class="container-fluid" align="center">
            <div class="row m-3 justify-content-around">
                <div class="galleryColumn" *ngFor="let file of fileNameType">
                    <div type="button" (click)="navigate(file)" class="card galleryCard text-center rounded-lg p-5">
                        <img class="img-fluid d-block rounded-circle" [src]="img + file.fileName + '.png'">
                        <h4 class="card-title">{{file.fileName}}</h4>
                    </div>
                </div>
                <!--<div class="galleryColumn">
                    <a class="card galleryCard text-center rounded-lg p-5" routerLink="../../docs/pdf/DataWorkflow.pdf">
                        <img class="img-fluid d-block rounded-circle" src="/content/images/galleries/dataWorkflow.png">
                        <h4 class="card-title" jhiTranslate=documentation.dataWorkflow></h4>
                    </a>
                </div>-->
            </div>
            <div class="btn btn-dark" jhiTranslate="entity.action.back" routerLink="/"></div>
            <div class="row m-3 justify-content-around"></div>
        </div>
    </div>
</div>
