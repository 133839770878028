import * as React from 'react';
import { rce } from 'app/utils/react-utils';

import styled from 'styled-components';
import { Spinner } from 'baseui/spinner';

const LoaderMainContainer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    top: calc(50% - 40px);
    left: 0;
    right: 0;
    z-index: 10000;
`;

interface ILoaderProps {
    showLoader: boolean;
}

interface ILoaderState {
}

export class LoaderComponent extends React.Component<ILoaderProps, ILoaderState> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
    }

    render() {
        const { showLoader } = this.props;
        if (!showLoader) {
            return null;
        }

        return rce(LoaderMainContainer, { key: 'loader-container' },
            rce(Spinner, { key: 'spinner-loader', size: 80 }));
    }
}
