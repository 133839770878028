import * as React from 'react';

import { rce } from 'app/utils/react-utils';
import { Modal, ModalBody, ModalButton, ModalFooter, ROLE, SIZE, ModalHeader } from 'baseui/modal';

interface IBdSimpleModalProps {
    title: string;
    body1: string;
    body2: string;
    closeModal: Function;
}

interface IBdSimpleModalState {
}

export class SimpleModalComponent extends React.Component<IBdSimpleModalProps, IBdSimpleModalState> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, body1, body2 } = this.props;
        return rce(Modal, {
            key: 'BdSimpleModalMainContainer',
            isOpen: true,
            size: SIZE.auto,
            role: ROLE.dialog,
            animate: true,
            autoFocus: true,
            closeable: true,
            overrides: { Root: { style: () => ({ zIndex: 1000 }) } },
        }, [
            title && rce(ModalHeader, { key: 'modal-header' }, title),
            rce(ModalBody, { key: 'modal-body' }, [
                body1 && rce('div', { key: 'body-1' }, body1),
                body2 && rce('div', { key: 'body-2' }, body2),
            ]),
            rce(ModalFooter, { key: 'modal-footer' },
                rce(ModalButton, {
                    onClick: () => this.props.closeModal()
                }, 'OK'))
        ]);
    }
}
