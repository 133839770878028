<div>
    <router-outlet name="navbar"></router-outlet>
</div>
<div id="main-app-container" class="main-root-container">
    <router-outlet></router-outlet>
    <router-outlet name="popup"></router-outlet>
</div>
<!--
<div>
    <router-outlet name="footer"></router-outlet>
</div>
-->
