import { ItineraryPoint } from './itinerary-point';
import { Marker } from 'leaflet';
import { MapCoordinates } from './map-coordinates';

export class ItineraryPointLayer extends ItineraryPoint {

    static createFromPoint(point: ItineraryPoint, layer: Marker, needRefresh = true): ItineraryPointLayer {
        if (!point || !point.coordinates) {
            return null;
        }
        return new ItineraryPointLayer(point.id, point.address, point.coordinates, layer, needRefresh);
    }

    constructor(public id: number,
                public address = '',
                public coordinates = new MapCoordinates(null, null),
                public layer: Marker = null,
                public needRefresh = true) {

        super(id, address, coordinates);
    }
}
