import {IsoUnit} from 'app/global/enum/iso-unit';
import {TruckWaypoint} from 'app/global/class/way-point';
import {HereCredentials} from 'app/maps/here-maps/here-credentials';
import {GlobalRoutingData} from 'app/global/class/global-routing-data';

export class CFCInputDTO {
    constructor(public hereCredentials: HereCredentials = new HereCredentials(),
                public userId = 'user-2',
                public vehicleData: VehicleData[] = [new VehicleData()],
                public waypoints: TruckWaypoint[] = [new TruckWaypoint()],
                public enableEnergeticFlow = false,
                public enableTimeLine = true,
                public record = false,
                public routingData: RoutingData = new RoutingData(),
                public unit: Unit = new Unit()) {
    }
}

export class RoutingData extends GlobalRoutingData {
    constructor(public departure = DEP_ARRIV_DATES.NOW,
                public arrival = '',
                public enableTraffic = true,
                public enableWaypoints = true,
                public enableWeather = false,
                public tollRoadExcluded = false,
                public transitAround = true,
                public alternatives = 7,
                public dieselCost = 0,
                public gasCost = 0,
                public shippedHazardousGoods?: any, // property shared between dynamic itinerary config switch button and string list
                public softTruckRestriction = false) {
        super(departure, arrival, enableTraffic, enableWaypoints, enableWeather);
    }
}

export class Unit {
    constructor(public currency = DEVISE.EUR,
                public distance = null,
                public emission = IsoUnit.KG,
                public fuelConsumption = FUELCONSUMPTION_UNIT.LITRE_PER_100KM,
                public volume = VOLUME_UNIT.LITRES) {
    }
}

export class VehicleData {
    constructor(public axles: Axle[] = [new Axle()],
                public emissionType = 6,
                public engineFuelMap: EngineFuelMap = new EngineFuelMap(),
                public engineTorqueMap: EngineTorqueMap = new EngineTorqueMap(),
                public height = 0,
                public fuelType = FUEL_TYPE.DIESEL,
                public length = 4,
                public width = 4,
                public aerodynamicDragCoefficient = 0.6,
                public cylinderNumber = 6,
                public emptyWeight = 1400,
                public frontalArea = 10,
                public fuelCost = 1,
                public fuelLevel = 1,
                public fuelTankCapacity = 400,
                public gearBoxRatio = [14.94, 11.73, 9.04, 7.09, 5.54, 4.35, 3.44, 2.7, 2.08, 1.63, 1.27, 1],
                public idleSpeed = 600,
                public limitedWeight = 0,
                public maxEnginePower = 368,
                public maxEngineSpeed = 2100,
                public maxEngineSpeedForMaxEnginePower = 1900,
                public maxEngineSpeedForMaxEngineTorque = 1400,
                public maxEngineTorque = 2500,
                public minEngineSpeedForMaxEnginePower = 1400,
                public minEngineSpeedForMaxEngineTorque = 1050,
                public payload = 10000,
                public secondaryAxleRatio = 2.85,
                public vehicleWeight = 1400) {
    }

}

export class Axle {
    constructor(public axleNumber = 1,
                public axleWeight = 60,
                public rollingFrictionCoefficient = 4.8,
                public tireCharacteristics = '315/70R22.5',
                public tireNumber = 0,
                public type = AXLE_TYPE.DRIVE) {

    }
}

export class EngineFuelMap {
    static Inline_Model1: number[] = [];

    constructor(public engineFuel = EngineFuelMap.Inline_Model1,
                public engineSpeed: number [] = [],
                public engineTorque: number [] = []) {

    }
}

export class EngineTorqueMap {
    constructor(public engineSpeed: number [] = [],
                public maxEngineTorque: number [] = [],
                public minEngineTorque: number [] = []) {

    }
}

export enum DEVISE {
    EUR = 'EUR',
    GBP = 'GBP',
    CHF = 'CHF'
}

export enum DISTANCE_UNIT {
    KILOMETRES = 'kilometers',
    MILES = 'miles'
}

export enum FUELCONSUMPTION_UNIT {
    LITRE_PER_100KM = 'litrePer100km',
    KM_PER_LITRE = 'kmPerLitre',
    MILES_PER_GALLON = 'milesPerGallon'
}

export enum VOLUME_UNIT {
    LITRES = 'litres',
    GALLONS = 'gallons'
}

export enum AXLE_TYPE {
    DRIVE = 'Drive',
    NON_DRIVE = 'Non Drive',
    TRAILER = 'Trailer',
    STEER = 'Steer'
}

export enum AXLE_NAME {
    DRIVE = 'Drive',
    NON_DRIVE = 'Non Drive',
    TRAILER = 'Trailer',
    STEER = 'Steer'
}

export enum FUEL_TYPE {
    DIESEL = 'diesel',
    GAS = 'gaz'
}

export enum FUEL_TYPE_NAME {
    DIESEL = 'diesel',
    GAS = 'gas'
}

export enum WAYPOINT_TYPES {
    SERVICE_STATION = 'SERVICE_STATION',
    PASS_THROUGH = 'PASS_THROUGH'
}

export enum DEP_ARRIV_DATES {
    NOW = 'now'
}

export enum EMISSION_TYPE {
    EURO_I = 'EURO I',
    EURO_II = 'EURO II',
    EURO_III = 'EURO III',
    EURO_IV = 'EURO IV',
    EURO_V = 'EURO V',
    EURO_VI = 'EURO VI',
    EURO_EEV = 'EURO EEV',
    ELECRIC_VEHICLES = 'Electric Vehicles'
}
