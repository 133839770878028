export enum ChartItemType {
    AREA = 'area',
    BAR = 'bar',
    LINE = 'line'
}

export interface ChartPoint {
    x: number;
    y: number;
}

export enum TemporarySignalChartItems {
    ALTITUDE = 'Altitude',
    VITESSE = 'Vitesse',
    PUISSANCE = 'Puissance'
}

export class CumulativeChartItem {

    constructor(
        public key: string,
        public yAxis = 1,
        public type: ChartItemType = ChartItemType.LINE,
        public values: ChartPoint[] = []) {

    }
}
