import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MapControlSharedService } from './map-control.shared.service';
import { MapControlName } from './map-controle-name';

@Component({
    selector: 'dc-map-control',
    templateUrl: './map-control.component.html',
    styleUrls: ['./map-control.component.scss']
})
export class MapControlComponent implements OnInit {

    controlFormGroup: FormGroup;

    constructor(private readonly mapControlSharedService: MapControlSharedService) {
    }

    ngOnInit() {
        // Create form group and form controls
        this.controlFormGroup = new FormGroup({
            meteoState: new FormControl(null),
            airQualityState: new FormControl(null)
        });
        // On each controller change, update service value and if value is true, set the other control value to false
        this.controlFormGroup.get('meteoState').valueChanges.subscribe(value => {
            const name = value ? MapControlName.METEO : null;
            this.mapControlSharedService.nextActivated(name);
            if (name) {
                this.controlFormGroup.get('airQualityState').setValue(false, {onlySelf: true, emitEvent: false });
            }
        });
        this.controlFormGroup.get('airQualityState').valueChanges.subscribe(value => {
            const name = value ? MapControlName.AIR_QUALITY : null;
            this.mapControlSharedService.nextActivated(name);
            if (name) {
                this.controlFormGroup.get('meteoState').setValue(false, {onlySelf: true, emitEvent: false });
            }
        });
    }

}
