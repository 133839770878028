import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { GeocodingSuggestion } from '../../../class/geocoding/geocoding-suggestion';
import { MapCoordinates } from '../../../class/map-coordinates';
import { GeoCoderAddress } from 'app/global/class/geo-coder-address';
import { isNotEmpty } from 'app/utils/global.helper';

export class GeocodingBaseService {

    private static readonly BASE_URL: string = 'geoservices/api/here';
    private static readonly SERVICE_COORDINATES_URL = 'geocode.search.hereapi.com/v1/geocode';
    private static readonly SERVICE_ADDRESS_URL = 'revgeocode.search.hereapi.com/v1/revgeocode';
    private static readonly URL_POSITION = 'lookup.search.hereapi.com/v1/lookup';
    private static readonly URL_AUTOCOMPLETE = 'autocomplete.search.hereapi.com/v1/autocomplete';

    // private static readonly URL_AUTOCOMPLETE: string = 'api/geocoding/autocomplete';
    // private static readonly URL_POSITION: string = 'api/geocoding/position';
    // private static readonly SERVICE_COORDINATES_URL = 'api/getcoordinates';
    // private static readonly SERVICE_ADDRESS_URL = 'api/getaddress';
    static autocomplete(query: string, httpClient: HttpClient): Observable<GeocodingSuggestion[]> {
        if (!httpClient) {
            throw Error('No http Client!');
        }
        if (!query || !query.trim()) {
            return of([]);
        }
        const params: HttpParams = new HttpParams()
            .set('q', query);
        return httpClient.get<{
            items: { id: string, title: string }[]
        }>(`${GeocodingBaseService.BASE_URL}/${GeocodingBaseService.URL_AUTOCOMPLETE}`, { params })
            .pipe(map(r => r.items.map(i => ({ id: i.id, label: i.title }))));
    }

    static getPosition(locationId: string, httpClient: HttpClient): Observable<MapCoordinates> {
        if (!httpClient) {
            throw Error('No http Client!');
        }
        if (!locationId || !locationId.trim()) {
            return of(null);
        }
        const params: HttpParams = new HttpParams()
            .set('id', String(locationId));

        return httpClient.get<{
            position: { lat: number, lng: number }
        }>(`${GeocodingBaseService.BASE_URL}/${GeocodingBaseService.URL_POSITION}`, { params }).pipe(
            map(response => response.position),
            map(pos => !!pos && new MapCoordinates(pos.lat, pos.lng))
        );
    }

    static getGPSFromAddress(address: string, httpClient: HttpClient): Observable<MapCoordinates> {
        const params: HttpParams = new HttpParams()
            .set('q', String(address));
        return httpClient.get<{
            items: { position: { lat: number, lng: number } }[]
        }>(`${GeocodingBaseService.BASE_URL}/${GeocodingBaseService.SERVICE_COORDINATES_URL}`, { params }).pipe(
            map(({ items }) => isNotEmpty(items) ? items[0].position : undefined),
            map(coord => {
                if (!coord) {
                    throw Error('Address not found');
                } else {
                    return GeocodingBaseService.mapToCorrectCoordinates(coord);
                }
            })
        );
    }

    static getAddressFromGPS(coordinates: MapCoordinates, httpClient: HttpClient): Observable<GeoCoderAddress> {
        const params: HttpParams = new HttpParams()
            .set('at', String(coordinates.latitude) + ',' + String(coordinates.longitude));
        return httpClient.get<{
            items: { address: { label: string } }[]
        }>(`${GeocodingBaseService.BASE_URL}/${GeocodingBaseService.SERVICE_ADDRESS_URL}`, { params })
            .pipe(
                map(res => isNotEmpty(res.items) ? res.items[0].address : { label: 'Location not found' })
            );
    }

    /**
     * Geocoder webservice may return object with Latitude / Longitude properties,
     * (instead of latitude / longitude)
     *
     * Map to MapCoordinates object, so we always retrieve the correct properties
     *
     * @param {{lat: number, lng: number}} coordinate
     * @return {MapCoordinates}
     */
    private static mapToCorrectCoordinates(coordinate?: { lat: number, lng: number }): MapCoordinates {
        if (!coordinate) {
            return null;
        }
        return new MapCoordinates(coordinate.lat, coordinate.lng);
    }
}
