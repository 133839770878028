import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { PdfviewerService } from 'app/docs/pdfviewer/pdfviewer.service';
import {GalleryService} from 'app/galleries/documentation/gallery.service';
import {FileUtils} from 'app/utils/file-utils';

@Component({
    selector: 'dc-html-viewer',
    template: `
        <iframe #htmlIFrame></iframe>
        <a [routerLink]="returnTo" class="nav-link float">
            <fa-icon icon="arrow-left"></fa-icon>
        </a>
    `,
    styleUrls: ['./html-viewer.component.scss'],
})

export class HtmlViewerComponent implements OnInit {

    src: SafeResourceUrl | SafeHtml;
    returnTo: string;
    filename: string;

    @ViewChild('htmlIFrame', { static: true }) htmlIFrame;

    constructor(private readonly route: ActivatedRoute,
                public readonly sanitizer: DomSanitizer,
                public readonly renderer: Renderer2,
                public readonly galleryService: GalleryService,
                private readonly pdfviewerService: PdfviewerService) {
    }

    ngOnInit(): void {
        this.filename = this.route.snapshot.params.data;
        if (this.filename) {
            this.galleryService.fetchHtmlFile(this.filename).subscribe(res => {
                if (res) {
                    res = FileUtils.htmlToDataURL(res);
                    this.returnTo = '/gallery/documentation';
                    this.renderer.setAttribute(this.htmlIFrame.nativeElement, 'src', res);
                }
            }, err => {
                console.error('Error at getting the content from files service ', err);
                this.renderer.setAttribute(this.htmlIFrame.nativeElement, 'src', 'content/404.html');
                return EMPTY;
            });
        } else {
            this.route.params.subscribe((params: Params) => this.extractParams(params));
        }
    }

    private extractParams(params: Params): void {
        this.returnTo = params['returnTo'] || '/';
        this.setIFrameSrc(params['path']);
    }

    private setIFrameSrc(path: string): void {
        this.pdfviewerService.getWebContentAsObjectUrl(path).pipe(
            tap((src: string) => this.renderer.setAttribute(this.htmlIFrame.nativeElement, 'src', src)),
            catchError(err => {
                console.error('Error at getting the content from files service with file path', path, err);
                this.renderer.setAttribute(this.htmlIFrame.nativeElement, 'src', 'content/404.html');
                return EMPTY;
            })
        ).subscribe();
    }
}
