import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable()
export class LeafletMapSharedService {
    private nextLayer: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    getVectorLayer(): BehaviorSubject<any> {
        return this.nextLayer;
    }

    nextLayerControl(mapLayer: any): void {
        this.nextLayer.next(mapLayer);
    }
}
