const SET_CHART_RESULT = 'SET_CHART';

export const setChartResults = (chartResults: any) => ({
    type: SET_CHART_RESULT,
    chartResults
});

export const DATA_ACTIONS = {
    [SET_CHART_RESULT]: (state, action) => ({
        ...state,
        chartResults: action.chartResults
    })
};
