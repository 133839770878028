export enum IsoUnit {
    KM = 'km',
    DAY = 'jours',
    HEBDO_DAY = 'km / jour',
    HOUR = 'h',
    MINUTE = 'min',
    HEBDO_EURO = '€ / jour',
    LITRE_KM = ' l / 100 km',
    EURO = '€',
    LITRES = 'L',
    KG = 'kg',
    KW = 'kW',
    GRAMME = 'g',
    GRAM_PER_KM = 'g / km',
    GRAM_CO2_PER_KM = 'g CO2 / km',
    EURO_PER_LITRE = '€ / l',
    EURO_PER_KG = '€ / kg',
    SINGLE_DAY = ' / jour',
    SEMAINE = ' / semaine',
    MONTH = ' / mois',
    YEAR = ' / an',
    NO_UNIT = ''
}
