import { isNil } from 'lodash';

import * as nv from 'nvd3';
import * as d3 from 'd3';

import { Injectable } from '@angular/core';
import { TimeSeries } from '../energy-prediction-output';
import { CumulativeChartItem, TemporarySignalChartItems } from './cumulative-chart-item';
import { isArrayEmpty } from 'app/utils/global.helper';
import { formatDecimal } from 'app/utils/math-utils';
import { MapFacadeService } from 'app/maps/map-facade.service';
import { BikeTimelineSharedService } from './bike-timeline-shared.service';
import { ItineraryPoint } from 'app/global/class/itinerary-point';
import { MapCoordinates } from 'app/global/class/map-coordinates';
import { ItineraryPointLayer } from 'app/global/class/itinerary-point-layer';
import { NvD3MouseEvent } from 'app/global/interface/nvd3.interface';

@Injectable()
export class TemporarySignalChartService {

    static readonly TIMELINE_POINT_ID = -1;

    chartData: CumulativeChartItem[] = [];
    currentTimeSeries: TimeSeries;

    constructor(private readonly sharedService: BikeTimelineSharedService,
                private readonly mapFacadeService: MapFacadeService) {
    }

    drawChart(timeSeries: TimeSeries): void {
        if (this.isChartUpToDate(timeSeries)) {
            // Graph is up to date, no need to do anything
            return null;
        }
        // Data changed, so remove previous time line point from the map
        this.removeTimelinePoint();

        // Check if timeSeries is consistent
        if (!this.isTimeSeriesValid(timeSeries)) {
            // New time series are undefined or not valid, clean up and leave
            this.removeOldSvg();
            return null;
        }
        // New timeSeries, save as current and start producing chart data
        this.currentTimeSeries = timeSeries;

        const altitude = new CumulativeChartItem(TemporarySignalChartItems.ALTITUDE.toString(), 2);
        const speed = new CumulativeChartItem(TemporarySignalChartItems.VITESSE.toString());
        const power = new CumulativeChartItem(TemporarySignalChartItems.PUISSANCE.toString());

        // Set values for each graph data
        timeSeries.traveledDistanceDref.forEach((x, index) => {
            altitude.values.push({x, y: formatDecimal(timeSeries.altitudeDref[index])});
            speed.values.push({x, y: formatDecimal(timeSeries.speedGeneratedDref[index])});
            power.values.push({x, y: formatDecimal(timeSeries.tractionPowerDref[index])});
        });

        // Update with the fresh data and render the chart
        this.chartData = [altitude, speed, power];
        this.renderChart();
    }

    removeTimelinePoint(): void {
        this.sharedService.nextTimelineLayer(null);
    }

    /**
     * Return true if the chart is present in the DOM and is using the same data
     * (no need then to update the chart)
     *
     * @param {TimeSeries} timeSeries the new data to compare with the one used for current chart
     * @return {boolean}
     */
    private isChartUpToDate(timeSeries: TimeSeries): boolean {
        const svg = d3.select('#tempSignalSvg');
        const emptyTest = svg && !svg.empty();
        return emptyTest && timeSeries === this.currentTimeSeries;
    }

    renderChart(): void {
        // Check for previous svg in DOM and remove if necessary
        this.removeOldSvg();
        nv.addGraph(() => {
            const chart = nv.models.multiChart()
                .margin({top: 30, right: 40, bottom: 30, left: 40})
                .color(d3.scale.category10().range())
                .useInteractiveGuideline(true);

            // Add the 3 axis
            chart.xAxis
                .tickFormat(d3.format(',d'));

            chart.yAxis1
                .tickFormat(d3.format(',.1f'));

            chart.yAxis2
                .tickFormat(d3.format(',.1f'));

            // Add chart to the DOM
            d3.select('#tempSignalChart')
                .style('width', '100%')
                .append('svg')
                .attr('id', 'tempSignalSvg')
                .datum(this.chartData)
                .call(chart);

            // Add listener on mouse move to update timeline point position on mouse move
            chart.interactiveLayer.dispatch.on('elementMousemove.name', e => this.updatePoint(e));

            return chart;
        });
    }

    /**
     * Update the timeline point on the map when moving the mouse over the chart
     *
     * @param {NvD3MouseEvent} mouseEvent
     */
    private updatePoint(mouseEvent: NvD3MouseEvent): void {
        // Retrieve the coordinates from the event object
        const coord: MapCoordinates = this.getCoordinateFromValue(mouseEvent);
        // Get the current point layer if any
        let pointLayer: ItineraryPointLayer = this.sharedService.getTimelineLayerValue();
        if (!pointLayer) {
            // No point yet, so create new point and new Itinerary point layer
            const newPoint = new ItineraryPoint(TemporarySignalChartService.TIMELINE_POINT_ID, '', coord);
            // Create the point with the marker for the map service
            pointLayer = this.mapFacadeService.buildTimelinePointLayer(newPoint);
            // Update model value so the map can display the point
            this.sharedService.nextTimelineLayer(pointLayer);
        } else {
            // Just update coordinates of current marker, map will refresh changes
            this.sharedService.updateLayerPosition(coord);
        }
    }

    /**
     * Return point coordinates from the nvD3 mouse event
     *
     * @param {NvD3MouseEvent} mouseEvent
     * @return {MapCoordinates}
     */
    private getCoordinateFromValue(mouseEvent: NvD3MouseEvent): MapCoordinates {
        if (isNil(mouseEvent) || isNil(mouseEvent.pointXValue)) {
            return null;
        }
        // Make data is formatted with 3 decimals, to match the one from timeSeries
        const xValue = Math.round(mouseEvent.pointXValue * 1000) / 1000;
        // Find point index in timeSeries values
        const pointIndex = this.currentTimeSeries.traveledDistanceDref.indexOf(xValue);
        if (!this.isTimeSeriesIndexValid(pointIndex)) {
            console.warn('Could not get Coordinate for time line point, index value not valid');
            return null;
        }
        // Find the lat and lng values from the respective arrays
        const latDref = this.currentTimeSeries.latDref[pointIndex];
        const lngDref = this.currentTimeSeries.lngDref[pointIndex];

        return new MapCoordinates(latDref, lngDref);
    }

    /**
     * Return true if index is with current timeSeries latitude and longitude arrays range
     *
     * @param {number} pointIndex
     * @return {boolean}
     */
    private isTimeSeriesIndexValid(pointIndex: number): boolean {
        return this.isTimeSeriesValid(this.currentTimeSeries) && pointIndex >= 0 &&
            pointIndex < this.currentTimeSeries.latDref.length &&
            pointIndex < this.currentTimeSeries.lngDref.length;
    }

    private isTimeSeriesValid(timeSeries: TimeSeries): boolean {
        return !isNil(timeSeries) &&
            !isArrayEmpty(timeSeries.traveledDistanceDref) &&
            !isArrayEmpty(timeSeries.altitudeDref) &&
            !isArrayEmpty(timeSeries.speedGeneratedDref) &&
            !isArrayEmpty(timeSeries.tractionPowerDref);
    }

    /**
     * Remove old chart svg from the DOM
     *
     */
    private removeOldSvg(): void {
        const oldSvg = d3.select('#tempSignalSvg');
        if (oldSvg) {
            oldSvg.remove();
        }
    }

}
