/**
 * Définit l'information d'accès à une ressource liée à une démonstration
 *
 * Utilisé par les composants de présentation qui sont navigables
 * (ex Element d'un caroussel, bouton "en savoir plus" d'un élément du caroussel)
 *
 * 3 types de ressources ciblés
 *      - une url externe (http**)
 *      - une route interne simple destinée au routeur angular,
 *      - une ressource externe mais chargée dans un composant angular, via une iframe (cf paramètre embedded)
 *
 * path: l'url / route
 * authorities: la liste des roles autorisant l'accès
 * alwaysVisible: si le composant associé doit être affiché si l'utilisateur n'a pas les droits d'accès
 * embedded: True si la ressource est à encapsuler dans un composant angular, dans une iframe (docs)
 * returnTo: la route à actionner dans le cas du bouton "en savoir plus", pour revenir sur la démo d'origine
 */
export class DemoPathAccess {
    constructor(public path: string,
                public authorities: string[], // The authorities to access the path,
                public alwaysVisible = true, // If true, will be visible for unauthorized users
                public embedded = false, // If the path should be contained in the content wrapper (iframe),
                public returnTo = '/' // Path of the route to come back from when return button is pressed
    ) {
    }
}
