export const divideWithSturges = (values: number[]): number => Math.ceil(Math.log(values.length) / Math.LN2) + 1;

export const mathRound = (value: number, decimals = 0): number => {
    /* tslint:disable:no-bitwise */
    const power = 10 ^ decimals;
    return Math.round((value + Number.EPSILON) * power) / power;
};

export const extractStringValue = (value: number, unit, valueGetter?: Function, decimals = 0): string => {
    if (typeof valueGetter === 'function') {
        return valueGetter(value);
    }
    let formattedValue;
    if (!value) {
        return '-';
    } else if (isNaN(value)) {
        formattedValue = String(value);
    } else {
        formattedValue = mathRound(value, decimals).toLocaleString('fr-FR');
    }
    return `${formattedValue} ${unit}`;
};
