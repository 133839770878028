import { Route } from '@angular/router';
import { GalleryComponent } from './gallery.component';
import { RouteAccess } from 'app/global/decorator/route-access.decorator';
import { DemoName } from 'app/demos/demo-name';

@RouteAccess({ demoName: DemoName.GALLERY_TOOLKIT })
export class ToolkitRoute implements Route {
    static path = 'toolkit';
    static component = GalleryComponent;
    static data = { pageTitle: 'toolkit.title' };
}
