import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ChartDemoUrlHandler } from './chart-demo-url.handler';
import { MobicloudResponse } from '../../../class/mobicloud-response';
import { extractChartBoardConfig, extractComparisonResult } from './chart-board-demo.helper';
import { ChartBoardDemoConfig } from './chart-board-demo-config';
import { IDashBoardOutput } from './class/output/dash-board-output.interface';
import { HttpMethod } from '../../../enum/http-method';
import { ChartBoardInput } from './class/input/chart-board-input';
import { WsDefinition } from 'app/global/class/ws/ws-definition';

export class ChartDemoService<T extends ChartBoardInput, V> {

    constructor(private urlHandler: ChartDemoUrlHandler) {
    }

    getBoardConfig(httpClient: HttpClient, onError: (err) => Observable<void>): Observable<ChartBoardDemoConfig | void> {
        return this.processRequest<MobicloudResponse<IDashBoardOutput>>(this.urlHandler.getConfiguration(), httpClient).pipe(
            map((r: MobicloudResponse<IDashBoardOutput>) => extractChartBoardConfig(r)),
            catchError(err => (typeof onError === 'function' ? onError(err) : this.handleError(err))));
    }

    compareResults(tco2input: T, httpClient: HttpClient, onError?: (err) => Observable<void>): Observable<V | void> {
        if (!tco2input) {
            throw Error('Error calculate: input model not defined');
        }
        return this.processRequest<MobicloudResponse<V>>(this.urlHandler.getCalculate(), httpClient, tco2input).pipe(
            map((r: MobicloudResponse<V>) => extractComparisonResult(r)),
            catchError(err => (typeof onError === 'function' ? onError(err) : this.handleError(err))));
    }

    /**
     * Aiguille vers le type de requête en fonction de la configuration fournie par la démo en cours
     *
     * @param wsDefinition les informations du web service nécessaires pour la requête (url, methode)
     * @param httpClient le client http
     * @param body le body de la requête si présent
     * @private
     */
    private processRequest<W>(wsDefinition: WsDefinition, httpClient: HttpClient, body?: any | null): Observable<W> {
        if (!httpClient) {
            throw Error('Http client not defined');
        }
        if (!wsDefinition) {
            throw Error('No definition for this web service call');
        }
        const url = wsDefinition.getURL(this.urlHandler.getBaseUrl());
        let options: Object;
        if (wsDefinition.getParams()) {
            options = { params: wsDefinition.getParams() };
        }
        let request: Observable<W>;
        switch (wsDefinition.getMethod()) {
            case HttpMethod.GET:
                request = httpClient.get<W>(url, options);
                break;
            case HttpMethod.POST:
                request = httpClient.post<W>(url, body, options);
                break;
            case HttpMethod.PUT:
                request = httpClient.put<W>(url, body, options);
                break;
            case HttpMethod.DELETE:
                request = httpClient.delete<W>(url, options);
                break;
            default:
                request = httpClient.get<W>(url, options);
        }
        return request;
    }

    private handleError(err): Observable<void> {
        console.error(err);
        return EMPTY;
    }
}
