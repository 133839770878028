import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { DemoService } from './demo.service';
import { DemoInfo } from '../../demos/demo-info';
import { getDemoInfosByName, mapDemoNamesToDemoInfos } from '../../utils/demo-utils';
import { DemoGroupInfo } from 'app/demos/demo-group-info';
import { isArrayEmpty } from 'app/utils/global.helper';

@Injectable()
export class DemoAccessSharedService {
    private disabledDemos = new BehaviorSubject<DemoInfo[] | DemoGroupInfo[]>(undefined);

    constructor(private readonly demoService: DemoService) {
    }

    init(): void {
        this.initDisabledDemos().subscribe();
    }

    initDisabledDemos(): Observable<DemoInfo[] | DemoGroupInfo[]> {
        return this.demoService.getDisabledDemo().pipe(
            map(mapDemoNamesToDemoInfos),
            tap((demos: DemoInfo[]) => this.setDisabledDemos(demos)),
            share()
        );
    }

    getDisabledDemosSubject(): BehaviorSubject<DemoInfo[] | DemoGroupInfo[]> {
        return this.disabledDemos;
    }

    getDisabledDemosValues(): DemoInfo[] | DemoGroupInfo[] {
        return this.disabledDemos.getValue() || [];
    }

    isDemoActive(demo: DemoInfo | DemoGroupInfo): boolean {
        return !demo || this.getDisabledDemosValues().every(d => d && d.name !== demo.name) && this.checkChildren(demo);
    }

    checkDemoActive(demo: DemoInfo | DemoGroupInfo): Observable<boolean> {
        if (this.disabledDemos.getValue() === undefined) {
            // List has not been requested yet, initialize and check demo status
            return this.initDisabledDemos().pipe(map(() => this.isDemoActive(demo)));
        }
        return of(this.isDemoActive(demo));
    }

    private setDisabledDemos(demos: DemoInfo[] | DemoGroupInfo[]): void {
        this.disabledDemos.next(demos);
    }

    private checkChildren(demo: DemoInfo | DemoGroupInfo): boolean {
        if (demo && demo instanceof DemoGroupInfo && !isArrayEmpty(demo.children)) {
            return demo.ignoreChildren || demo.children.some(d => this.isDemoActive(getDemoInfosByName(d)));
        }
        return true;
    }
}
