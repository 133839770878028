import * as React from 'react';
import { rce } from '../../../../utils/react-utils';
import { styled } from 'baseui';
import { isArrayEmpty, isNil } from '../../../../utils/global.helper';
import { Input } from 'baseui/input';
import { InputType } from './class/input-type';
import { IMenuGroup } from './class/menu-group.interface';
import { IMenuOption } from './class/menu-option.interface';
import { SelectComponent } from '../react-select/select.react.component';
import { ISelectOption } from '../react-select/select-option.interface';
import { findOptionById, findSelectedOption, getSelectedValue } from './sidebar.helper';
import { MenuSelectOption } from './class/menu-select-option';
import { InputContainer } from '../common/common-styled-components';
import { ReactSearchAddressComponent } from '../react-search-address/react-search-address.react.component';
import { HttpClient } from '@angular/common/http';
import { MapCoordinates } from '../../../class/map-coordinates';
import { SearchSelectedItem } from '../react-search-address/search-selected-item';
import { ISidebarUIState } from './sidebar-ui-state.interface';
import { SidebarLabelContainer } from 'app/global/component/react/react-map-demo/bd-sidebar/bd-styled.components';

const Tco2VehicleTypeMainContainer = styled('div', (({ $theme }) => ({
    padding: '20px'
})));
const SidebarGroupInputContainer = styled('div', (({ $theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
})));
const SidebarGroupInputItemContainer = styled('div', (({ $theme }) => ({
    padding: '0 5px',
})));

interface ISidebarGroupProps {
    menuGroup: IMenuGroup;
    getValueFromPath: (path: string) => any;
    updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void;
    httpClient: HttpClient;
    uiState: ISidebarUIState;
}

interface ISidebarGroupState {
    selectedAddress: { [targetPath: string]: SearchSelectedItem };
}

export class SidebarGroupComponent extends React.Component<ISidebarGroupProps, ISidebarGroupState> {

    constructor(props) {
        super(props);
        this.state = {
            selectedAddress: {}
        };
    }

    render() {
        const { menuGroup } = this.props;

        return rce(Tco2VehicleTypeMainContainer, { key: menuGroup && menuGroup.label || 'ISidebarGroup' },
            this.renderOptions(menuGroup)
        );
    }

    renderOptions(menuGroup: IMenuGroup): React.ReactNode[] {
        if (!menuGroup || isArrayEmpty(menuGroup.options)) {
            return [];
        }
        return menuGroup.options.map((o: IMenuOption) => this.renderOption(o));
    }

    renderOption(o: IMenuOption): React.ReactNode {
        const { getValueFromPath, updateModel, uiState, httpClient } = this.props;
        let component: React.ReactNode;
        switch (o.inputType) {
            case InputType.NUMBER:
            case InputType.TEXT:
                component = this.renderInput(o, getValueFromPath, updateModel);
                break;
            case InputType.SELECT:
                component = this.renderSelect(o, getValueFromPath);
                break;
            case InputType.TABLE:
                component = this.renderTable(o, getValueFromPath, updateModel);
                break;
            case InputType.ADDRESS:
                component = this.renderSearchAddress(o, uiState, updateModel, httpClient);
                break;
            default:
                component = null;
        }
        return component;
    }

    private renderInput(o: IMenuOption,
                        getValueFromPath: (path: string) => any,
                        updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void): React.ReactNode {
        const type = o.inputType;

        // obs: number input value needs to be defined, even if model value is null
        const value = isNil(getValueFromPath(o.targetPath)) ? '' : getValueFromPath(o.targetPath);

        return rce(InputContainer, { key: o.targetPath }, [
            rce(SidebarLabelContainer, {
                key: 'data-type-select-label',
                style: { paddingBottom: '10px' }
            }, o.label),
            rce(Input, {
                key: o.targetPath + '-input',
                type,
                value,
                onChange: (e: any) => updateModel(this.resolveInputValue(e, o), o.targetPath)
            })
        ]);
    }

    private resolveInputValue(e: any, o: IMenuOption): number | string | null {
        let newValue = e?.target?.value || null;
        if (o.inputType === InputType.NUMBER || o.inputType === InputType.TABLE) {
            newValue = e.target.value !== '' ? Number(newValue) : newValue;
        }
        return newValue;
    }

    private renderSelect(o: IMenuOption,
                         getValueFromPath: (path: string) => any): React.ReactNode {
        const value = getValueFromPath(o.targetPath);
        return rce(InputContainer, { key: o.targetPath }, [
                rce(SidebarLabelContainer, {
                    key: 'data-type-select-label',
                    style: { paddingBottom: '10px' }
                }, o.label),
                rce(SelectComponent,
                    {
                        key: o.targetPath + '-select',
                        options: o.selectValues,
                        selected: findSelectedOption(value, o.selectValues),
                        onSelect: (option: ISelectOption) => this.onSelect(option, o)
                    })
            ]
        );
    }

    private renderTable(o: IMenuOption,
                        getValueFromPath: (path: string) => any,
                        updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void): React.ReactNode {
        if (!o || !o.defaultValue || !Array.isArray(o.defaultValue)) {
            return null;
        }
        const inputs = (<Array<number | string>>o.defaultValue || []).map((d: number | string, index: number) => {
            const type = typeof d === 'string' ? 'string' : 'number';
            const arrayOfValues = getValueFromPath(o.targetPath);
            let value = 0;

            if (!isArrayEmpty(arrayOfValues) && index < arrayOfValues.length) {
                const valueFromModel = arrayOfValues[index];
                // obs: number input value needs to be defined, even if model value is null
                value = isNil(valueFromModel) ? '' : valueFromModel;
            }

            return rce(SidebarGroupInputItemContainer, { key: o.targetPath + '-input-' + index },
                rce(Input, {
                    type,
                    value,
                    onChange: (e: any) => this.updateTableValue(e, o, index, getValueFromPath, updateModel)
                })
            );
        });
        return rce(InputContainer, { key: o.targetPath }, [
            rce(SidebarLabelContainer, {
                key: 'data-type-input-label',
                style: { paddingBottom: '10px' }
            }, o.label),
            rce(SidebarGroupInputContainer, { key: 'input-group-container' }, inputs)
        ]);
    }

    private updateTableValue(e: any,
                             o: IMenuOption,
                             index: number,
                             getValueFromPath: (path: string) => any,
                             updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void): void {

        const value = this.resolveInputValue(e, o);
        const arrayOfValues = getValueFromPath(o.targetPath);
        if (!isArrayEmpty(arrayOfValues) && index < arrayOfValues.length) {
            arrayOfValues[index] = value;
        }
        updateModel(arrayOfValues, o.targetPath);
    }

    private renderSearchAddress(o: IMenuOption,
                                uiState: ISidebarUIState,
                                updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void,
                                httpClient: HttpClient): React.ReactNode {
        const initialValue = uiState?.selectedAddress && uiState?.selectedAddress[o?.targetPath];

        return rce(InputContainer, { key: o.targetPath }, [
                rce(SidebarLabelContainer, {
                    key: 'data-type-select-label',
                    style: { paddingBottom: '10px' }
                }, o.label),
                rce(ReactSearchAddressComponent,
                    {
                        key: o.targetPath + '-address',
                        onSelect: (selected: SearchSelectedItem) => this.onAddressSelect(selected, o, uiState, updateModel),
                        initialValue,
                        httpClient
                    })
            ]
        );
    }

    onAddressSelect(selectedAddress: SearchSelectedItem,
                    o: IMenuOption,
                    uiState: ISidebarUIState,
                    updateModel: (value: any, path: string, uiState?: ISidebarUIState) => void): void {
        if (!o || !o.targetPath) {
            throw Error('Menu option not valid, cannot update address model');
        }
        let coordinates: MapCoordinates = selectedAddress?.coordinates;
        if (!coordinates) {
            coordinates = new MapCoordinates(null, null);
        }
        const newAddress = uiState && uiState.selectedAddress || {};
        newAddress[o.targetPath] = selectedAddress;
        updateModel(coordinates, o.targetPath, { selectedAddress: newAddress });
    }

    onSelect(value: ISelectOption, o: IMenuOption): void {
        if (!value) {
            console.warn('Problème de sélection: indéfinie');
            return;
        }
        const { updateModel } = this.props;
        const selected: MenuSelectOption = findOptionById(value.id, o.selectValues);
        if (!selected) {
            console.warn('Impossible de trouver la valeur sélectionnée dans la liste des options');
            return;
        }
        const v = getSelectedValue(selected);
        if (!v) {
            console.warn('Problème de sélection: option trouvée mais valeur non définie');
            return;
        }
        updateModel(v, o.targetPath);
    }
}
