import { get } from 'lodash';

import { Injectable } from '@angular/core';
import { RouteColorsService } from '../route-colors.service';
import { PolylineGeometry } from '../../demos/bike-demo/bike-route-results';
import { ExtendedPolyline, PolylineData } from './extended-polyline';
import { RouteConfig } from '../route-config';
import { mapGeometryToArray } from '../../utils/leaflet-map.utils';
import { isArrayEmpty } from '../../utils/global.helper';
import { GeoJSON } from 'leaflet';
import { GeoJsonObject } from 'geojson';
import { MapControlName } from '../map-control/map-controle-name';
import { ResultsSharedService } from '../../demos/results-shared.service';

@Injectable()
export class LeafletRouteLayerService {
    static readonly METEO_PROPERTY_NAME = 'properties.intensity';
    static readonly TRUCK_PROPERTY_NAME = 'properties.indiceMultiPolluant';

    constructor(private readonly routeColorsService: RouteColorsService,
                private readonly sharedService: ResultsSharedService) {
    }

    buildLayersFromRawResult(geometry: PolylineGeometry): ExtendedPolyline {
        if (!geometry) {
            return null;
        }
        return this.buildRouteLayer(geometry);
    }

    buildMeteoGeoJSON(geoJSON: GeoJsonObject): GeoJSON {
        return new GeoJSON(geoJSON, {
            onEachFeature: this.updateGeoJSONStyle(MapControlName.METEO)
        });
    }

    buildAirQualityGeoJSON(geoJSON: GeoJsonObject): GeoJSON {
        return new GeoJSON(geoJSON, {
            onEachFeature: this.updateGeoJSONStyle(MapControlName.AIR_QUALITY)
        });
    }

    refreshSelected(layers: ExtendedPolyline[], selected: ExtendedPolyline): void {
        if (isArrayEmpty(layers)) {
            return null;
        }
        layers.forEach((layer: ExtendedPolyline) => {
            const isRouteSelected = layer === selected;
            const routeRGBAFullColor = this.routeColorsService.getRGBAColorWithOpacity(layer.data.routeColor, isRouteSelected);
            const weight = isRouteSelected ? RouteConfig.SELECTED_ROUTE_WIDTH : RouteConfig.DEFAULT_ROUTE_WIDTH;
            const options = {
                color: routeRGBAFullColor,
                weight
            };
            layer.data.$selected = isRouteSelected;
            layer.setStyle(options);
        });
    }

    private updateGeoJSONStyle(controlName: MapControlName): any {
        const propertyKey = controlName === MapControlName.METEO ?
            LeafletRouteLayerService.METEO_PROPERTY_NAME : LeafletRouteLayerService.TRUCK_PROPERTY_NAME;
        return (feature, layer) => {
            const color = this.routeColorsService.getColorFromValue(get(feature, propertyKey), controlName);
            layer.setStyle({ weight: 6, color, opacity: 1 });
        };
    }

    private buildRouteLayer(geometry: PolylineGeometry): ExtendedPolyline {
        const color = this.sharedService.getNextAvailableColor();
        if (!color) {
            console.warn('Error creating color for route results :', geometry);
            return null;
        }
        const routeRGBAFullColor = this.routeColorsService.getRGBAColorWithOpacity(color);
        const weight = RouteConfig.DEFAULT_ROUTE_WIDTH;
        const options = {
            color: routeRGBAFullColor,
            weight
        };
        const coordinates = mapGeometryToArray(geometry);
        if (!coordinates) {
            return;
        }
        const polyline: ExtendedPolyline = new ExtendedPolyline(coordinates, options);
        // Save line color in object data attribute, to use later in associated consoResult
        polyline.data = new PolylineData(color);
        return polyline;
    }
}
