import { isNil, last, merge } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';

import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { ItineraryImgUrl, ItineraryPoint } from 'app/global/class/itinerary-point';
import { MapCoordinates } from '../../class/map-coordinates';
import { ItineraryPointLayer } from '../../class/itinerary-point-layer';
import { ItinerarySharedService } from './itinerary-shared.service';
import { ItineraryHelperService } from './itinerary-helper.service';
import { GeoCoderAddress } from 'app/global/class/geo-coder-address';
import { GeocodingBaseService } from 'app/global/component/react/react-search-address/geocoding-base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ItineraryService {

    // Max number of itinerary points
    static readonly MAX_POINTS = 5;

    // Global counter for point id generation
    private itineraryIdCursor = 0;
    // The formGroup containing itinerary point data (address, coordinates)
    private itineraryForm: FormGroup;

    // The formGroup of currently modified point
    private currentPointForm: FormGroup;

    constructor(public readonly sharedService: ItinerarySharedService,
                public readonly formBuilder: FormBuilder,
                private readonly httpClient: HttpClient,
                public readonly itineraryHelperService: ItineraryHelperService) {
    }

    initForm(): void {
        // Create the initial form from new itinerary
        this.itineraryForm = this.formBuilder.group({
            name: 'itineraryGroup',
            itinerary: this.formBuilder.array(this.initItinerary())
        });
        this.initSubjects();
    }

    clearAll(): void {
        this.itineraryIdCursor = 0;
        this.currentPointForm = null;
        this.itineraryForm = null;
        this.sharedService.clearValue();
    }

    private initItinerary(): FormGroup[] {
        this.itineraryIdCursor = 0;
        const initialItinerary = [];
        initialItinerary.push(this.createPoint());
        initialItinerary.push(this.createPoint());
        return initialItinerary;
    }

    private initSubjects(): void {
        const srcPoints = this.getControlArray().getRawValue();
        this.sharedService.nextPointLayers(srcPoints);
    }

    setCurrentPointForm(form: FormGroup): void {
        this.currentPointForm = form;
    }

    setIsItineraryFocused(isFocus: boolean): void {
        this.sharedService.setIsItineraryFocused(isFocus);
    }

    getItineraryPointsSubject(): BehaviorSubject<ItineraryPointLayer[]> {
        return this.sharedService.getPointLayers();
    }

    refreshAndRedraw(): void {
        const oldPoints = this.getControlArray().getRawValue();
        oldPoints.forEach(itineraryPoint => itineraryPoint.needRefresh = true);
        this.sharedService.nextPointLayers([...oldPoints]);
    }

    refreshAfterMove(newPointArray: ItineraryPointLayer[]): void {
        this.getControlArray().setValue(newPointArray);
        this.refreshAndRedraw();
    }

    getControlArray(): FormArray {
        return this.itineraryForm ? this.itineraryForm.get('itinerary') as FormArray : null;
    }

    getCurrentForm(): FormGroup {
        return this.currentPointForm;
    }

    getItineraryPoints(): ItineraryPointLayer[] {
        const controlArray = this.getControlArray();
        return controlArray ? controlArray.getRawValue() : [];
    }

    /**
     * Action to perform on a drag event on a point
     * Update the address and coordinates values of the point
     *
     * @param {MapCoordinates} new coordinates of the point
     * @param {ItineraryPointLayer} pointToModify the point to modify
     */
    updateOnDrag(coordinates: MapCoordinates, pointToModify: ItineraryPointLayer): void {
        if (!coordinates || !pointToModify) {
            return;
        }
        // Find in the control array the one corresponding to the point being modified
        const pointForm = this.itineraryHelperService.findPointForm(pointToModify, this.getControlArray().controls as FormGroup[]);
        // Update coordinates value
        this.updateCoordinates(pointForm, coordinates);
        // Update address value
        GeocodingBaseService.getAddressFromGPS(coordinates, this.httpClient).pipe(
            tap((addressResult: GeoCoderAddress) => pointForm.get('address').setValue(addressResult.label))
        ).subscribe();
    }

    private updateCoordinates(form: FormGroup, coordinates: MapCoordinates): void {
        if (!form || !coordinates) {
            return;
        }
        form.get('coordinates').setValue(coordinates);
    }

    /**
     * Return the right image url based on point form group and its position in the list
     *
     * @param {FormGroup} pointGroup
     * @return {ItineraryImgUrl}
     */
    getImageUrl(pointGroup: FormGroup): ItineraryImgUrl {
        return this.itineraryHelperService.getImageUrl(pointGroup, this.getControlArray());
    }

    /**
     * Return the right image url based on an itinerary point and its position in the list
     *
     * @param {ItineraryPoint} point
     * @param {ItineraryPoint[]} pointList
     * @return {ItineraryImgUrl}
     */
    getItineraryPointImageUrl(point: ItineraryPoint, pointList: ItineraryPoint[]): ItineraryImgUrl {
        return this.itineraryHelperService.getItineraryPointImageUrl(point, pointList);
    }

    updatePointFromAddress(pointForm: FormGroup): Observable<MapCoordinates> {
        return GeocodingBaseService.getGPSFromAddress(pointForm.get('address').value, this.httpClient).pipe(
            // No need to update address, it has been updated by itinerary's typeahead
            tap((coordinates: MapCoordinates) => this.updatePointAttributes(pointForm, coordinates, null, true))
        );
    }

    updatePointFromGPS(coordinates: MapCoordinates, pointIndex?: number): Observable<GeoCoderAddress> {
        return GeocodingBaseService.getAddressFromGPS(coordinates, this.httpClient).pipe(tap((addressResult: GeoCoderAddress) => {
            // We need to know the current state of points definition to know which point to update
            const pointsFormArray: FormArray = this.getControlArray();

            // If index not provided, find next point being modified
            let pointForm: FormGroup;
            if (!isNil(pointIndex)) {
                pointForm = pointsFormArray.at(pointIndex) as FormGroup;
            }
            pointForm = pointForm || this.currentPointForm;
            if (!pointForm) {
                pointForm = pointsFormArray.at(0) as FormGroup;
                this.currentPointForm = pointsFormArray.at(1) as FormGroup;
            }
            this.updatePointAttributes(pointForm, coordinates, addressResult.label);
        }));
    }

    updatePoint(coordinates: MapCoordinates, adress: string , pointIndex?: number): any {
        const pointsFormArray: FormArray = this.getControlArray();
        // If index not provided, find next point being modified
        let pointForm: FormGroup;
        if (!isNil(pointIndex)) {
            pointForm = pointsFormArray.at(pointIndex) as FormGroup;
        }
        pointForm = pointForm || this.currentPointForm;
        if (!pointForm) {
            pointForm = pointsFormArray.at(0) as FormGroup;
            this.currentPointForm = pointsFormArray.at(1) as FormGroup;
        }
        this.updatePointAttributes(pointForm, coordinates, adress);
    }

    private updatePointAttributes(pointForm: FormGroup, coordinates: MapCoordinates, address: string, skipAddress = false): void {
        this.updateCoordinates(pointForm, coordinates);
        if (!skipAddress) {
            pointForm.get('address').setValue(address);
        }
        const itineraryPointIndex = this.findIndex(pointForm);

        this.updateItinerarySubject(pointForm, itineraryPointIndex);
    }

    /**
     * Update the marker associated with this point
     *
     * @param {FormGroup} pointForm the source : pointForm that has been modified
     * @param {number} index the position of the
     */
    private updateItinerarySubject(pointForm: FormGroup, index: number): void {
        const itineraryToUpdate: ItineraryPointLayer = this.sharedService.getPointLayerValueAt(index);
        merge(itineraryToUpdate, pointForm.getRawValue());
        itineraryToUpdate.needRefresh = true;

        // Refresh list so the map can update the layer
        this.sharedService.refreshPointLayers();
    }

    getItineraryForm(): FormGroup {
        return this.itineraryForm;
    }

    addPoint(srcPoint?: ItineraryPointLayer): void {
        if (this.itineraryHelperService.canAddPoint(this.getControlArray())) {
            const newPoint = this.createPoint(srcPoint);
            this.getControlArray().push(newPoint);
            // We now must edit this new point, set it as currently modified point
            this.currentPointForm = last(this.getControlArray().controls) as FormGroup;
            this.refreshAndRedraw();
        }
    }

    /**
     * Remove or reset point at given index
     * If more than 2 points are present in list, remove the point
     * else, we can't have less than 2 point, just reset point information
     *
     * @param {number} index
     */
    removeOrResetPoint(index: number): void {
        if (this.itineraryHelperService.canRemove(this.getControlArray())) {
            // We are removing the point at this index, find it
            this.getControlArray().removeAt(index);
            // We now must edit this new point, set it as currently modified point
            this.currentPointForm = last(this.getControlArray().controls) as FormGroup;
        } else {
            // Actually not removing, only deleting point information
            this.getControlArray().at(index).reset();
            // We are just deleting point information, make sure we are now editing this point
            this.currentPointForm = this.getControlArray().at(index) as FormGroup;
        }
        this.refreshAndRedraw();
    }

    reverse(): void {
        const controls = []; // Array for saving the controls to reverse
        const length = this.getControlArray().length; // Initial number of controls
        if (!length) {
            return null;
        }
        for (let i = 0; i < length - 1; i++) {
            // Save reference of the control at position 0
            controls.push(this.getControlArray().at(0));
            // Remove control currently at index 0 (FormArray indexes are updated)
            this.getControlArray().removeAt(0);
        }
        // At this point, only one control remaining in the formGroup (the last one, now the first one)
        for (let j = controls.length - 1; j >= 0; j--) {
            // Push all saved controls back in, in reverse order
            this.getControlArray().push(controls[j]);
        }
        this.refreshAndRedraw();
    }

    findIndex(targetControl: AbstractControl): number {
        return this.itineraryHelperService.findIndexInFormArray(targetControl, this.getControlArray());
    }

    createPoint(newPoint?: ItineraryPointLayer): FormGroup {
        this.itineraryIdCursor++;
        let pointToAdd = newPoint;
        if (!pointToAdd) {
            pointToAdd = new ItineraryPointLayer(this.itineraryIdCursor);
        }
        return this.formBuilder.group(pointToAdd);
    }
}
