import './vendor.ts';

import * as moment from 'moment';
import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GatewayAngularAppRoutingModule } from './app-routing.module';
import { GatewayAngularHomeModule } from './home/home.module';
import { GatewayAngularEntityModule } from './entities/entity.module';
import {
    ActiveMenuDirective,
    ErrorComponent,
    FooterComponent,
    MainComponent,
    NavbarComponent,
    PageRibbonComponent
} from './layouts';
import { GatewayAngularCoreModule } from 'app/core/core.module';
import { GatewayAngularSharedModule } from 'app/shared/shared.module';
import { GalleriesModule } from 'app/galleries/galleries.module';
import { DocsModule } from 'app/docs/docs.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'app/blocks/interceptor/auth.interceptor';
import { AuthExpiredInterceptor } from 'app/blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from 'app/blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from 'app/blocks/interceptor/notification.interceptor';
import { AddressService } from 'app/global/service/address.service';
import { VersionService } from 'app/global/service/version.service';
import { CurrentDemoSharedService } from 'app/global/service/current-demo-shared.service';
import { HereCredentialsService } from 'app/maps/here-maps/here-credentials.service';
import { CompanyService } from 'app/global/service/company.service';
import { LeftSidebarSharedService } from 'app/left-sidebar/left-sidebar.shared.service';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgJhipsterModule } from 'ng-jhipster';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCoffee, fas } from '@fortawesome/free-solid-svg-icons';
import { DemoService } from 'app/global/service/demo.service';
import { DemoAccessSharedService } from 'app/global/service/demo-access-shared.service';
import { SharedDirectivesModule } from 'app/global/directive/shared-directives.module';
import { HttpClient } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { NgxKeplerModule } from 'app/global/component/ngx-kepler/ngx-kepler.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        NgxWebstorageModule.forRoot({ prefix: 'dc', separator: '-' }),
        NgJhipsterModule.forRoot({
            // set below to true to make alerts look like toast
            alertAsToast: false,
            alertTimeout: 5000,
            i18nEnabled: true,
            defaultI18nLang: 'fr'
        }),
        MarkdownModule.forRoot({ loader: HttpClient }),
        GatewayAngularSharedModule,
        GatewayAngularCoreModule,
        GatewayAngularHomeModule,
        // jhipster-needle-angular-add-module JHipster will add new module here
        GatewayAngularEntityModule,
        GatewayAngularAppRoutingModule,
        // NgxKeplerModule,
        // DemosRoutingModule,
        GalleriesModule,
        DocsModule,
        SharedDirectivesModule,
        BsDatepickerModule.forRoot()
    ],
    declarations: [
        MainComponent,
        NavbarComponent,
        ErrorComponent,
        PageRibbonComponent,
        ActiveMenuDirective,
        FooterComponent
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
        },
        AddressService,
        DemoService,
        DemoAccessSharedService,
        VersionService,
        CurrentDemoSharedService,
        HereCredentialsService,
        CompanyService,
        LeftSidebarSharedService
    ],
    bootstrap: [MainComponent]
})
export class GatewayAngularAppModule {
    constructor(private dpConfig: NgbDatepickerConfig, library: FaIconLibrary) {
        library.addIconPacks(fas);
        // TODO check if faCoffee is needed
        library.addIcons(faCoffee);
        this.dpConfig.minDate = { year: moment().year() - 100, month: 1, day: 1 };
    }
}
