import { HttpClient } from '@angular/common/http';

const SET_HTTP_CLIENT = 'SET_HTTP_CLIENT';
const SET_ROLES = 'SET_ROLES';

export const setHttpClient = (httpClient: HttpClient) => ({
    type: SET_HTTP_CLIENT,
    httpClient
});

export const setRoles = (roles: string[]) => ({
   type: SET_ROLES,
   roles
});

// For the app reducer
export const CONFIG_ACTIONS = {
    [SET_HTTP_CLIENT]: (state, action) => ({
        ...state,
        httpClient: action.httpClient
    }),
    [SET_ROLES]: (state, action) => ({
        ...state,
        roles: action.roles
    })
};
