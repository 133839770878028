import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoginModalService } from 'app/core/login/login-modal.service';
import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core/user/account.model';
import { ROLES as galleriesRoles } from 'app/galleries/role.constants';
import { DEMO_ROLES as demosRoles } from 'app/demos/role.constants';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DemoAccess } from 'app/demos/demo-access';
import { getDemoInfosByName } from 'app/utils/demo-utils';
import { DemoName } from 'app/demos/demo-name';
import {GeneralConditionComponent} from 'app/shared/general-condition/general-condition.component';

@Component({
    selector: 'dc-home',
    templateUrl: './home.component.html',
    styleUrls: ['home.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    demoNames = DemoName;
    roles = { ...galleriesRoles, ...demosRoles };
    account: Account | null = null;
    modalRef: NgbModalRef;
    authSubscription?: Subscription;
    private isOpen = false;

    constructor(
        private accountService: AccountService,
        private loginModalService: LoginModalService,
        private modalService: NgbModal,
    ) {
    }

    ngOnInit(): void {
        this.authSubscription = this.accountService.getAuthenticationState().subscribe(account => {
            this.account = account;
            if (this.cguValidation(account)) {
                this.openCguModel();
            }
        });
    }

    private cguValidation(account: Account): boolean {
        return account && account.latestCguVersion !== '' && (account.currentCguVersion === '0' || account.currentCguVersion !== account.latestCguVersion);
    }

    getDemoAccess(name: DemoName): DemoAccess {
        return getDemoInfosByName(name);
    }

    isAuthenticated(): boolean {
        return this.accountService.isAuthenticated();
    }

    login(): void {
        this.loginModalService.open();
    }

    ngOnDestroy(): void {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }

    openCguModel(): NgbModalRef {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        const options: NgbModalOptions = {
            scrollable: true,
            backdrop: 'static',
            keyboard: false
        };
        const modalRef: NgbModalRef = this.modalService.open(GeneralConditionComponent, options);
        modalRef.componentInstance.fromParent = this.account;
        modalRef.result.finally(() => (this.isOpen = false));
        return modalRef;
    }
}
