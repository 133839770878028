import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HereCredentials } from './here-credentials';

@Injectable()
export class HereCredentialsService {
    url = 'internal/service/getHereCredentials/';

    hereCredentials: HereCredentials;

    constructor(private readonly httpClient: HttpClient) {
    }

    initCredentials(): Observable<HereCredentials> {
        return this.httpClient.get<HereCredentials>(this.url).pipe(
            tap(response => this.hereCredentials = response)
        );
    }

    getCredentials(): Observable<HereCredentials> {
        return this.hereCredentials ? of(this.hereCredentials) : this.initCredentials();
    }
}
