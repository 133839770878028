import { NgModule } from '@angular/core';

import { FilterAccessDirective } from 'app/global/directive/filter-access.directive';
import { ModalsModule } from 'app/global/component/modals/modals.module';
import { DemoAccessDirective } from './demo-access.directive';

@NgModule({
    imports: [
        ModalsModule
    ],
    declarations: [FilterAccessDirective, DemoAccessDirective],
    exports: [FilterAccessDirective, DemoAccessDirective, DemoAccessDirective],
    providers: [],
})
export class SharedDirectivesModule {
}
